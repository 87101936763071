import React from 'react'
import { Container, Row, Col, Breadcrumb, Dropdown, Form } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./stylenew.css";
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';
import ScrollArea from 'react-scrollbar';

import { MdOutlineFileDownload } from 'react-icons/md';
import { HiDotsVertical, HiOutlineCalendar } from 'react-icons/hi';

const TicketDashboardTwo = () => {    
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Help Centre</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Support</Breadcrumb.Item>
                            <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="outerspace pt-2">
                                <Col as="section" className="innerspace pt-4">
                                    <Row className="mx-md-4 text-center pointbox">
                                        <Col className="firstLogo"><img src="./assets/logosmall.png" className="img-fluid" alt='calender' /></Col>
                                        <Col className="fs24 GrayComn semibold"><span class="menuTag fs20">Ticket Number</span><br />HC17B34V81</Col>
                                        <Col className="fs24 GrayComn semibold"><span class="menuTag fs20">Help Category</span><br />Issue with an Order</Col>
                                        <Col className="fs24 GrayComn semibold"><span class="menuTag fs20">Subject</span><br />Mistake with my Order</Col>
                                        <Col className="col-auto">
                                            <Dropdown className="drdown favMenu" align="end">
                                                <Dropdown.Toggle variant="light" className="bg-transparent shoadow-none outline-none border-0 p-0" id="dropdown-basic">
                                                    <HiDotsVertical size="35" color="var(--bs-grayDark51)" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item className="GrayComn semibold py-1 px-1 bg-transparent" href="#/action-1">
                                                        <span className="d-inline-block icnSvg"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></span>Download Transcript
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <Row className="mx-md-4 mt-5 pb-5">
                                        <ScrollArea
                                            smoothScrolling
                                            speed={0.9}
                                            className="scrollcontentarea"
                                            horizontal={false}>
                                            <Col className="tabchat p-lg-0 position-relative text-center mb-5">
                                                <hr className="ms5" />
                                                <span class="menuTag menuTagDate grayf4f GrayComn fs12 semibold position-absolute py-1 lh-lg">
                                                <HiOutlineCalendar className="align-top me-2" size="20" color="var(--theme-color)" />20 July 2022</span>
                                            </Col>
                                            <Col xl="8" className="text-end float-end pb-5 pe-5">
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3">johndoe132@gmail.com</Col>
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
                                                    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Col>
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3 corderred position-relative">
                                                    <img src="./assets/chatBG.png" className="img-fluid mt-2" alt='calender' />
                                                    <span className="fs28 d-block my-3">
                                                        <svg className="me-2" width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.35551 24.0182H6.70288V26.4829H8.35551C9.01894 26.4829 9.54396 25.8985 9.54396 25.2846C9.54258 24.5927 9.01895 24.0183 8.35551 24.0183V24.0182Z" fill="white"/>
                                                            <path d="M15.0077 24.0666H13.365V28.5007H15.0077C17.6802 28.5009 17.7979 24.0666 15.0077 24.0666Z" fill="white"/>
                                                            <path d="M28.4716 18.5682H1.2379C0.557053 18.5682 0 19.1252 0 19.8061V32.185C0 32.8659 0.557053 33.4229 1.2379 33.4229H28.4716C29.1535 33.4229 29.7095 32.8659 29.7095 32.185V19.8061C29.7095 19.1252 29.1535 18.5682 28.4716 18.5682ZM8.35544 27.7201H6.70281V29.0273C6.70281 29.4628 6.42567 29.7093 6.06895 29.7093C5.74207 29.7093 5.39667 29.4628 5.39667 29.0259V23.4543C5.39667 23.1274 5.6542 22.7698 6.06895 22.7698L8.35545 22.7696C9.64279 22.7696 10.8014 23.6311 10.8014 25.2838C10.8003 26.8485 9.64279 27.72 8.35545 27.72L8.35544 27.7201ZM15.0959 29.7093H12.7403C12.4134 29.7093 12.0569 29.5297 12.0569 29.0953V23.4739C12.0569 23.1175 12.4134 22.86 12.7403 22.86H15.0069C19.5299 22.86 19.431 29.7093 15.0959 29.7093L15.0959 29.7093ZM23.9161 24.1462H21.0344V25.7195H23.5586C23.9164 25.7195 24.2714 26.076 24.2714 26.4228C24.2714 26.7496 23.9161 27.0157 23.5586 27.0157H21.0344V29.0942C21.0344 29.4407 20.7879 29.7082 20.4414 29.7082C20.0057 29.7082 19.7382 29.4407 19.7382 29.0942V23.4728C19.7382 23.1164 20.0068 22.8588 20.4414 22.8588H23.9161C24.3518 22.8588 24.608 23.1164 24.608 23.4728C24.608 23.7908 24.3518 24.1462 23.9161 24.1462L23.9161 24.1462Z" fill="white"/>
                                                            <path d="M33.423 9.39032L25.2705 1.2379C24.5549 0.520058 23.2973 0 22.2822 0H8.66535C6.61787 0 4.95166 1.66621 4.95166 3.71369V16.0926C4.95166 16.7771 5.50512 17.3305 6.18956 17.3305C6.87399 17.3305 7.42745 16.7771 7.42745 16.0926V3.71369C7.42745 3.03174 7.98201 2.47579 8.66535 2.47579H19.8064C21.1742 2.47579 22.2822 3.58495 22.2822 4.95158V9.59369C22.2822 11.1261 23.5375 12.379 25.0675 12.379H29.7096C31.0773 12.379 32.1854 13.4881 32.1854 14.8547V35.899C32.1854 36.5809 31.6319 37.1369 30.9475 37.1369H8.66535C7.98201 37.1369 7.42745 36.5809 7.42745 35.899C7.42745 35.2145 6.87399 34.6611 6.18956 34.6611C5.50512 34.6611 4.95166 35.2145 4.95166 35.899C4.95166 37.9465 6.61787 39.6127 8.66535 39.6127H30.9475C32.9949 39.6127 34.6612 37.9465 34.6612 35.899V12.379C34.6612 11.3652 34.1411 10.1074 33.4233 9.3906L33.423 9.39032Z" fill="white"/>
                                                        </svg>order_receipt.pdf</span>
                                                </Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">10:35 PM</Col>
                                            </Col>
                                            <Col xl="8" className="text-start float-start position-relative ps-5">
                                                <span className="position-absolute commentrelative bottom-0 gray333 rounded-pill text-center">
                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                    </svg>
                                                </span>
                                                <Col as="span" className="text-start fs20 GrayComn gray02 border20 px-3 py-2 d-inline-block mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et 
                                                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip 
                                                    ex ea commodo consequat.</Col>
                                                <Col as="span" className="text-start fs20 GrayComn gray02 border20 ps-4 pe-3 py-2 d-inline-block mb-3 cornergray position-relative">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">10:37 PM</Col>
                                            </Col>
                                            <Col xl="8" className="text-end float-end pb-5 pe-5">
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3 corderred position-relative">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                </Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">11:06 PM</Col>
                                            </Col>
                                            <Col xl="8" className="text-start float-start position-relative ps-5 mb-5">
                                                <span className="position-absolute commentrelative bottom-0 gray333 rounded-pill text-center">
                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                    </svg>
                                                </span>                                                
                                                <Col as="span" className="text-start fs20 GrayComn gray02 border20 ps-4 pe-3 py-2 d-inline-block mb-3 cornergray position-relative">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, ed do eiusmod temipiscing elit, ed do eiupor </Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">11:08 PM</Col>
                                            </Col>                                            
                                            <Col className="tabchat p-lg-0 position-relative text-center mt-5" style={{clear: "both"}}>
                                                <hr className="ms5" />
                                                <span class="menuTag menuTagDate bg-white border border-1 grayf4f GrayComn fs20 semibold position-absolute py-1 lh-lg">Ticket Closed</span>
                                            </Col>
                                            <br />
                                            <Col className="tabchat p-lg-0 position-relative text-center mt-5">
                                                <hr className="ms5" />
                                                <span class="menuTag menuTagDate grayf4f GrayComn fs12 semibold position-absolute py-1 lh-lg">
                                                <HiOutlineCalendar className="align-top me-2" size="20" color="var(--theme-color)" />15 August 2022</span>
                                            </Col><br /><br />
                                            <Col xl="8" className="text-end float-end pb-5 pe-5">
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3">johndoe132@gmail.com</Col>
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
                                                    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Col>
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3 corderred position-relative">
                                                    <img src="./assets/chatBG.png" className="img-fluid mt-2" alt='calender' />
                                                    <span className="fs28 d-block my-3">
                                                        <svg className="me-2" width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.35551 24.0182H6.70288V26.4829H8.35551C9.01894 26.4829 9.54396 25.8985 9.54396 25.2846C9.54258 24.5927 9.01895 24.0183 8.35551 24.0183V24.0182Z" fill="white"/>
                                                            <path d="M15.0077 24.0666H13.365V28.5007H15.0077C17.6802 28.5009 17.7979 24.0666 15.0077 24.0666Z" fill="white"/>
                                                            <path d="M28.4716 18.5682H1.2379C0.557053 18.5682 0 19.1252 0 19.8061V32.185C0 32.8659 0.557053 33.4229 1.2379 33.4229H28.4716C29.1535 33.4229 29.7095 32.8659 29.7095 32.185V19.8061C29.7095 19.1252 29.1535 18.5682 28.4716 18.5682ZM8.35544 27.7201H6.70281V29.0273C6.70281 29.4628 6.42567 29.7093 6.06895 29.7093C5.74207 29.7093 5.39667 29.4628 5.39667 29.0259V23.4543C5.39667 23.1274 5.6542 22.7698 6.06895 22.7698L8.35545 22.7696C9.64279 22.7696 10.8014 23.6311 10.8014 25.2838C10.8003 26.8485 9.64279 27.72 8.35545 27.72L8.35544 27.7201ZM15.0959 29.7093H12.7403C12.4134 29.7093 12.0569 29.5297 12.0569 29.0953V23.4739C12.0569 23.1175 12.4134 22.86 12.7403 22.86H15.0069C19.5299 22.86 19.431 29.7093 15.0959 29.7093L15.0959 29.7093ZM23.9161 24.1462H21.0344V25.7195H23.5586C23.9164 25.7195 24.2714 26.076 24.2714 26.4228C24.2714 26.7496 23.9161 27.0157 23.5586 27.0157H21.0344V29.0942C21.0344 29.4407 20.7879 29.7082 20.4414 29.7082C20.0057 29.7082 19.7382 29.4407 19.7382 29.0942V23.4728C19.7382 23.1164 20.0068 22.8588 20.4414 22.8588H23.9161C24.3518 22.8588 24.608 23.1164 24.608 23.4728C24.608 23.7908 24.3518 24.1462 23.9161 24.1462L23.9161 24.1462Z" fill="white"/>
                                                            <path d="M33.423 9.39032L25.2705 1.2379C24.5549 0.520058 23.2973 0 22.2822 0H8.66535C6.61787 0 4.95166 1.66621 4.95166 3.71369V16.0926C4.95166 16.7771 5.50512 17.3305 6.18956 17.3305C6.87399 17.3305 7.42745 16.7771 7.42745 16.0926V3.71369C7.42745 3.03174 7.98201 2.47579 8.66535 2.47579H19.8064C21.1742 2.47579 22.2822 3.58495 22.2822 4.95158V9.59369C22.2822 11.1261 23.5375 12.379 25.0675 12.379H29.7096C31.0773 12.379 32.1854 13.4881 32.1854 14.8547V35.899C32.1854 36.5809 31.6319 37.1369 30.9475 37.1369H8.66535C7.98201 37.1369 7.42745 36.5809 7.42745 35.899C7.42745 35.2145 6.87399 34.6611 6.18956 34.6611C5.50512 34.6611 4.95166 35.2145 4.95166 35.899C4.95166 37.9465 6.61787 39.6127 8.66535 39.6127H30.9475C32.9949 39.6127 34.6612 37.9465 34.6612 35.899V12.379C34.6612 11.3652 34.1411 10.1074 33.4233 9.3906L33.423 9.39032Z" fill="white"/>
                                                        </svg>order_receipt.pdf</span>
                                                </Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">10:35 PM</Col>
                                            </Col>
                                            <Col xl="8" className="text-start float-start position-relative ps-5">
                                                <span className="position-absolute commentrelative bottom-0 gray333 rounded-pill text-center">
                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                    </svg>
                                                </span>
                                                <Col as="span" className="text-start fs20 GrayComn gray02 border20 px-3 py-2 d-inline-block mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et 
                                                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip 
                                                    ex ea commodo consequat.</Col>
                                                <Col as="span" className="text-start fs20 GrayComn gray02 border20 ps-4 pe-3 py-2 d-inline-block mb-3 cornergray position-relative">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">10:37 PM</Col>
                                            </Col>
                                            <Col xl="8" className="text-end float-end pb-5 pe-5">
                                                <Col as="span" className="text-start fs20 text-white bgtheme border20 px-3 py-2 d-inline-block mb-3 corderred position-relative">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                </Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">11:06 PM</Col>
                                            </Col>
                                            <Col xl="8" className="text-start float-start position-relative ps-5 mb-5">
                                                <span className="position-absolute commentrelative bottom-0 gray333 rounded-pill text-center">
                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                    </svg>
                                                </span>
                                                <Col as="span" className="text-start fs20 GrayComn gray02 border20 ps-4 pe-3 py-2 d-inline-block mb-3 cornergray position-relative">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, ed do eiusmod temipiscing elit, ed do eiupor </Col>
                                                <Col as="span" className="fs12 gray82 semibold d-block mb-3">11:08 PM</Col>
                                            </Col>
                                            <Col className="py-4 px-5 align-items-center d-flex col-12 bgLightGray">
                                                <Col className="sendChatArea">
                                                    <Form>
                                                        <Form.Group controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="email" className="border-0 shadow-none gray999 semibold h-auto py-3 lh-1" placeholder="Send Message" />
                                                        </Form.Group>
                                                    </Form>
                                                </Col>
                                                <Col className="px-4" lg="auto">
                                                    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.7574 7.5L7.87869 17.3787C6.70711 18.5502 6.70711 20.4498 7.87869 21.6213C9.05026 22.7929 10.9498 22.7929 12.1213 21.6213L21.7426 11.7426C24.0858 9.39949 24.0858 5.6005 21.7426 3.25735C19.3995 0.914216 15.6006 0.914216 13.2574 3.25735L3.63604 13.1361C0.121319 16.6507 0.121319 22.3492 3.63604 25.8639C7.15075 29.3787 12.8493 29.3787 16.3639 25.8639L25.75 16.5" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </Col>
                                                <Col className="px-2" lg="auto">
                                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.875 13.5L4.85119 13.7169C4.88294 13.5741 4.88294 13.4259 4.85119 13.2831L3.875 13.5ZM1.125 25.875L0.148812 25.6581C0.0651253 26.0347 0.205518 26.4258 0.509587 26.6632C0.813655 26.9006 1.22717 26.9419 1.57221 26.7694L1.125 25.875ZM25.875 13.5L26.3222 14.3944C26.661 14.225 26.875 13.8788 26.875 13.5C26.875 13.1212 26.661 12.775 26.3222 12.6056L25.875 13.5ZM1.125 1.125L1.57221 0.230572C1.22717 0.0580487 0.813656 0.099384 0.509588 0.336793C0.205519 0.574203 0.0651264 0.965343 0.148813 1.34193L1.125 1.125ZM3.875 12.5C3.32272 12.5 2.875 12.9477 2.875 13.5C2.875 14.0523 3.32272 14.5 3.875 14.5L3.875 12.5ZM14.875 14.5C15.4273 14.5 15.875 14.0523 15.875 13.5C15.875 12.9477 15.4273 12.5 14.875 12.5L14.875 14.5ZM2.89881 13.2831L0.148812 25.6581L2.10119 26.0919L4.85119 13.7169L2.89881 13.2831ZM1.57221 26.7694L26.3222 14.3944L25.4278 12.6056L0.677785 24.9806L1.57221 26.7694ZM26.3222 12.6056L1.57221 0.230572L0.677786 2.01943L25.4278 14.3944L26.3222 12.6056ZM0.148813 1.34193L2.89881 13.7169L4.85119 13.2831L2.10119 0.908068L0.148813 1.34193ZM3.875 14.5L14.875 14.5L14.875 12.5L3.875 12.5L3.875 14.5Z" fill="#C32148"/>
                                                    </svg>
                                                </Col>
                                            </Col>
                                            <br /><br />
                                            <Col className="tabchat p-lg-0 position-relative text-center">
                                                <hr className="ms5" />
                                                <span class="menuTag menuTagDate bg-white border border-1 grayf4f GrayComn fs20 semibold position-absolute py-1 lh-lg">Ticket Closed</span>
                                            </Col>
                                            <br /><br />
                                        </ScrollArea>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )    
}
export default TicketDashboardTwo