import React, { useState } from 'react'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./hoststep.css";
import Header from "../header";
import Footer from '../footer';
import { Link } from 'react-router-dom';
import MenuSidebar from '../menusidebar';

const HostMenuone = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none rightshadow menuside-bar" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <MenuSidebar />
                    </Col>
                    <Col lg="9">
                        <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-5 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <Col md="auto" className='d-flex text-center flex-column flex-md-row text-md-start col-md-auto'>
                                        <span className="user-profile-circle me-md-3 mx-auto rounded-pill border border-2 border-danger p-1 d-inline-block position-relative">
                                            <img src="./assets/black_scoop.png" className='rounded-pill border-2' alt="food" />
                                        </span>
                                        <Col className="itemDetails">
                                            <Col as="div" className="itemHead mb-2">
                                                <h4 className="d-inline-block align-middle GrayDark fs46 semibold">City Home Cafe</h4>
                                            </Col>
                                            <Col as="p" className="d-inline-flex border04 grayf7f py-2 px-3 shadowMD align-items-center semibold align-middle">
                                                <Col className='border-end pe-3 me-3'>
                                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.0013 26.0947L15.8379 14.4258H24.1647L20.0013 26.0947Z" fill="#6992F2"/>
                                                    <path d="M0 14.3711L19.3602 31.4961L13.5297 14.4017L0 14.3711Z" fill="#3169ED"/>
                                                    <path d="M12.8381 12.0727L0.0273438 12.0743L6.42691 0.878906L12.8381 12.0727Z" fill="#4377EF"/>
                                                    <path d="M39.9998 14.3867L20.6396 31.5117L26.4701 14.4173L39.9998 14.3867Z" fill="#7CA0F4"/>
                                                    <path d="M27.1631 12.0845L39.9738 12.086L33.5859 0.890625L27.1631 12.0845Z" fill="#A1BBF7"/>
                                                    <path d="M24.2077 11.9639L15.8809 11.9649L20.0559 4.72656L24.2077 11.9639Z" fill="#8EADF6"/>
                                                    <path d="M19.5222 0.00155315L8.61328 0L14.0628 9.53337L19.5222 0.00155315Z" fill="#5684F0"/>
                                                    <path d="M20.5225 0.00155315L31.4314 0L25.9918 9.53337L20.5225 0.00155315Z" fill="#7CA0F4"/>
                                                    </svg>
                                                </Col>    
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6758 0.928603C12.0926 -0.309534 13.9074 -0.309534 14.3242 0.928603L16.4386 7.20961C16.6249 7.76332 17.1596 8.13821 17.7627 8.13821H24.605C25.9538 8.13821 26.5146 9.80412 25.4234 10.5693L19.8878 14.4512C19.3998 14.7934 19.1957 15.4 19.3821 15.9537L21.4965 22.2348C21.9132 23.4729 20.445 24.5025 19.3539 23.7372L13.8184 19.8554C13.3304 19.5131 12.6696 19.5131 12.1816 19.8554L6.64609 23.7372C5.55492 24.5025 4.08674 23.4729 4.50353 22.2348L6.61791 15.9537C6.8043 15.4 6.6001 14.7934 6.11212 14.4512L0.57661 10.5693C-0.514582 9.80412 0.0462183 8.13821 1.39499 8.13821H8.23727C8.84045 8.13821 9.37505 7.76332 9.56144 7.20961L11.6758 0.928603Z" fill="#EF9309"/>
                                                    </svg>
                                                <Col as="span" className='col-auto px-2 lh-1 align-middle fs26 GrayComn semibold'>
                                                    4.5
                                                </Col>
                                                <Link to="/" className='d-inline-block themeColor ps-3 semibold fs20 text-decoration-none'>See Reviews (55)</Link>
                                            </Col>
                                        </Col>
                                    </Col>
                                    <Col md="auto" className='text-md-end py-md-4 mt-2 text-center'>
                                        <Button variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 ">
                                        <svg className='align-text-bottom me-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3335 2.41536C1.3335 1.81706 1.81853 1.33203 2.41683 1.33203H17.5835C18.1818 1.33203 18.6668 1.81706 18.6668 2.41536V4.58203C18.6668 5.18033 18.1818 5.66536 17.5835 5.66536H2.41683C1.81853 5.66536 1.3335 5.18033 1.3335 4.58203V2.41536Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3335 11.0833C1.3335 10.485 1.81853 10 2.41683 10H8.91683C9.51515 10 10.0002 10.485 10.0002 11.0833V17.5833C10.0002 18.1817 9.51515 18.6667 8.91683 18.6667H2.41683C1.81853 18.6667 1.3335 18.1817 1.3335 17.5833V11.0833Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3335 11.0833C14.3335 10.485 14.8185 10 15.4168 10H17.5835C18.1818 10 18.6668 10.485 18.6668 11.0833V17.5833C18.6668 18.1817 18.1818 18.6667 17.5835 18.6667H15.4168C14.8185 18.6667 14.3335 18.1817 14.3335 17.5833V11.0833Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        Selection Template
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col as="section" className='ms-0 ms-xl-5 ps-xl-5 mb-5 ps-0'>
                            <Col className='container px-xl-5 px-lg-4 px-0'>
                                <Row>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                                <svg className="mt-5" width="74" height="107" viewBox="0 0 74 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M63.5909 16.1008H35.7518V4.63762C35.7518 4.00817 35.3914 3.43428 34.8233 3.1616C34.256 2.88892 33.582 2.96567 33.0907 3.35899L25.9263 9.09057L18.7618 3.35899C18.2706 2.96567 17.5965 2.88892 17.0292 3.1616C16.4612 3.43428 16.1008 4.00817 16.1008 4.63762V16.1008H9.55038C7.81337 16.103 6.14867 16.7931 4.92057 18.0214C3.69247 19.2496 3.00225 20.9149 3 22.6512V99.6181C3.00146 100.921 3.51978 102.17 4.44021 103.091C5.36136 104.011 6.61006 104.529 7.91284 104.531H57.0407C58.3434 104.529 59.5921 104.011 60.5133 103.091C61.4337 102.17 61.9521 100.921 61.9535 99.6181V88.1549H66.8663C68.1691 88.1535 69.4178 87.6351 70.3389 86.7147C71.2594 85.7936 71.7777 84.5449 71.7791 83.2421V24.2887C71.7762 22.1181 70.9128 20.0368 69.3783 18.5023C67.8431 16.967 65.7618 16.1037 63.5912 16.1007L63.5909 16.1008ZM19.3758 8.04513L24.9027 12.4666C25.5014 12.9448 26.3509 12.9448 26.9498 12.4666L32.4766 8.04513V25.9263H19.3758V8.04513ZM9.55027 19.3767H16.1006V25.9271L9.55027 25.9264C8.37983 25.9264 7.29862 25.302 6.71376 24.2888C6.12891 23.2755 6.12891 22.0268 6.71376 21.0136C7.29862 20.0003 8.37986 19.376 9.55027 19.376V19.3767ZM58.6781 99.6188C58.6774 100.523 57.9448 101.256 57.0405 101.256H7.91267C7.00835 101.256 6.27581 100.523 6.27508 99.6188V28.3227C7.27006 28.9002 8.40021 29.2029 9.55027 29.2014H57.0405C57.9448 29.2029 58.6774 29.9354 58.6781 30.839V99.6188ZM68.5037 83.2429C68.5029 84.1465 67.7704 84.8798 66.8661 84.8805H61.9533V30.8399C61.9518 29.5379 61.4335 28.2892 60.5131 27.368C59.5919 26.4468 58.3432 25.9285 57.0404 25.9271H35.7517V19.3767H63.5908C64.8936 19.3781 66.1423 19.8965 67.0635 20.8176C67.9839 21.7388 68.5022 22.9875 68.5037 24.2895V83.2429Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                                <path d="M52.1254 71.784H48.8502C48.8451 67.7258 47.3354 63.8139 44.6129 60.8041C41.8903 57.795 38.1488 55.9016 34.1119 55.4906V53.7704C34.1119 52.8654 33.3786 52.1328 32.4743 52.1328C31.5699 52.1328 30.8367 52.8654 30.8367 53.7704V55.4913V55.4906C26.7998 55.9015 23.0582 57.7948 20.3357 60.8041C17.6131 63.8139 16.1036 67.7258 16.0983 71.784H12.8231C11.9188 71.784 11.1855 72.5173 11.1855 73.4216C11.1855 74.3259 11.9188 75.0592 12.8231 75.0592H52.1254C53.0297 75.0592 53.763 74.3259 53.763 73.4216C53.763 72.5173 53.0297 71.784 52.1254 71.784ZM19.3735 71.784C19.3735 67.1037 21.8709 62.7787 25.9239 60.4386C29.9769 58.0977 34.9717 58.0977 39.0247 60.4386C43.0777 62.7787 45.575 67.1037 45.575 71.784H19.3735Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                                </svg>
                                                <Col as="p" className='fs28 semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Bundled Deals</Col>
                                            </Link>
                                        </Col>
                                    </Col>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                                <svg className="mt-5" width="74" height="107" viewBox="0 0 74 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M63.5909 16.1008H35.7518V4.63762C35.7518 4.00817 35.3914 3.43428 34.8233 3.1616C34.256 2.88892 33.582 2.96567 33.0907 3.35899L25.9263 9.09057L18.7618 3.35899C18.2706 2.96567 17.5965 2.88892 17.0292 3.1616C16.4612 3.43428 16.1008 4.00817 16.1008 4.63762V16.1008H9.55038C7.81337 16.103 6.14867 16.7931 4.92057 18.0214C3.69247 19.2496 3.00225 20.9149 3 22.6512V99.6181C3.00146 100.921 3.51978 102.17 4.44021 103.091C5.36136 104.011 6.61006 104.529 7.91284 104.531H57.0407C58.3434 104.529 59.5921 104.011 60.5133 103.091C61.4337 102.17 61.9521 100.921 61.9535 99.6181V88.1549H66.8663C68.1691 88.1535 69.4178 87.6351 70.3389 86.7147C71.2594 85.7936 71.7777 84.5449 71.7791 83.2421V24.2887C71.7762 22.1181 70.9128 20.0368 69.3783 18.5023C67.8431 16.967 65.7618 16.1037 63.5912 16.1007L63.5909 16.1008ZM19.3758 8.04513L24.9027 12.4666C25.5014 12.9448 26.3509 12.9448 26.9498 12.4666L32.4766 8.04513V25.9263H19.3758V8.04513ZM9.55027 19.3767H16.1006V25.9271L9.55027 25.9264C8.37983 25.9264 7.29862 25.302 6.71376 24.2888C6.12891 23.2755 6.12891 22.0268 6.71376 21.0136C7.29862 20.0003 8.37986 19.376 9.55027 19.376V19.3767ZM58.6781 99.6188C58.6774 100.523 57.9448 101.256 57.0405 101.256H7.91267C7.00835 101.256 6.27581 100.523 6.27508 99.6188V28.3227C7.27006 28.9002 8.40021 29.2029 9.55027 29.2014H57.0405C57.9448 29.2029 58.6774 29.9354 58.6781 30.839V99.6188ZM68.5037 83.2429C68.5029 84.1465 67.7704 84.8798 66.8661 84.8805H61.9533V30.8399C61.9518 29.5379 61.4335 28.2892 60.5131 27.368C59.5919 26.4468 58.3432 25.9285 57.0404 25.9271H35.7517V19.3767H63.5908C64.8936 19.3781 66.1423 19.8965 67.0635 20.8176C67.9839 21.7388 68.5022 22.9875 68.5037 24.2895V83.2429Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                                <path d="M52.1254 71.784H48.8502C48.8451 67.7258 47.3354 63.8139 44.6129 60.8041C41.8903 57.795 38.1488 55.9016 34.1119 55.4906V53.7704C34.1119 52.8654 33.3786 52.1328 32.4743 52.1328C31.5699 52.1328 30.8367 52.8654 30.8367 53.7704V55.4913V55.4906C26.7998 55.9015 23.0582 57.7948 20.3357 60.8041C17.6131 63.8139 16.1036 67.7258 16.0983 71.784H12.8231C11.9188 71.784 11.1855 72.5173 11.1855 73.4216C11.1855 74.3259 11.9188 75.0592 12.8231 75.0592H52.1254C53.0297 75.0592 53.763 74.3259 53.763 73.4216C53.763 72.5173 53.0297 71.784 52.1254 71.784ZM19.3735 71.784C19.3735 67.1037 21.8709 62.7787 25.9239 60.4386C29.9769 58.0977 34.9717 58.0977 39.0247 60.4386C43.0777 62.7787 45.575 67.1037 45.575 71.784H19.3735Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                                </svg>
                                                <Col as="p" className='fs28 semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Mains</Col>
                                            </Link>
                                        </Col>
                                    </Col>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                            <svg className="mt-5" width="74" height="107" viewBox="0 0 74 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5909 16.1008H35.7518V4.63762C35.7518 4.00817 35.3914 3.43428 34.8233 3.1616C34.256 2.88892 33.582 2.96567 33.0907 3.35899L25.9263 9.09057L18.7618 3.35899C18.2706 2.96567 17.5965 2.88892 17.0292 3.1616C16.4612 3.43428 16.1008 4.00817 16.1008 4.63762V16.1008H9.55038C7.81337 16.103 6.14867 16.7931 4.92057 18.0214C3.69247 19.2496 3.00225 20.9149 3 22.6512V99.6181C3.00146 100.921 3.51978 102.17 4.44021 103.091C5.36136 104.011 6.61006 104.529 7.91284 104.531H57.0407C58.3434 104.529 59.5921 104.011 60.5133 103.091C61.4337 102.17 61.9521 100.921 61.9535 99.6181V88.1549H66.8663C68.1691 88.1535 69.4178 87.6351 70.3389 86.7147C71.2594 85.7936 71.7777 84.5449 71.7791 83.2421V24.2887C71.7762 22.1181 70.9128 20.0368 69.3783 18.5023C67.8431 16.967 65.7618 16.1037 63.5912 16.1007L63.5909 16.1008ZM19.3758 8.04513L24.9027 12.4666C25.5014 12.9448 26.3509 12.9448 26.9498 12.4666L32.4766 8.04513V25.9263H19.3758V8.04513ZM9.55027 19.3767H16.1006V25.9271L9.55027 25.9264C8.37983 25.9264 7.29862 25.302 6.71376 24.2888C6.12891 23.2755 6.12891 22.0268 6.71376 21.0136C7.29862 20.0003 8.37986 19.376 9.55027 19.376V19.3767ZM58.6781 99.6188C58.6774 100.523 57.9448 101.256 57.0405 101.256H7.91267C7.00835 101.256 6.27581 100.523 6.27508 99.6188V28.3227C7.27006 28.9002 8.40021 29.2029 9.55027 29.2014H57.0405C57.9448 29.2029 58.6774 29.9354 58.6781 30.839V99.6188ZM68.5037 83.2429C68.5029 84.1465 67.7704 84.8798 66.8661 84.8805H61.9533V30.8399C61.9518 29.5379 61.4335 28.2892 60.5131 27.368C59.5919 26.4468 58.3432 25.9285 57.0404 25.9271H35.7517V19.3767H63.5908C64.8936 19.3781 66.1423 19.8965 67.0635 20.8176C67.9839 21.7388 68.5022 22.9875 68.5037 24.2895V83.2429Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            <path d="M52.1254 71.784H48.8502C48.8451 67.7258 47.3354 63.8139 44.6129 60.8041C41.8903 57.795 38.1488 55.9016 34.1119 55.4906V53.7704C34.1119 52.8654 33.3786 52.1328 32.4743 52.1328C31.5699 52.1328 30.8367 52.8654 30.8367 53.7704V55.4913V55.4906C26.7998 55.9015 23.0582 57.7948 20.3357 60.8041C17.6131 63.8139 16.1036 67.7258 16.0983 71.784H12.8231C11.9188 71.784 11.1855 72.5173 11.1855 73.4216C11.1855 74.3259 11.9188 75.0592 12.8231 75.0592H52.1254C53.0297 75.0592 53.763 74.3259 53.763 73.4216C53.763 72.5173 53.0297 71.784 52.1254 71.784ZM19.3735 71.784C19.3735 67.1037 21.8709 62.7787 25.9239 60.4386C29.9769 58.0977 34.9717 58.0977 39.0247 60.4386C43.0777 62.7787 45.575 67.1037 45.575 71.784H19.3735Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            </svg>
                                            <Col as="p" className='fs28 semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Rice Specials</Col>
                                        </Link>
                                        </Col>
                                    </Col>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                            <svg className="mt-5" width="74" height="107" viewBox="0 0 74 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5909 16.1008H35.7518V4.63762C35.7518 4.00817 35.3914 3.43428 34.8233 3.1616C34.256 2.88892 33.582 2.96567 33.0907 3.35899L25.9263 9.09057L18.7618 3.35899C18.2706 2.96567 17.5965 2.88892 17.0292 3.1616C16.4612 3.43428 16.1008 4.00817 16.1008 4.63762V16.1008H9.55038C7.81337 16.103 6.14867 16.7931 4.92057 18.0214C3.69247 19.2496 3.00225 20.9149 3 22.6512V99.6181C3.00146 100.921 3.51978 102.17 4.44021 103.091C5.36136 104.011 6.61006 104.529 7.91284 104.531H57.0407C58.3434 104.529 59.5921 104.011 60.5133 103.091C61.4337 102.17 61.9521 100.921 61.9535 99.6181V88.1549H66.8663C68.1691 88.1535 69.4178 87.6351 70.3389 86.7147C71.2594 85.7936 71.7777 84.5449 71.7791 83.2421V24.2887C71.7762 22.1181 70.9128 20.0368 69.3783 18.5023C67.8431 16.967 65.7618 16.1037 63.5912 16.1007L63.5909 16.1008ZM19.3758 8.04513L24.9027 12.4666C25.5014 12.9448 26.3509 12.9448 26.9498 12.4666L32.4766 8.04513V25.9263H19.3758V8.04513ZM9.55027 19.3767H16.1006V25.9271L9.55027 25.9264C8.37983 25.9264 7.29862 25.302 6.71376 24.2888C6.12891 23.2755 6.12891 22.0268 6.71376 21.0136C7.29862 20.0003 8.37986 19.376 9.55027 19.376V19.3767ZM58.6781 99.6188C58.6774 100.523 57.9448 101.256 57.0405 101.256H7.91267C7.00835 101.256 6.27581 100.523 6.27508 99.6188V28.3227C7.27006 28.9002 8.40021 29.2029 9.55027 29.2014H57.0405C57.9448 29.2029 58.6774 29.9354 58.6781 30.839V99.6188ZM68.5037 83.2429C68.5029 84.1465 67.7704 84.8798 66.8661 84.8805H61.9533V30.8399C61.9518 29.5379 61.4335 28.2892 60.5131 27.368C59.5919 26.4468 58.3432 25.9285 57.0404 25.9271H35.7517V19.3767H63.5908C64.8936 19.3781 66.1423 19.8965 67.0635 20.8176C67.9839 21.7388 68.5022 22.9875 68.5037 24.2895V83.2429Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            <path d="M52.1254 71.784H48.8502C48.8451 67.7258 47.3354 63.8139 44.6129 60.8041C41.8903 57.795 38.1488 55.9016 34.1119 55.4906V53.7704C34.1119 52.8654 33.3786 52.1328 32.4743 52.1328C31.5699 52.1328 30.8367 52.8654 30.8367 53.7704V55.4913V55.4906C26.7998 55.9015 23.0582 57.7948 20.3357 60.8041C17.6131 63.8139 16.1036 67.7258 16.0983 71.784H12.8231C11.9188 71.784 11.1855 72.5173 11.1855 73.4216C11.1855 74.3259 11.9188 75.0592 12.8231 75.0592H52.1254C53.0297 75.0592 53.763 74.3259 53.763 73.4216C53.763 72.5173 53.0297 71.784 52.1254 71.784ZM19.3735 71.784C19.3735 67.1037 21.8709 62.7787 25.9239 60.4386C29.9769 58.0977 34.9717 58.0977 39.0247 60.4386C43.0777 62.7787 45.575 67.1037 45.575 71.784H19.3735Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            </svg>
                                            <Col as="p" className='fs28 semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Sides</Col>
                                        </Link>
                                        </Col>
                                    </Col>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                            <svg className="mt-5" width="74" height="107" viewBox="0 0 74 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5909 16.1008H35.7518V4.63762C35.7518 4.00817 35.3914 3.43428 34.8233 3.1616C34.256 2.88892 33.582 2.96567 33.0907 3.35899L25.9263 9.09057L18.7618 3.35899C18.2706 2.96567 17.5965 2.88892 17.0292 3.1616C16.4612 3.43428 16.1008 4.00817 16.1008 4.63762V16.1008H9.55038C7.81337 16.103 6.14867 16.7931 4.92057 18.0214C3.69247 19.2496 3.00225 20.9149 3 22.6512V99.6181C3.00146 100.921 3.51978 102.17 4.44021 103.091C5.36136 104.011 6.61006 104.529 7.91284 104.531H57.0407C58.3434 104.529 59.5921 104.011 60.5133 103.091C61.4337 102.17 61.9521 100.921 61.9535 99.6181V88.1549H66.8663C68.1691 88.1535 69.4178 87.6351 70.3389 86.7147C71.2594 85.7936 71.7777 84.5449 71.7791 83.2421V24.2887C71.7762 22.1181 70.9128 20.0368 69.3783 18.5023C67.8431 16.967 65.7618 16.1037 63.5912 16.1007L63.5909 16.1008ZM19.3758 8.04513L24.9027 12.4666C25.5014 12.9448 26.3509 12.9448 26.9498 12.4666L32.4766 8.04513V25.9263H19.3758V8.04513ZM9.55027 19.3767H16.1006V25.9271L9.55027 25.9264C8.37983 25.9264 7.29862 25.302 6.71376 24.2888C6.12891 23.2755 6.12891 22.0268 6.71376 21.0136C7.29862 20.0003 8.37986 19.376 9.55027 19.376V19.3767ZM58.6781 99.6188C58.6774 100.523 57.9448 101.256 57.0405 101.256H7.91267C7.00835 101.256 6.27581 100.523 6.27508 99.6188V28.3227C7.27006 28.9002 8.40021 29.2029 9.55027 29.2014H57.0405C57.9448 29.2029 58.6774 29.9354 58.6781 30.839V99.6188ZM68.5037 83.2429C68.5029 84.1465 67.7704 84.8798 66.8661 84.8805H61.9533V30.8399C61.9518 29.5379 61.4335 28.2892 60.5131 27.368C59.5919 26.4468 58.3432 25.9285 57.0404 25.9271H35.7517V19.3767H63.5908C64.8936 19.3781 66.1423 19.8965 67.0635 20.8176C67.9839 21.7388 68.5022 22.9875 68.5037 24.2895V83.2429Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            <path d="M52.1254 71.784H48.8502C48.8451 67.7258 47.3354 63.8139 44.6129 60.8041C41.8903 57.795 38.1488 55.9016 34.1119 55.4906V53.7704C34.1119 52.8654 33.3786 52.1328 32.4743 52.1328C31.5699 52.1328 30.8367 52.8654 30.8367 53.7704V55.4913V55.4906C26.7998 55.9015 23.0582 57.7948 20.3357 60.8041C17.6131 63.8139 16.1036 67.7258 16.0983 71.784H12.8231C11.9188 71.784 11.1855 72.5173 11.1855 73.4216C11.1855 74.3259 11.9188 75.0592 12.8231 75.0592H52.1254C53.0297 75.0592 53.763 74.3259 53.763 73.4216C53.763 72.5173 53.0297 71.784 52.1254 71.784ZM19.3735 71.784C19.3735 67.1037 21.8709 62.7787 25.9239 60.4386C29.9769 58.0977 34.9717 58.0977 39.0247 60.4386C43.0777 62.7787 45.575 67.1037 45.575 71.784H19.3735Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            </svg>
                                            <Col as="p" className='fs28 semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Desserts</Col>
                                        </Link>
                                        </Col>
                                    </Col>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                            <svg className="mt-5" width="74" height="107" viewBox="0 0 74 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5909 16.1008H35.7518V4.63762C35.7518 4.00817 35.3914 3.43428 34.8233 3.1616C34.256 2.88892 33.582 2.96567 33.0907 3.35899L25.9263 9.09057L18.7618 3.35899C18.2706 2.96567 17.5965 2.88892 17.0292 3.1616C16.4612 3.43428 16.1008 4.00817 16.1008 4.63762V16.1008H9.55038C7.81337 16.103 6.14867 16.7931 4.92057 18.0214C3.69247 19.2496 3.00225 20.9149 3 22.6512V99.6181C3.00146 100.921 3.51978 102.17 4.44021 103.091C5.36136 104.011 6.61006 104.529 7.91284 104.531H57.0407C58.3434 104.529 59.5921 104.011 60.5133 103.091C61.4337 102.17 61.9521 100.921 61.9535 99.6181V88.1549H66.8663C68.1691 88.1535 69.4178 87.6351 70.3389 86.7147C71.2594 85.7936 71.7777 84.5449 71.7791 83.2421V24.2887C71.7762 22.1181 70.9128 20.0368 69.3783 18.5023C67.8431 16.967 65.7618 16.1037 63.5912 16.1007L63.5909 16.1008ZM19.3758 8.04513L24.9027 12.4666C25.5014 12.9448 26.3509 12.9448 26.9498 12.4666L32.4766 8.04513V25.9263H19.3758V8.04513ZM9.55027 19.3767H16.1006V25.9271L9.55027 25.9264C8.37983 25.9264 7.29862 25.302 6.71376 24.2888C6.12891 23.2755 6.12891 22.0268 6.71376 21.0136C7.29862 20.0003 8.37986 19.376 9.55027 19.376V19.3767ZM58.6781 99.6188C58.6774 100.523 57.9448 101.256 57.0405 101.256H7.91267C7.00835 101.256 6.27581 100.523 6.27508 99.6188V28.3227C7.27006 28.9002 8.40021 29.2029 9.55027 29.2014H57.0405C57.9448 29.2029 58.6774 29.9354 58.6781 30.839V99.6188ZM68.5037 83.2429C68.5029 84.1465 67.7704 84.8798 66.8661 84.8805H61.9533V30.8399C61.9518 29.5379 61.4335 28.2892 60.5131 27.368C59.5919 26.4468 58.3432 25.9285 57.0404 25.9271H35.7517V19.3767H63.5908C64.8936 19.3781 66.1423 19.8965 67.0635 20.8176C67.9839 21.7388 68.5022 22.9875 68.5037 24.2895V83.2429Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            <path d="M52.1254 71.784H48.8502C48.8451 67.7258 47.3354 63.8139 44.6129 60.8041C41.8903 57.795 38.1488 55.9016 34.1119 55.4906V53.7704C34.1119 52.8654 33.3786 52.1328 32.4743 52.1328C31.5699 52.1328 30.8367 52.8654 30.8367 53.7704V55.4913V55.4906C26.7998 55.9015 23.0582 57.7948 20.3357 60.8041C17.6131 63.8139 16.1036 67.7258 16.0983 71.784H12.8231C11.9188 71.784 11.1855 72.5173 11.1855 73.4216C11.1855 74.3259 11.9188 75.0592 12.8231 75.0592H52.1254C53.0297 75.0592 53.763 74.3259 53.763 73.4216C53.763 72.5173 53.0297 71.784 52.1254 71.784ZM19.3735 71.784C19.3735 67.1037 21.8709 62.7787 25.9239 60.4386C29.9769 58.0977 34.9717 58.0977 39.0247 60.4386C43.0777 62.7787 45.575 67.1037 45.575 71.784H19.3735Z" fill="#404040" stroke="#404040" stroke-width="4.3"/>
                                            </svg>
                                            <Col as="p" className='fs28 semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Drinks</Col>
                                        </Link>
                                        </Col>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="4" className='px-lg-2 px-xl-4 px-md-3'>
                                        <Col className='add-cribbox border12 text-center lightred shadowMD '>
                                            <Col role="button" onClick={setShow} className='d-block py-5 px-2 text-decoration-none'>
                                                <svg className="mt-5" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="22.8086" width="14.4" height="60" rx="7.2" fill="#C32148"/>
                                                <rect y="37.1758" width="14.4" height="60" rx="7.2" transform="rotate(-90 0 37.1758)" fill="#C32148"/>
                                                </svg>

                                                <Col as="p" className='fs28 semibold mt-4 mb-5 mb-0 pt-2 GrayComn'>Add New Menu</Col>
                                            </Col>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Modal 
                className="order-response" 
                backdropClassName="action1"
                dialogClassName="action2 successfullyPop"
                contentClassName="action3 bgtheme border-0 border12"
                show={show} 
                centered
                onHide={handleClose}>
                <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
                <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white"/>
                </svg>
                </Modal.Header>
                <Modal.Body className='text-center px-5 pb-5'>
                    <Col className="modal-content-inner">
                        <Col as="h3" className="p-0 mb-1 fs32 colorwhite">Order Response</Col>
                        <Col as="p" className="px-2 colorwhite fs24 mb-5 lh-sm pb-2">Do you wish to Accept this <br className='d-none d-lg-inline' /> Order Request?</Col>
                    </Col>
                </Modal.Body>
            </Modal>
            <Footer />
        </div>
    )    
}
export default HostMenuone;