import React from 'react'
import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./dinerstyle.css"
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';

const UserInterface = () => {
    
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">User Interface Tutorial</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Support</Breadcrumb.Item>
                            <Breadcrumb.Item>User Iinterface Tutorial</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="outerspace">
                                <Col as="section" className="innerspace royalImg">
                                    
                                    <Row className="m-0 text-center vh-100 align-items-center justify-content-center">
                                        <Col lg="5">
                                            <h2 className="fs80vw GrayComn semibold">Coming Soon</h2>
                                            <p className="fs18 GrayComn semibold lh-sm mb-0">Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                            <Button href="#" variant="danger" className="rounded-pill py-2 fs20 semibold px-5 mt-5">Contact Us</Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default UserInterface