import React, { useState } from 'react'
import { Container, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./dinerstyle.css"
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';

const PrivacySetting = () => {
    
    const [alpha, setAlpha] = useState();
    const [alpha01, setAlpha01] = useState();
    const [alpha02, setAlpha02] = useState();    

    const getCheckedValue = (e) => {
        switch (e.target.name) {
            case "case01":
                e.target.checked ? setAlpha("off") : setAlpha("On")
                break;
            case "case02":
                e.target.checked ? setAlpha01("On") : setAlpha01("Off")
                break;
            case "case03":
                e.target.checked ? setAlpha02("On") : setAlpha02("Off")
                break;            
            default:
                break;
        }
    }

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Privacy</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account</Breadcrumb.Item>
                            <Breadcrumb.Item>Privacy Settings</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="px-lg-5 outerspace">
                                <Col className="px-lg-4 innerspace">
                                <Row>
                                    <Col lg="9">
                                        <h2 className="fs28 GrayComn semibold mb-1 lh-1">Personal data</h2>
                                        <p className="fs15 gray82 semibold mb-lg-5 pb-2">User have the option here to request for type of data Cribbly retains / collected from User, and request deletion of Account and removal of all personal data from Cribbly</p>
                                    </Col>
                                    <Col lg="3" className="text-end">
                                        <h2 className="fs28 GrayComn semibold mb-1 lh-1 d-none d-lg-block">&nbsp;</h2>
                                        <Form.Check 
                                            className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize mb-5 mb-sm-0"
                                            type="switch"
                                            id="custom-switch"
                                            label={alpha ? alpha : "On"}
                                            checked
                                            name="case01"
                                            onChange={getCheckedValue}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="9">
                                    <h2 className="fs28 GrayComn semibold mb-1 lh-1">Third-party tools</h2>
                                    <p className="fs15 gray82 semibold mb-lg-5 pb-2">All third-party tools integrated with Cribbly platform that collects, processes and / or retains User data</p>
                                    </Col>
                                    <Col lg="3" className="text-end">
                                        <h2 className="fs28 GrayComn semibold mb-1 lh-1 d-none d-lg-block">&nbsp;</h2>
                                        <Form.Check 
                                            className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize mb-5 mb-sm-0"
                                            type="switch"
                                            id="custom-switch01"
                                            label={alpha01 ? alpha01: "Off"}
                                            name="case02"
                                            onChange={getCheckedValue}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="9">
                                    <h2 className="fs28 GrayComn semibold mb-1 lh-1">Social Media and Search Engines</h2>
                                    <p className="fs15 gray82 semibold mb-lg-5 pb-2">User have the option to manage which social media app has access to their data on Cribbly, this include photos taken and uploaded on the App, purchases, reviews…etc. </p>
                                    </Col>
                                    <Col lg="3" className="text-end">
                                        <h2 className="fs28 GrayComn semibold mb-1 lh-1 d-none d-lg-block">&nbsp;</h2>
                                        <Form.Check 
                                            className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize"
                                            type="switch"
                                            id="custom-switch02"
                                            label={alpha02 ? alpha02: "Off"}
                                            name="case03"
                                            onChange={getCheckedValue}
                                        />
                                    </Col>
                                </Row>
                                </Col>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>                        
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default PrivacySetting