import React from 'react'
import { Container, Row, Col, Breadcrumb, Form, Button, ListGroup, Tabs, Tab, Badge } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./dinerstyle.css"
import Header from "../header";
import Footer from "../footer";
import ProfileMenu from "../profilemenu";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Slider from "react-slick";
import "react-pro-sidebar/dist/css/styles.css";

const ReferAndEarn = () => {
    const currentTier = {
        dots: true,
        arrows: false,
        infinite: true,
        swipeToSlide: true,
        centerMode: false,
        centerPadding: "0",
        slidesToShow: 1,
        slidesToScroll: 1,
        easing: 'cubic',
        
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },{
                breakpoint: 1270,
                settings: {
                    slidesToShow: 1                    
                }
            }
        ]
    };

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Refer & Earn</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Refer & Earn</Breadcrumb.Item>                            
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="px-lg-5 outerspace">
                                <Col className="px-lg-4 innerspace">
                                    <Row>
                                        <Col className="mt-lg-5">
                                            <h2 className="mt-lg-3 fs80vw GrayComn semibold mb-0">Refer A Friend</h2>
                                            <p className="fs18 GrayComn semibold lh-sm mb-0 pb-1">Share the love and be rewarded! <br className="d-none d-lg-inline" />Check out our referral programs today and enjoy a treat on <br className="d-none d-lg-inline" />us when have you successfully referred a friend!t.</p>
                                            <Form className="RefCode mt-5">
                                                <Form.Label className="fs20 gray82 semibold lh-1">Your Referral Code</Form.Label>
                                                <Form.Group className="mb3 position-relative" controlId="formBasicEmail">
                                                    <Form.Control type="text" placeholder="AC_JD123" className="shadow-sm bgLight border-0" />
                                                    <Button variant="primary" type="submit" className="position-absolute fs20 px-3">
                                                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" className="align-text-bottom me-2" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 7.875C10 7.32272 9.55228 6.875 9 6.875C8.44772 6.875 8 7.32272 8 7.875H10ZM18 20.125C18.5523 20.125 19 19.6773 19 19.125C19 18.5727 18.5523 18.125 18 18.125V20.125ZM8 7.875C8 8.42728 8.44772 8.875 9 8.875C9.55228 8.875 10 8.42728 10 7.875H8ZM17.2045 3.7045L16.4973 4.41155L16.4974 4.41161L17.2045 3.7045ZM22.1705 8.6705L22.8777 7.96345L22.8776 7.96339L22.1705 8.6705ZM18 18.125C17.4477 18.125 17 18.5727 17 19.125C17 19.6773 17.4477 20.125 18 20.125V18.125ZM9 8.875C9.55228 8.875 10 8.42728 10 7.875C10 7.32272 9.55228 6.875 9 6.875V8.875ZM19 19.125C19 18.5727 18.5523 18.125 18 18.125C17.4477 18.125 17 18.5727 17 19.125H19ZM8 7.875V16.875H10V7.875H8ZM8 16.875C8 18.67 9.45508 20.125 11.25 20.125V18.125C10.5596 18.125 10 17.5654 10 16.875H8ZM11.25 20.125H18V18.125H11.25V20.125ZM10 7.875V5.625H8V7.875H10ZM10 5.625C10 4.93464 10.5596 4.375 11.25 4.375V2.375C9.45507 2.375 8 3.83007 8 5.625H10ZM11.25 4.375H16.409V2.375H11.25V4.375ZM16.409 4.375C16.4422 4.375 16.474 4.38821 16.4973 4.41155L17.9117 2.99745C17.5131 2.59885 16.9725 2.375 16.409 2.375V4.375ZM16.4974 4.41161L21.4634 9.3776L22.8776 7.96339L17.9116 2.9974L16.4974 4.41161ZM21.4633 9.37755C21.4868 9.40107 21.5 9.43294 21.5 9.46599H23.5C23.5 8.90231 23.276 8.36187 22.8777 7.96345L21.4633 9.37755ZM21.5 9.46599V16.875H23.5V9.46599H21.5ZM21.5 16.875C21.5 17.5654 20.9404 18.125 20.25 18.125V20.125C22.045 20.125 23.5 18.67 23.5 16.875H21.5ZM20.25 18.125H18V20.125H20.25V18.125ZM9 6.875H6.75V8.875H9V6.875ZM6.75 6.875C4.95507 6.875 3.5 8.33007 3.5 10.125H5.5C5.5 9.43464 6.05964 8.875 6.75 8.875V6.875ZM3.5 10.125V21.375H5.5V10.125H3.5ZM3.5 21.375C3.5 23.17 4.95508 24.625 6.75 24.625V22.625C6.05963 22.625 5.5 22.0654 5.5 21.375H3.5ZM6.75 24.625H15.75V22.625H6.75V24.625ZM15.75 24.625C17.545 24.625 19 23.17 19 21.375H17C17 22.0654 16.4404 22.625 15.75 22.625V24.625ZM19 21.375V19.125H17V21.375H19Z" fill="white"/>
                                                        </svg>
                                                        Copy</Button>
                                                </Form.Group>
                                            </Form>
                                            <ListGroup horizontal className="mt-5 pt-lg-3">
                                                <ListGroup.Item role="button" className="border-0 p-0 pe-1 me-3 socialicons"><img src={process.env.PUBLIC_URL+"assets/fb01.svg"} className="img-fluid" alt="paymentmethod" /></ListGroup.Item>
                                                <ListGroup.Item role="button" className="border-0 p-0 pe-1 me-3 socialicons"><img src={process.env.PUBLIC_URL+"assets/lnkd02.svg"} className="img-fluid" alt="paymentmethod" /></ListGroup.Item>
                                                <ListGroup.Item role="button" className="border-0 p-0 pe-1 me-3 socialicons"><img src={process.env.PUBLIC_URL+"assets/tw03.svg"} className="img-fluid" alt="paymentmethod" /></ListGroup.Item>
                                                <ListGroup.Item role="button" className="border-0 p-0 pe-1 me-3 socialicons"><img src={process.env.PUBLIC_URL+"assets/Insta04.svg"} className="img-fluid" alt="paymentmethod" /></ListGroup.Item>
                                                <ListGroup.Item role="button" className="border-0 p-0 pe-1 me-3 socialicons"><img src={process.env.PUBLIC_URL+"assets/whats05.svg"} className="img-fluid" alt="paymentmethod" /></ListGroup.Item>
                                                <ListGroup.Item role="button" className="border-0 p-0 pe-1 me-3 socialicons"><img src={process.env.PUBLIC_URL+"assets/teleg06.svg"} className="img-fluid" alt="paymentmethod" /></ListGroup.Item>
                                                <ListGroup.Item role="button" className="border-0 p-0 pe-1 me-3 socialicons"><img src={process.env.PUBLIC_URL+"assets/share07.svg"} className="img-fluid" alt="paymentmethod" /></ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                        <Col lg="7" className="text-end">
                                            <img src={process.env.PUBLIC_URL+"assets/referFriend.png"} className="img-fluid" alt="paymentmethod" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>                        
                        <Col as="section" className="rewards_Slider mt-3 mt-lg-5 pt-lg-4 px-md-4">
                            <Slider className="featureProd me-md-4" {...currentTier}>
                                <img src={process.env.PUBLIC_URL+"assets/refersliderbanner.png"} className="img-fluid border12" alt="paymentmethod" />
                                <img src={process.env.PUBLIC_URL+"assets/refersliderbanner.png"} className="img-fluid border12" alt="paymentmethod" />
                                <img src={process.env.PUBLIC_URL+"assets/refersliderbanner.png"} className="img-fluid border12" alt="paymentmethod" />
                                <img src={process.env.PUBLIC_URL+"assets/refersliderbanner.png"} className="img-fluid border12" alt="paymentmethod" />
                            </Slider>
                        </Col>
                        <Col as="section" className="referList mt-5 ps-md-4 pe-md-5">
                            <Col className="shadowSM tabsElite">
                                <Tabs
                                    defaultActiveKey="first"
                                    id="justify-tab-example"
                                    className="mb-0"
                                    justify
                                    >
                                    <Tab eventKey="first" title="Pending" className="pb-4 pt-5 tabRewards">
                                    <Scrollbars 
                                        className="cpnlist"
                                        autoHideTimeout={1000}
                                        autoHideDuration={1000}
                                        autoHeight
                                        autoHeightMax={600}
                                        thumbMinSize={300}
                                        universal={true}>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 pb-4 align-items-center">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 pb-4 align-items-center">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>                                    
                                    </Scrollbars>
                                    </Tab>                                    
                                    <Tab eventKey="second" title="Completed" className="pb-4 pt-5 tabRewards">
                                    <Scrollbars 
                                        className="cpnlist"
                                        autoHideTimeout={1000}
                                        autoHideDuration={1000}
                                        autoHeight
                                        autoHeightMax={600}
                                        thumbMinSize={300}
                                        universal={true}>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 pb-4 align-items-center">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 mb-4 pb-4 align-items-center border-bottom">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="mx-md-5 pb-4 align-items-center">
                                            <Col md="auto" lg="auto" className="ps-0">
                                                <span className="rounded-pill border border-2 border-danger p-1 d-inline-block rndSpan">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width={90} height={90} className="img-fluid rounded-pill border border-2 border-danger" alt="paymentmethod" />
                                                </span>
                                            </Col>
                                            <Col md="5" lg="5">
                                                <h5 className="fs28 GrayComn semibold mb-1">Ronald Richards</h5>
                                                <Badge className="p-0 fs24 semibold gray82" bg="transparent">Referral ID: WQ58RA5632</Badge>
                                            </Col>
                                            <Col>
                                                <h5 className="fs24 GrayComn semibold mb-1 pt-3">Pending Credit</h5>
                                                <Badge className="m-0 fs30 themeColor semibold pt-0 ps-0" bg="transparent">S$5</Badge>
                                            </Col>
                                            <Col md="auto" lg="auto">
                                                <h5 className="fs24 gray82 semibold mb-1 pt-3">Date Joined</h5>
                                                <Badge className="m-0 GrayComn fs24 semibold pt-1 ps-0" bg="transparent">02 July,2022</Badge>
                                            </Col>
                                        </Row>
                                        </Scrollbars>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Col>
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default ReferAndEarn