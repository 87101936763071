import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ReactFlagsSelect from "react-flags-select";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { HiHome } from "react-icons/hi";
import { Link } from 'react-router-dom';
import './loginpage.css';


import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

    const addClass = () => {
        document.getElementById("signUpFlip").classList.add("scalIn");
        setTimeout(function() {
            document.getElementById("scalOut").classList.add("scalOut");    
        }, 100);
        
        setTimeout(function() {
            document.getElementById("signUpFlip").classList.add("flip");
        }, 1000);
        
        setTimeout(function() {
            document.getElementById("scalOut").classList.remove("scalOut");    
        }, 1000);

        setTimeout(function() {
            document.getElementById("signUpFlip").classList.remove("scalIn");
        }, 1000);
    }

    const LoginPage = () => {
    const [selected, setSelected] = useState("");


    return (
        <div>
            <Container className="userLogin" fluid>
                <Row className="dFlex justify-content-center">
                    <Col md={11} className="row justify-content-center midContainer">
                        <Col md={5}>
                            <Col as="div" className="logo_login my-5 py-md-5">
                                <Link to="/" className="loginLogo mb-5 d-block"><img src="./assets/loginLogo.png" alt="logo" /></Link>                            
                                <Col className="headingLogin">
                                    <Col as="div" className="heading mb-5">
                                        <h2 className="m-0 small text-black">
                                            Enjoy a cozy hot meal at your neighbour's crib.
                                        </h2>
                                        <p className="parra mt-4">
                                            Join us and be rewarded whenever you eat with us. 
                                            A good taste deserves more treats.
                                        </p>
                                    </Col>
                                    <Col as="div" className="appStoreIcons pt-1">
                                        <h2>Download App</h2>
                                        <div className="d-inline-block aapblock">
                                            <img src="./assets/googlePlay.png" className="img-fluid"   alt='googlePayicon' />
                                            <img src="./assets/appStore.png" className="img-fluid"     alt='appStoreicon' />
                                        </div>
                                    </Col>                                    
                                </Col>
                                <br /><br />
                                <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-5">
                                    <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                                </Link>                                
                            </Col>
                        </Col>
                        <Col md={6} className="pe-0">
                            <Row className="d-md-flex h-100">
                                <Col className="rightLog position-relative mt-5">
                                <br /><br />
                                    <section id="scalOut" className="backImage position-absolute scalImg ">
                                        <img src="./assets/imageBunch.png" className="img-fluid" alt='bgpic' />
                                    </section>
                                    <section id="signUpFlip" className="mobilePur m-auto cardBox">
                                        <div className="front">
                                            <div className='heading mb-5'>
                                                <h2 className='small lh-1 fs-normal'>
                                                    Hi, <br />Welcome Back
                                                </h2>
                                            </div>
                                            <Form>
                                                
                                                <Form.Group controlId="formBasicEmail" label="Email or Contact Number" className="mb-3 pb-1">
                                                    <Form.Control type="email" placeholder="Email or Contact Number" />
                                                </Form.Group>
                                                
                                                <Form.Group controlId="formBasicPassword" label="Password" className="mb-1 position-relative">
                                                    <Form.Control type="password" placeholder="Password" />
                                                    <FiEye size="20" color="var(--bs-border)" className="showPass" />
                                                    <FiEyeOff size="20" color="var(--bs-border)" className="showPassOff" />
                                                </Form.Group>

                                                <Form.Group className="mt-4 mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                                                <div className="form-check d-inline-block">
                                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault5" />
                                                    <label className="form-check-label fw-bold pt-1 ps-1" for="flexRadioDefault5">Remember me</label>
                                                </div>
                                                    <Form.Label className='float-end'><Link to="#" className="forgotPass text-decoration-none">Forgot Password?</Link></Form.Label>                                        
                                                </Form.Group>
                                                
                                                <Button className="w-100 py-2 lh-lg border-0" variant="primary" type="submit">Login</Button>
                                            </Form>
                                            <Col md={12} className="orText position-relative">
                                                <span className="d-block text-center text-uppercase"><i>or</i></span>
                                            </Col>
                                            <Col md={12} className="d-flex justify-content-between socialButton">
                                                <FacebookLoginButton className="facebookplus fs-bold" onClick={() => alert("Hello")}>
                                                    <span>Login with Facebook</span>
                                                </FacebookLoginButton>
                                                <GoogleLoginButton className="googleplus fw-bold" onClick={() => alert("Hello")}>
                                                    <span>Login with Google</span>
                                                </GoogleLoginButton>                                    
                                            </Col>
                                            <Col md={12} className="signUpLink">
                                            <p className="text-center mt-4 mb-0">Don’t have an account? <Button className="text-decoration-none" onClick={addClass} >Sign up</Button></p>
                                            </Col>
                                        </div>
                                        <div className="back">
                                            <div className='heading mb-5'>
                                                <h2 className='small lh-1 fs-normal'>
                                                    Hi, <br />Welcome Back
                                                </h2>
                                            </div>
                                            <Form>
                                                <Form.Group controlId="formBasicEmail" label="Email or Contact Number" className="mb-3 pb-1 d-flex justify-content-between">
                                                    <Col as="div" className="w-25 bg-white flagBox">
                                                        <ReactFlagsSelect
                                                            selected={selected}
                                                            placeholder="sds"
                                                            fullWidth ={5}
                                                            onSelect={(code) => setSelected(code)}                                                        
                                                        />
                                                    </Col>
                                                    <Form.Control className="w70" type="email" placeholder="9123 4567" />
                                                </Form.Group>
                                                
                                                <Form.Group controlId="formBasicPassword" label="Password" className="mb-1 position-relative">
                                                    <Form.Control type="password" placeholder="Password" />
                                                    <FiEye size="20" color="var(--bs-border)" className="showPass" />
                                                    <FiEyeOff size="20" color="var(--bs-border)" className="showPassOff" />
                                                </Form.Group>

                                                <Form.Group className="mt-1 mb-3" controlId="exampleForm.ControlTextarea1">
                                                <div className="form-check d-inline-block">
                                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="back01" />
                                                    <label className="form-check-label fw-bold pt-1 ps-1" for="back01">Remember me</label>
                                                </div>
                                                    <Form.Label className='float-end'><Link to="#" className="forgotPass text-decoration-none">Forgot Password?</Link></Form.Label>                                        
                                                </Form.Group>
                                                
                                                <Button className="w-100 py-2 lh-lg border-0" variant="primary" type="submit">Login</Button>
                                            </Form>
                                            <Col md={12} className="orText position-relative">
                                                <span className="d-block text-center text-uppercase"><i>or</i></span>
                                            </Col>
                                            <Col md={12} className="d-flex justify-content-between socialButton">
                                                <FacebookLoginButton className="facebookplus fs-bold" onClick={() => alert("Hello")}>
                                                    <span>Login with Facebook</span>
                                                </FacebookLoginButton>
                                                <GoogleLoginButton className="googleplus fw-bold" onClick={() => alert("Hello")}>
                                                    <span>Login with Google</span>
                                                </GoogleLoginButton>                                    
                                            </Col>
                                            <Col md={12} className="signUpLink">
                                            <p className="text-center mt-3">Don’t have an account? <Link to="/loginthree" className="text-decoration-none">Sign up</Link></p>
                                            </Col>
                                        </div>
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container>            
        </div>        
    )
}
export default LoginPage