import React from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import { BsChevronRight } from 'react-icons/bs';
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from 'react-router-dom';
import "./dinerstyle.css"
import Header from "../header";
import Footer from '../footer';
import ProfileMenu from "../profilemenu";

const HelpCenter02 = () => {
    
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Help Centre</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Support</Breadcrumb.Item>
                            <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="outerspace">
                                <Col as="section" className="innerspace me-md-5">
                                    <Row className="ms-md-4 me-md-5 hoverclass">
                                        <Col className="faq_wrapper p-lg-0">
                                            <h2 class="fs28 GrayComn semibold mb-1 lh-1">Issue with a User</h2>
                                            <p class="GrayComn semibold mb-lg-5 pb-2">Please Choose A Subject</p>
                                        </Col>
                                        <Col className="px-0 ms-4" lg="12">
                                            <Link className="d-block GrayComn semibold fs20 text-decoration-none" to={{pathname: '/HelpCenter03', state: {any: "any"}}}>
                                                <BsChevronRight className="align-baseline" size="16" /> Subject 1
                                            </Link>
                                        </Col>
                                        <Col className="px-0 ms-4 pt-3 mt-5" lg="12">
                                            <Link className="d-block GrayComn semibold fs20 text-decoration-none" to={{pathname: '/HelpCenter03', state: {any: "any"}}}><BsChevronRight className="align-baseline" size="16" /> Subject 2</Link>
                                        </Col>
                                        <Col className="px-0 ms-4 pt-3 mt-5" lg="12">
                                            <Link className="d-block GrayComn semibold fs20 text-decoration-none" to={{pathname: '/HelpCenter03', state: {any: "any"}}}><BsChevronRight className="align-baseline" size="16" /> Subject 3</Link>
                                        </Col>
                                        <Col className="px-0 ms-4 pt-3 mt-5" lg="12">
                                            <Link className="d-block GrayComn semibold fs20 text-decoration-none" to={{pathname: '/HelpCenter03', state: {any: "any"}}}><BsChevronRight className="align-baseline" size="16" /> Subject 4</Link>
                                        </Col>
                                        <Col className="px-0 ms-4 pt-3 mt-5" lg="12">
                                            <Link className="d-block GrayComn semibold fs20 text-decoration-none" to={{pathname: '/HelpCenter03', state: {any: "any"}}}><BsChevronRight className="align-baseline" size="16" /> Others</Link>
                                        </Col>
                                    </Row>                                    
                                </Col>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default HelpCenter02