import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from "./components/home"
import Quantity from "./components/findmymeal/quantity"
import FindMyMeal from "./components/findmymeal/findmeal_one"
import OutletProfile from "./components/findmymeal/outletprofile"
import LoginPage from "./components/auth/loginpage"
import LoginThree from "./components/auth/loginthree"
import ForgotPassThree from "./components/auth/forgotpassthree";
import ForgotPassTwo from "./components/auth/forgotpasstwo"
import ForgotPassOne from "./components/auth/forgotpassone";
import NewPassword from "./components/auth/newpassword";

import DinerSignupOne from "./components/diner/dinersignup01";
import DinerSignupTwo from "./components/diner/dinersignup02";
import DinerSignupThree from "./components/diner/dinersignup03";
import DinerSignupFour from "./components/diner/dinersignup04";
import DinerSignupFive from "./components/diner/dinersignup05";
import DinerSignupSix from "./components/diner/dinersignup06";
import DinerSignupSeven from "./components/diner/dinersignup07";
import DinerSignupEight from "./components/diner/dinersignup08";
import DinerSignupNine from "./components/diner/dinersignup09";
import DinerSignupTen from "./components/diner/dinersignup10";
import DinerSignupEleven from "./components/diner/dinersignup11";

import CartSummery from "./components/checkout/cartsummery";
import CartCheckout from "./components/checkout/cartcheckout";
import ReviewAndRating from "./components/checkout/reviewandrating";
import Coupon from "./components/checkout/coupon";
import MyProfile from "./components/useraccount/myprofile";
import TrackYourOrder from "./components/useraccount/trackyourorder";

import Career from "./components/career/career";
import Career02 from "./components/career/career02";
import AboutUs from "./components/career/aboutus";
import BecomeAHost from "./components/career/becomeahost";
import ContactUs from "./components/career/contactus";
import Error from "./components/career/error";
import TermsofUse from "./components/career/termsofuse";
import PrivacyPolicy from "./components/career/privacypolicy";
import HostingLanding from "./components/career/hostinglanding";
import DinerPayment from "./components/dinerpanel/dinerpayment";
import MemberShip from "./components/dinerpanel/membership";
import TransactionHistory from "./components/dinerpanel/transactionhistory";
import NotificationAlert from "./components/dinerpanel/notificationalert";
import NotificationCenter from "./components/dinerpanel/notificationcenter";
import PrivacySetting from "./components/dinerpanel/privacysetting";
import RoyaltyProgram from "./components/dinerpanel/royaltyprogram";
import ReferAndEarn from "./components/dinerpanel/referandearn";
import UserInterface from "./components/dinerpanel/userinterface";
import Faq from "./components/dinerpanel/faq";
import Faq02 from "./components/dinerpanel/faq02";
import HelpCenter from "./components/dinerpanel/helpcenter";
import HelpCenter02 from "./components/dinerpanel/helpcenter02";
import HelpCenter03 from "./components/dinerpanel/helpcenter03";
import HostStepOne from "./components/hoststeps/hoststepone";
import HostCribOne from "./components/hoststeps/hostcribone";
import HostCribTwo from "./components/hoststeps/hostcribtwo";
import HostCribThree from "./components/hoststeps/hostcribthree";
import HostMenuone from "./components/hoststeps/hostmenuone";
import HostMenutwo from "./components/hoststeps/hostmenutwo";
import HostMenuthree from "./components/hoststeps/hostmenuthree";
import HostMenufour from "./components/hoststeps/hostmenufour";
import HostMenufive from "./components/hoststeps/hostmenufive";
import MenuTempateEdit from "./components/hoststeps/menutempateedit"
import MenutemplateSavechange from "./components/hoststeps/menutemplatesavechange";
import MenuSidebar from "./components/menusidebar";
import HostMarketingone from "./components/hoststeps/hostmarketingone";

import MyOrderA from "./components/dinerOrder/myordera";
import MyAccount01 from "./components/useraccount/myaccount01";
import MyAccount02 from "./components/useraccount/myaccount02";
import MyAccount03 from "./components/useraccount/myaccount03";
import Inbox from "./components/message/inbox";
import Inbox02 from "./components/message/inbox02";

import IdentityStep01 from "./components/dinerverify/identitystep01";
import IdentityStep02 from "./components/dinerverify/identitystep02";
import IdentityStep03 from "./components/dinerverify/identitystep03";
import IdentityStep04 from "./components/dinerverify/identitystep04";
import SignPass from "./components/dinerverify/signpass";
import CartSummery01 from "./components/checkout/cartsummery01";
import CartCheckout01 from "./components/checkout/cartcheckout01";
import CartCheckout02 from "./components/checkout/cartcheckout02";
import HostMarketingtwo from "./components/hoststeps/hostmarketingtwo";
import HostMarketingThree from "./components/hoststeps/hostmarketthree";
import HostAccordian from "./components/hoststeps/hostaccordian";
import HostBusiness from "./components/hoststeps/hostbusiness";
import TooltipRange from "./components/hoststeps/tooltiprange";
import HostReviews from "./components/hoststeps/hostreviews";
import FavouriteDirectory from "./components/newpage/favouritedirectory";
import Favourite from "./components/newpage/favourite";
import MediaOne from "./components/newpage/mediaone";
import MediaTwo from "./components/newpage/mediatwo";
import TicketDashboard from "./components/newpage/ticketdashboard";
import TicketDashboardTwo from "./components/newpage/ticketdashboardtwo";
import OrderHistory from "./components/newpage/orderhistory";
import AllReviews from "./components/newpage/allreviews";
import DinerLoyalty from "./components/newpage/dinerloyalty";
import HostIncentive from "./components/newpage/hostincentive";
import HostPrestige from "./components/newpage/hostprestige";
import HostPrestigeLeaderboard from "./components/newpage/hostprestigeleaderboard";
import HostPrestigeLeaderboard02 from "./components/newpage/hostprestigeleaderboard02";
import HostPrestigeLeaderboard03 from "./components/newpage/hostprestigeleaderboard03";
import HostPrestigeLeaderboard04 from "./components/newpage/hostprestigeleaderboard04";

function App() {
  return (
    <div className="App">
       <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />          
          <Route path="/findmeal_one" exact element={<FindMyMeal />} />
          <Route path="/outletprofile" exact element={<OutletProfile />} />
          <Route path="/quantity" exact element={<Quantity />} />
          <Route path="/loginpage" exact element={<LoginPage />} />
          <Route path="/loginthree" exact element={<LoginThree />} />
          <Route path="/forgotpassone" exact element={<ForgotPassOne />} />
          <Route path="/forgotpasstwo" exact element={<ForgotPassTwo />} />
          <Route path="/forgotpassthree" exact element={<ForgotPassThree />} />
          <Route path="/newpassword" exact element={<NewPassword />} />

          <Route path="/dinersignup01" exact element={<DinerSignupOne />} />
          <Route path="/dinersignup02" exact element={<DinerSignupTwo />} />
          <Route path="/dinersignup03" exact element={<DinerSignupThree />} />
          <Route path="/dinersignup04" exact element={<DinerSignupFour />} />
          <Route path="/dinersignup05" exact element={<DinerSignupFive />} />
          <Route path="/dinersignup06" exact element={<DinerSignupSix />} />
          <Route path="/dinersignup07" exact element={<DinerSignupSeven />} />
          <Route path="/dinersignup08" exact element={<DinerSignupEight />} />
          <Route path="/dinersignup09" exact element={<DinerSignupNine />} />
          <Route path="/dinersignup10" exact element={<DinerSignupTen />} />
          <Route path="/dinersignup11" exact element={<DinerSignupEleven />} />
          
          <Route path="/cartsummery" exact element={<CartSummery />} />
          <Route path="/cartsummery01" exact element={<CartSummery01 />} />          

          <Route path="/cartcheckout" exact element={<CartCheckout />} />
          <Route path="/cartcheckout01" exact element={<CartCheckout01 />} />
          <Route path="/cartcheckout02" exact element={<CartCheckout02 />} />

          <Route path="/reviewandrating" exact element={<ReviewAndRating />} />
          <Route path="/coupon" exact element={<Coupon />} />
          <Route path="/myprofile" exact element={<MyProfile />} />
          <Route path="/trackyourorder" exact element={<TrackYourOrder />} />
          
          <Route path="/career" exact element={<Career />} />
          <Route path="/career02" exact element={<Career02 />} />
          <Route path="/aboutus" exact element={<AboutUs />} />
          <Route path="/becomeahost" exact element={<BecomeAHost />} />
          <Route path="/contactus" exact element={<ContactUs />} />
          <Route path="/error" exact element={<Error />} />
          <Route path="/termsofuse" exact element={<TermsofUse />} />
          <Route path="/privacypolicy" exact element={<PrivacyPolicy />} />
          <Route path="/hostinglanding" exact element={<HostingLanding />} />
          <Route path="/dinerpayment" exact element={<DinerPayment />} />
          <Route path="/membership" exact element={<MemberShip />} />
          <Route path="/transactionhistory" exact element={<TransactionHistory />} />
          <Route path="/notificationalert" exact element={<NotificationAlert />} />
          <Route path="/notificationcenter" exact element={<NotificationCenter />} />
          <Route path="/privacysetting" exact element={<PrivacySetting />} />
          <Route path="/royaltyprogram" exact element={<RoyaltyProgram />} />
          <Route path="/referandearn" exact element={<ReferAndEarn />} />
          <Route path="/userinterface" exact element={<UserInterface />} />
          <Route path="/faq" exact element={<Faq />} />
          <Route path="/faq02" exact element={<Faq02 />} />
          <Route path="/helpcenter" exact element={<HelpCenter />} />
          <Route path="/helpcenter02" exact element={<HelpCenter02 />} />
          <Route path="/helpcenter03" exact element={<HelpCenter03 />} />
          <Route path="/hoststepone" exact element={<HostStepOne />} />
          <Route path="/hostcribone" exact element={<HostCribOne />} />
          <Route path="/hostcribtwo" exact element={<HostCribTwo />} />
          <Route path="/hostcribthree" exact element={<HostCribThree />} />
          <Route path="/hostmenuone" exact element={<HostMenuone />} />
          <Route path="/hostmenutwo" exact element={<HostMenutwo />} />
          <Route path="/hostmenuthree" exact element={<HostMenuthree />} />
          <Route path="/hostmenufour" exact element={<HostMenufour />} />
          <Route path="hostmenufive" exact element={<HostMenufive />} />
          <Route path="menutempateedit" exact element={<MenuTempateEdit />} />
          <Route path="menutemplatesavechange" exact element={<MenutemplateSavechange />} />
          <Route path="menusidebar" exact element={<MenuSidebar />} />
          <Route path="hostmarketingone" exact element={< HostMarketingone/>} />
          <Route path="hostmarketingtwo" exact element={< HostMarketingtwo />} />
          <Route path="hostmarketingthree" exact element={< HostMarketingThree />} />
          <Route path="hostaccordian" exact element={< HostAccordian />} />
          <Route path="hostbusiness" exact element={< HostBusiness />} />
          <Route path="hostreviews" exact element={< HostReviews />} />
          <Route path="tooltiprange" exact element={< TooltipRange />} />          
          
          <Route path="myordera" exact element={<MyOrderA />} />
          <Route path="myaccount01" exact element={<MyAccount01 />} />
          <Route path="myaccount02" exact element={<MyAccount02 />} />
          <Route path="myaccount03" exact element={<MyAccount03 />} />
          <Route path="inbox" exact element={<Inbox />} />
          <Route path="inbox02" exact element={<Inbox02 />} />

          <Route path="identitystep01" exact element={<IdentityStep01 />} />
          <Route path="identitystep02" exact element={<IdentityStep02 />} />
          <Route path="identitystep03" exact element={<IdentityStep03 />} />
          <Route path="identitystep04" exact element={<IdentityStep04 />} />
          <Route path="signpass" exact element={<SignPass />} />

          <Route path="favouritedirectory" exact element={<FavouriteDirectory />} />
          <Route path="favourite" exact element={<Favourite />} />
          <Route path="mediaone" exact element={<MediaOne />} />
          <Route path="mediatwo" exact element={<MediaTwo />} />
          <Route path="ticketdashboard" exact element={<TicketDashboard />} />
          <Route path="ticketdashboardtwo" exact element={<TicketDashboardTwo />} />
          <Route path="orderhistory" exact element={<OrderHistory />} />
          <Route path="allreviews" exact element={<AllReviews />} />
          <Route path="dinerloyalty" exact element={<DinerLoyalty />} />
          <Route path="hostincentive" exact element={<HostIncentive />} />
          <Route path="hostprestige" exact element={<HostPrestige />} />
          <Route path="hostprestigeleaderboard" exact element={<HostPrestigeLeaderboard />} />
          <Route path="hostprestigeleaderboard02" exact element={<HostPrestigeLeaderboard02 />} />
          <Route path="hostprestigeleaderboard03" exact element={<HostPrestigeLeaderboard03 />} />
          <Route path="hostprestigeleaderboard04" exact element={<HostPrestigeLeaderboard04 />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
