import React from 'react'
import { Container, Row, Col, Button, Breadcrumb, Card, Badge, Figure, Modal, Form } from 'react-bootstrap';
import { FiChevronDown, FiCreditCard, } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import { BsFillCircleFill } from 'react-icons/bs';
import { BiEdit } from "react-icons/bi";
import { TiPlus } from "react-icons/ti";
import "./dinerstyle.css"
import Header from "../header";
import Footer from "../footer";
import ProfileMenu from "../profilemenu";
import Slider from "react-slick";

const DinerPayment = () => {    
    const [modalShow, setModalShow] = React.useState(false);    
    const settings = {
        dots: true,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        speed: 500,
        slidesToShow: 1.05,
        slidesToScroll: 1,
        easing: 'cubic',
        
        responsive: [
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                    centerMode: false,
                    dots: false
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1.05,
                    centerPadding: "25px",
                    centerMode: true,
                    dots: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }            
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 1399,
                settings: {
                    centerPadding: "20px",
                }
            }
        ]
    };    
    const CardsPayment = {
        dots: true,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: "140px",
        speed: 500,
        slidesToShow: 1.15,
        slidesToScroll: 1,
        easing: 'cubic',
        
        responsive: [
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                    centerMode: false,
                    dots: false
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1.05,
                    dots: false,
                    centerPadding: "35px",
                    centerMode: true
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1.05,
                    centerPadding: "25px",
                    centerMode: true
                }            
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1.05,
                    centerPadding: "25px",
                    centerMode: true
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 1.05,
                    centerPadding: "35px",
                    centerMode: true
                }
            }
        ]
    };
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <Col as="div" className="text-center">
                            <ProfileMenu />
                        </Col>
                    </Col>
                    <Col lg="9" as="div" className="userAcc paymentarea pt-4">
                        <h2 className="fs52 semibold ms-md-4 mb-0">&nbsp;Payment</h2>
                        <Breadcrumb className="ms-md-4 mb-5 ps-3">
                            <Breadcrumb.Item href="/">Account</Breadcrumb.Item>
                            <Breadcrumb.Item>Payment</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Row className="ms-md-4 m-0">
                            <Col lg="11">
                                <Row className="text-center shadowSM m-0 p-4">
                                    <Card.Header className="border-0 py-0 bg-transparent text-start fs30 semibold GrayDark pb-2">
                                    <svg className="me-3" width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.2353 2H4.76449C3.23743 2 1.99951 3.23793 1.99951 4.76498V23.2357C1.99951 24.7627 3.23743 26.0006 4.76449 26.0006H29.2353C30.7624 26.0006 32.0003 24.7627 32.0003 23.2357V8.00016V4.76498C32.0003 3.23792 30.7624 2 29.2353 2Z" stroke="#C32148" stroke-width="2.5" stroke-linecap="round"/>
                                    <path d="M32.0013 9.49902H26.0011C23.5157 9.49902 21.501 11.5138 21.501 13.9991V13.9991C21.501 16.4845 23.5157 18.4993 26.0011 18.4993H32.0013" stroke="#C32148" stroke-width="2.5" stroke-linecap="square"/>
                                    </svg> Cribbly Wallet
                                    </Card.Header>
                                    <Col className="borderEnd mb-5">
                                        <Card className="border-0 mb-5">
                                            <Card.Body>
                                                <blockquote className="blockquote mb-0">
                                                    <p className="gray82 semibold fs26 mb-0 mt-5 ">Cash Balance</p>
                                                    <p className="GrayComn semibold fs40 mb-5">S$1,800.00</p>
                                                    <p className="gray82 semibold fs18 mb-0 lh-sm">If you wish to withdraw available cash balance, <br className="d-none d-lg-inline"/>please switch to your Host Account.</p>
                                                </blockquote>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col className="mb-5">
                                        <Card className="border-0">
                                            <Card.Body>
                                                <blockquote className="blockquote mb-0">
                                                    <p className="gray82 semibold fs26 mb-0 mt-5 ">Credit Balance</p>
                                                    <p className="GrayComn semibold fs40 mb-2">S$100.00</p>
                                                    <p className="gray82 semibold fs24 mb-4 lh-sm">Expiring: 15 August 2022</p>
                                                    <Button href="#" variant="danger" className="rounded-pill fs18 px-5">Top Up</Button>
                                                </blockquote>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Col className="spaceHV h146">&nbsp;</Col>
                        <Col as="section" className="ms-md-4">
                            <Col as="ul" className="paymentSlider row m-0 list-unstyled">
                                <Col as="li" className="addnew">
                                    <Card role="button" onClick={() => setModalShow(true)} className="lightred border-0 shadowSM h-100 text-center">
                                        <Card.Body className="d-flex align-items-center justify-content-center flex-column">
                                            <TiPlus size="90" color="var(--theme-color)" />
                                            <h3 className="fs18 bold mt-2">Add New Card</h3>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col as="li" className="cardSlider">
                                    {/* slider */}
                                    <Slider className="featureProd" {...settings}>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                        <h2 className="fs28 m-0 semibold d-inline-block"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> Debit / Credit Card </h2>
                                                        <Col className="cardhead float-end">
                                                            <Badge pill bg="false" role="button" className="bgLightGreen ms-2">Preferred</Badge>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">
                                                    <Row className=" m-auto">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost">
                                                                <img src={process.env.PUBLIC_URL+"assets/blackCard.png"} className="img-fluid" alt="paymentmethod" />
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="dotsCircle">
                                                                <h6 className="semibold m-0">Card Number</h6>
                                                                <Figure.Caption>
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />                                                                    
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />
                                                                    <span className="ms-4 text">9214</span>
                                                                </Figure.Caption>
                                                            </Figure>
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="semibold m-0">Expiry Date</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">08/29</span>
                                                                    </Figure.Caption>
                                                                </Col>
                                                                <Col lg="auto">
                                                                    <h6 className="semibold m-0">Card Holder Name</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">John Doe</span>
                                                                    </Figure.Caption>
                                                                </Col>
                                                            </Figure>
                                                            <Figure className="icons m-0">
                                                                <BiEdit role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2 me-3" />
                                                                <HiOutlineTrash role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2" />
                                                            </Figure>
                                                        </Col>
                                                    </Row>                                            
                                                </Col>
                                            </Col>
                                        </div>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                    <h2 className="fs28 m-0 semibold d-inline-block"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> Debit / Credit Card </h2>
                                                        <Col className="cardhead float-end">
                                                            <Badge pill bg="false" role="button" className="bgLightGreen ms-2">Preferred</Badge>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">                                                    
                                                    <Row className=" m-auto">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost">
                                                                <img src={process.env.PUBLIC_URL+"assets/blackCard.png"} className="img-fluid" alt="paymentmethod" />
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="dotsCircle">
                                                                <h6 className="semibold m-0">Card Number</h6>
                                                                <Figure.Caption>
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />                                                                    
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />
                                                                    <span className="ms-4 text">9214</span>
                                                                </Figure.Caption>
                                                            </Figure>
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="semibold m-0">Expiry Date</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">08/29</span>
                                                                    </Figure.Caption>
                                                                </Col>
                                                                <Col lg="auto">
                                                                    <h6 className="semibold m-0">Card Holder Name</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">John Doe</span>
                                                                    </Figure.Caption>
                                                                </Col>
                                                            </Figure>
                                                            <Figure className="icons m-0">
                                                                <BiEdit role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2 me-3" />
                                                                <HiOutlineTrash role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2" />
                                                            </Figure>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </div>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                    <h2 className="fs28 m-0 semibold d-inline-block"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> Debit / Credit Card </h2>
                                                        <Col className="cardhead float-end">
                                                            <Badge pill bg="false" role="button" className="bgLightGreen ms-2">Preferred</Badge>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">                                                    
                                                    <Row className=" m-auto">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost">
                                                                <img src={process.env.PUBLIC_URL+"assets/blackCard.png"} className="img-fluid" alt="paymentmethod" />                                                                
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="dotsCircle">
                                                                <h6 className="semibold m-0">Card Number</h6>
                                                                <Figure.Caption>
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />
                                                                    
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />

                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />

                                                                    <span className="ms-4 text">9214</span>
                                                                </Figure.Caption>
                                                            </Figure>
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="semibold m-0">Expiry Date</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">08/29</span>
                                                                    </Figure.Caption>
                                                                </Col>
                                                                <Col lg="auto">
                                                                    <h6 className="semibold m-0">Card Holder Name</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">John Doe</span>
                                                                    </Figure.Caption>
                                                                </Col>                                                                
                                                            </Figure>
                                                            <Figure className="icons m-0">
                                                                <BiEdit role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2 me-3" />
                                                                <HiOutlineTrash role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2" />
                                                            </Figure>
                                                        </Col>
                                                    </Row>                                            
                                                </Col>
                                            </Col>
                                        </div>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                    <h2 className="fs28 m-0 semibold d-inline-block"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> Debit / Credit Card </h2>
                                                        <Col className="cardhead float-end">
                                                            <Badge pill bg="false" role="button" className="bgLightGreen ms-2">Preferred</Badge>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">                                                    
                                                    <Row className=" m-auto">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost">
                                                                <img src={process.env.PUBLIC_URL+"assets/blackCard.png"} className="img-fluid" alt="paymentmethod" />                                                                
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="dotsCircle">
                                                                <h6 className="semibold m-0">Card Number</h6>
                                                                <Figure.Caption>
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />
                                                                    
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />

                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                    <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />

                                                                    <span className="ms-4 text">9214</span>
                                                                </Figure.Caption>
                                                            </Figure>
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="semibold m-0">Expiry Date</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">08/29</span>
                                                                    </Figure.Caption>
                                                                </Col>
                                                                <Col lg="auto">
                                                                    <h6 className="semibold m-0">Card Holder Name</h6>
                                                                    <Figure.Caption>
                                                                        <span className="text letter-spacing-normal">John Doe</span>
                                                                    </Figure.Caption>
                                                                </Col>                                                                
                                                            </Figure>
                                                            <Figure className="icons m-0">
                                                                <BiEdit role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2 me-3" />
                                                                <HiOutlineTrash role="button" size="36" color="var(--theme-color)" className="bgLightGray shadowSM border100 p-2" />
                                                            </Figure>
                                                        </Col>
                                                    </Row>                                            
                                                </Col>
                                            </Col>
                                        </div>
                                    </Slider>
                                </Col>                                
                            </Col>
                        </Col>                        
                        <Col className="spaceHV">&nbsp;</Col>
                        <Col as="section" className="ms-md-4">
                            <Col as="ul" className="paymentSlider cardpay row m-0 list-unstyled">
                                <Col as="li" className="cardSlider">
                                    {/* slider */}
                                    <Slider className="featureProd" {...CardsPayment}>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                        <h2 className="fs28 m-0 semibold"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> PayPal </h2>                                                        
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">
                                                    <Row className=" m-auto align-items-center">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost text-center">
                                                                <img src={process.env.PUBLIC_URL+"assets/paypal_Icon.png"} className="img-fluid" alt="paymentmethod" />
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="m-0 fs20 semibold">Email</h6>
                                                                    <Figure.Caption>
                                                                        <span className="fs20 semibold text letter-spacing-normal">johndoe5847@gmail.com</span>
                                                                    </Figure.Caption>
                                                                    <Button variant="danger" className="border100 regular fs16 py-1 px-4 mt-5">Make Primary</Button>
                                                                </Col>
                                                            </Figure>
                                                        </Col>
                                                    </Row>                                            
                                                </Col>
                                            </Col>
                                        </div>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                        <h2 className="fs28 m-0 semibold"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> ApplePay </h2>                                                        
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">                                                    
                                                    <Row className=" m-auto align-items-center">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost text-center">
                                                                <img src={process.env.PUBLIC_URL+"assets/apple_Icon.png"} className="img-fluid" alt="paymentmethod" />                                                                
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="m-0 fs20 semibold">Email</h6>
                                                                    <Figure.Caption>
                                                                        <span className="fs20 semibold text letter-spacing-normal">johndoe5847@gmail.com</span>
                                                                    </Figure.Caption>
                                                                    <Button variant="danger" className="border100 regular fs16 py-1 px-4 mt-5">Make Primary</Button>
                                                                </Col>                                                                                                                                
                                                            </Figure>                                                            
                                                        </Col>
                                                    </Row>                                            
                                                </Col>
                                            </Col>
                                        </div>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                        <h2 className="fs28 m-0 semibold"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> Google Pay</h2>
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">                                                    
                                                    <Row className=" m-auto align-items-center">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost text-center">
                                                                <img src={process.env.PUBLIC_URL+"assets/googlepay_Icon.png"} className="img-fluid" alt="paymentmethod" />
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="m-0 fs20 semibold">Email</h6>
                                                                    <Figure.Caption>
                                                                        <span className="fs20 semibold text letter-spacing-normal">johndoe5847@gmail.com</span>
                                                                    </Figure.Caption>
                                                                    <Button variant="danger" className="border100 regular fs16 py-1 px-4 mt-5">Make Primary</Button>
                                                                </Col>                                                                                                                                
                                                            </Figure>                                                            
                                                        </Col>
                                                    </Row>                                            
                                                </Col>
                                            </Col>
                                        </div>
                                        <div className="card border-0">
                                            <Col as="section" className="cartBox zindex topPart">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                        <h2 className="fs28 m-0 semibold"><FiCreditCard className="align-top me-2" size="33" color="var(--theme-color)" /> ApplePay </h2>                                                        
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">                                                    
                                                    <Row className=" m-auto align-items-center">
                                                        <Col className="listCard" md="6">
                                                            <Col as="div" className="cardPost text-center">
                                                                <img src={process.env.PUBLIC_URL+"assets/apple_Icon.png"} className="img-fluid" alt="paymentmethod" />                                                                
                                                            </Col>
                                                        </Col>
                                                        <Col className="cardNumber ms-md-5 pe-md-0">
                                                            <Figure className="d-flex mt-3 mb-4">
                                                                <Col xs="7">
                                                                    <h6 className="m-0 fs20 semibold">Email</h6>
                                                                    <Figure.Caption>
                                                                        <span className="fs20 semibold text letter-spacing-normal">johndoe5847@gmail.com</span>
                                                                    </Figure.Caption>
                                                                    <Button variant="danger" className="border100 regular fs16 py-1 px-4 mt-5">Make Primary</Button>
                                                                </Col>                                                                                                                                
                                                            </Figure>                                                            
                                                        </Col>
                                                    </Row>                                            
                                                </Col>
                                            </Col>
                                        </div>
                                    </Slider>
                                </Col>                                
                            </Col>
                        </Col>
                        <Col lg="12" className="p-4">&nbsp;</Col>                        
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
            
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="addCardModal"
                contentClassName="addContentModal border12 border-0"
                backdropClassName="opacityXtra"
                show={modalShow}
                onHide={() => setModalShow(false)}>
                <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white"/>
                </svg>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Col as="div" className="cardCircle bg-white p-3 border100 m-auto">
                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M60.501 15.125V50.875C60.501 51.975 60.0885 52.9375 59.2635 53.7625C58.4385 54.5875 57.476 55 56.376 55H9.62598C8.52598 55 7.56348 54.5875 6.73848 53.7625C5.91348 52.9375 5.50098 51.975 5.50098 50.875V15.125C5.50098 14.025 5.91348 13.0625 6.73848 12.2375C7.56348 11.4125 8.52598 11 9.62598 11H56.376C57.476 11 58.4385 11.4125 59.2635 12.2375C60.0885 13.0625 60.501 14.025 60.501 15.125ZM9.62598 22.6188H56.376V15.125H9.62598V22.6188ZM9.62598 31.4875V50.875H56.376V31.4875H9.62598ZM9.62598 50.875V15.125V50.875Z" fill="#C32148"/>
                        </svg>
                    </Col>
                    <h3 className="fs32 text-white regular mt-4">Add New Card</h3>
                    <p className="fs24 text-white lh-sm regular">Please input your card details you <br className='d-none d-lg-inline' />wish to save to file.</p>
                    <Col as="div" className="addCardCcontainer my-3 my-md-5 position-relative d-inline-block">
                        <img src={process.env.PUBLIC_URL+"assets/addCard.png"} className="img-fluid" alt="paymentmethod" />
                        <Row className="position-absolute posDirection">
                            <Col as="span">1</Col>
                            <Col as="span">2</Col>
                            <Col as="span">3</Col>
                            <Col as="span">4</Col>                            
                            <Col as="span" className="w40">&nbsp;</Col>                            
                            <Col as="span">1</Col>
                            <Col as="span">2</Col>
                            <Col as="span">3</Col>
                            <Col as="span">4</Col>
                            <Col as="span" className="w40">&nbsp;</Col>                            
                            <Col as="span">1</Col>
                            <Col as="span">2</Col>
                            <Col as="span">3</Col>
                            <Col as="span">4</Col>
                            <Col as="span" className="w40">&nbsp;</Col>                            
                            <Col as="span">1</Col>
                            <Col as="span">2</Col>
                            <Col as="span">3</Col>
                            <Col as="span">4</Col>
                        </Row>                        
                    </Col>
                    <Form className="text-start mx-2 mx-md-3 px-md-3">
                        <Form.Label className="text-white">Card Number</Form.Label>
                        <Form.Group className="pb-5" controlId="formBasicEmail">
                            <Row className="mb-4">
                                <Col><Form.Control type="text" placeholder="" /></Col>
                                <Col><Form.Control type="text" placeholder="" /></Col>
                                <Col><Form.Control type="text" placeholder="" /></Col>
                                <Col><Form.Control type="text" placeholder="" /></Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <Form.Label className="text-white">Expiry Date</Form.Label>
                                    <Form.Control type="date" placeholder="MM/YY" />
                                </Col>
                                <Col>
                                    <Form.Label className="text-white">CVV</Form.Label>
                                    <Form.Control type="text" placeholder="" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="text-white">Card Holder Name</Form.Label>
                                    <Form.Control type="text" placeholder="" />
                                </Col>                                
                            </Row>
                        </Form.Group>
                        <Button variant="light" type="submit" className="fs26 themeColor rounded-pill w-50 m-auto d-block my-3 my-lg-5 py-3 lh-1">Save Card</Button>
                    </Form>
                </Modal.Body>            
            </Modal>



        </div>
    )    
}
export default DinerPayment