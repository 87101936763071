import React from 'react'
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { BsClock } from 'react-icons/bs';
import "react-pro-sidebar/dist/css/styles.css";
import "./careers.css";
import Header from "../header";
import Footer from "../footer";
import { FiPhone } from 'react-icons/fi';


const ContactUs = () => {    

    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>                
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>                
            </Container>
            <br /><br />
            <Col as="div">
                <Col md={11} className="m-auto">
                    <Row className="mx-md-4 hypart m-0">
                        <Col lg="12">
                            <h1 className="mb-3 fs52 GrayComn">Contact Us</h1>
                            <p className="semibold GrayComn">We are here to help</p>
                        </Col>
                        <Col xxl="6" className="pt-md-4 mt-md-5 pe-md-5 contactus">
                            <h1 className="mb-md-5 fs52 GrayComn">How's it cooking today?</h1>
                            <Form>
                                <Form.Group as={Row} controlId="exampleForm.ControlInput1">                                    
                                    <Col lg="12" className="mb-4">
                                        <Form.Control size="lg" type="text" className="bgF8 shadow border-0 border08" placeholder="Name*" />
                                    </Col>
                                    <Col lg="12" className="mb-4">
                                        <Form.Control size="lg" type="text" className="bgF8 border-0 border08" placeholder="Company Name" />
                                    </Col>
                                    <Col lg="12" className="mb-4">
                                        <Form.Control size="lg" type="text" className="bgF8 border-0 border08" placeholder="Phone Number" />
                                    </Col>
                                    <Col lg="12" className="mb-4">
                                        <Form.Control size="lg" type="email" className="bgF8 border-0 border08" placeholder="Email*" />
                                    </Col>
                                    <Col lg="12" className="mb-4">
                                        <Form.Control as="textarea" className="bgF8 shadowMD border-0 border08" placeholder="Your Message*" rows={9} />
                                    </Col>
                                    <Col lg="12" className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flex04" />
                                            <label className="form-check-label ps-1 lh-base semibold fs18 GrayDark" for="flex04">
                                            <span className="themeColor">*</span>I Agree to <span className="themeColor">Terms</span> and <span className="themeColor">Privacy Policy</span>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg="12" className="d-flex justify-content-between align-items-end mt-5 capchaBox">
                                        <img src={process.env.PUBLIC_URL+"assets/capcha.png"} className="figure-img img-fluid mb-3 mb-lg-0 m-auto m-lg-0 w-50" alt="capcha" />
                                        <Button variant="danger fs26 border100 px-5 py-2 m-auto m-lg-0 d-block mb-5 mb-lg-0"><span className="regular">Send Message</span></Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xxl="6" className="rightImgSec mt-md-5 pt-md-4">
                            <figure className="figure carFig position-relative">
                                <img src={process.env.PUBLIC_URL+"assets/contactusImg.png"} className="border08 figure-img img-fluid" alt="careerpicture" />
                                <figcaption className="figure-caption text-center text-white">
                                    <h2 className="fs52vw semibold mt-lg-5">Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat sunt nostrud amet veniam consequat sunt nostrud amet. </h2>
                                    <Col as="p" className="pt-5 mt-5 mb-0">
                                        <Col as="span" className="cafeImage border border-2 border-danger rounded-pill p-1 bg-white d-inline-block">
                                            <img src={process.env.PUBLIC_URL+"assets/black_scoop.png"} 
                                            width="92" 
                                            height="92" 
                                            className="img-fluid border border-2 border-danger rounded-pill p-0"
                                            alt="cafepic" />
                                        </Col>
                                        <Col as="strong" className="d-block semibold fs26 mt-1">Annette Black</Col>
                                        <Col as="span" className="d-block fs20 regular">Mollit non deserunt ullamco est sit aliqua</Col>
                                    </Col>
                                </figcaption>
                            </figure>
                        </Col>
                        <Col lg="12" className="pt-md-4 mt-md-5 mb-md-5 px-md-5 contactus text-center">
                            <h1 className="pt-md-4 mt-md-5 mb-3 fs52 GrayComn">Our Crib Info.</h1>
                            <p className="fs20 semibold gray82">For corporate matters.</p>
                            <Row className="mt-md-5">
                                <Col lg="4" md="12" className="mb-sm-5">
                                    <Card className="border-0 cribInfo">
                                        <Col className="imgIcon m-auto position-relative mt-4 mt-lg-0">
                                            <Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/redcircle.png"} className="figure-img img-fluid m-auto d-block" />
                                            <HiOutlineOfficeBuilding size="45" color="var(--bs-white)" />
                                        </Col>
                                        <Card.Body className="mt-0 mt-lg-3">
                                            <Card.Title className="fs32 semibold GrayComn">Company Address</Card.Title>
                                            <Card.Text className="fs24 semibold gray82 mb-2 mb-lg-5">111 North Bridge Road #24-02, Peninsula Plaza,</Card.Text>
                                            <Card.Text className="fs24 semibold gray82">Singapore 179098 <br />Co. Reg. No.: 202222733W</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" md="12">
                                    <Card className="border-0 cribInfo">
                                        <Col className="imgIcon m-auto position-relative">
                                            <Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/redcircle.png"} className="figure-img img-fluid m-auto d-block" />
                                            <FiPhone size="45" color="var(--bs-white)" />                                            
                                        </Col>
                                        <Card.Body className="mt-0 mt-lg-3">
                                            <Card.Title className="fs32 semibold GrayComn">Contact Details</Card.Title>
                                            <Card.Text className="fs24 semibold gray82  mb-2 mb-lg-5">General Hotline: <br />+65 9622 3476</Card.Text>
                                            <Card.Text className="fs24 semibold gray82">General Inquiry:<br /> info@cribbly.com</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" md="12">
                                    <Card className="border-0 cribInfo">
                                        <Col className="imgIcon m-auto position-relative mt-4 mt-lg-0">
                                            <Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/redcircle.png"} className="figure-img img-fluid m-auto d-block" />
                                            <BsClock size="45" color="var(--bs-white)" />                                            
                                        </Col>
                                        <Card.Body className="mt-0 mt-lg-3">
                                            <Card.Title className="fs32 semibold GrayComn">Operating Hours</Card.Title>
                                            <Card.Text className="fs24 semibold gray82  mb-2 mb-lg-5">Monday - Friday<br />9.30AM - 6.30PM</Card.Text>
                                            <Card.Text className="fs24 semibold gray82">Off on Saturday, Sundays and<br />Public Holidays.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Col>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default ContactUs