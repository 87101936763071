import React from 'react'
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {Link} from "react-router-dom";
const Footer = () => {
    return (
        <Container fluid className="footerwrapper">
            <Row>
                <Col as="div" className="ftrBG">
                    <Container>
                        <Col as="footer" className="row pt-5 border-top">
                            <Col md={4} className="logoleft">
                                <Link to="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
                                    <img src={process.env.PUBLIC_URL+"assets/logo-footer.svg"} className="img-fluid" alt="footerlogo" />
                                </Link>
                                <Col as="div" className="followUs mt-5">
                                    <h4 className="fw-bold">Follow Us</h4>
                                    <Col as="div" className="d-flex mt-4">
                                        <img role="button" src={process.env.PUBLIC_URL+"assets/twitterIcon.svg"} className="img-fluid twitter" alt="Twitter" />
                                        <img role="button" src={process.env.PUBLIC_URL+"assets/facebookIcon.svg"} className="img-fluid facebook" alt="Facebook" />
                                        <img role="button" src={process.env.PUBLIC_URL+"assets/instaIcon.svg"} className="img-fluid insta" alt="Instagram" />
                                    </Col>
                                </Col>
                            </Col>
                            <Col className="col01">
                                <h5>Home</h5>
                                <ListGroup as="ul" className="nav flex-column">
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Download App</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Contact</Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col className="col03">
                                <h5>Company</h5>
                                <ListGroup as="ul" className="nav flex-column">
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">About</Link>                                           
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Media</Link>                                           
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Career</Link>                                           
                                    </ListGroup.Item>                                        
                                </ListGroup>
                            </Col>
                            <Col className="col02">
                                <h5>Diner</h5>
                                <ListGroup as="ul" className="nav flex-column">
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Sign Up</Link>                                           
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Loyalty</Link>                                           
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Faq</Link>                                           
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col className="col04">
                                <h5>Host</h5>
                                <ListGroup as="ul" className="nav flex-column">
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Sign Up</Link>                                           
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Become A Host</Link>                                           
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Incentive</Link>                                           
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-0 border-0">
                                        <MdKeyboardArrowRight color="var(--bs-border)" size="20" className="align-text-bottom" /><Link to="/" className="ftrLink">Prestige</Link>                                           
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>                            
                        </Col>
                    </Container>
                    <p className="copyRight text-center mb-0 py-4">All Right Reserved. Copyright by Cribbly Pvt Ltd</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer;