import React, { useState } from 'react'
import { Container, Row, Col, Image, Card, Form, Button, Modal } from 'react-bootstrap';
import "./account.css"
import Header from "../header";
import { BsCircleFill, BsClock } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import SwipeButton from "react-swipezor";
import { FiChevronRight } from 'react-icons/fi';

const MyAccount03 = () => {
    
    const [show, setShow] = useState(false);
    const [show01, setShow01] = useState(false);
    const [newClass, setNewClass] = useState(false);
    const handleClose = () => setShow(false);
    const handleClose01 = () => setShow01(false);


    return (
        <div id="bodyWrap" className="">
        <Container className="mealOne" fluid>
            <Row>
                <Col className="px-0">
                    <Header />
                </Col>
            </Row> 
            <Row className="ps-0 pinkgradiant pb-3">
                <Col lg="12" className="text-center mb-5"><Image src={process.env.PUBLIC_URL+"assets/sadIcon.png"} alt="mainbanner" className="img-fluid" /></Col>
                <Col lg="12" className="text-center">
                    <h2 className="fs60 text-white semibold mb-5">We’re Sad To See You Go!</h2>
                    <Card style={{maxWidth: '995px', paddingLeft: '2.5rem', paddingRight: '2.5rem'}} className="d-inline-block py-3 border08 w-100" body>
                    <Card.Text className="fs52 GrayComn semibold text-start m-0">Please tell us why you are leaving.</Card.Text>
                    <Card.Text className="GrayComn semibold text-start lh-1">Please key in the OTP sent to your mobile +65 <BsCircleFill size="9" color="var(--bs-gray55)" className="me-1" /> 
                        <BsCircleFill size="9" color="var(--bs-gray55)" className="me-1" /> 
                        <BsCircleFill size="9" color="var(--bs-gray55)" className="me-1" /> 
                        <BsCircleFill size="9" color="var(--bs-gray55)" /> 4567.</Card.Text>
                        <Form className="mt-5">
                            <Form.Group controlId="formBasicName" className="mb-0 row otpScreem">
                                <Col xs={4} md={2}><input className="otpFsize mb-4" type="text" id="input1" placeholder="." /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-4" type="text" id="input1" placeholder="." /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-4" type="text" id="input1" placeholder="." /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-4" type="text" id="input1" placeholder="." /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-4" type="text" id="input1" placeholder="." /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-4" type="text" id="input1" placeholder="." /></Col>
                            </Form.Group>
                            <Form.Group className="pb-5" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className="float-start fs20 text-black semibold">
                                    <Link to="" className="forgotPass text-decoration-none pe-1">Resend</Link> 
                                    or 
                                    <Link to="" className="forgotPass text-decoration-none ps-1">Change Number</Link>
                                </Form.Label>
                                <Form.Label className="float-end fs20"><BsClock size="20" className="align-text-top" /> <b>00.25</b></Form.Label>
                            </Form.Group>
                            <Form.Group className="mt-5 mb-2"> 
                                <Button onClick={setShow} className="w-50 py-2 lh-lg border-0 rounded-pill" variant="primary">Suspend</Button>
                            </Form.Group>
                        </Form>
                    </Card>
                </Col>
                <Col lg="8" className="text-center py-5 mt-3 text-center m-auto">
                    <Col as="div" className="icons pb-2">
                        <svg width="106" height="105" viewBox="0 0 106 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M96 59.809C96 53.222 91.8355 47.7177 86.0419 45.552V42.9355C86.0419 24.7984 71.1956 10 53 10C34.8043 10 19.9581 24.7984 19.9581 42.9355V45.552C14.1645 47.7177 10 53.3121 10 59.809C10 68.2004 16.8798 75.0587 25.299 75.0587H30.8211C32.2696 75.0587 33.446 73.8855 33.446 72.4422V47.1767C33.446 45.7328 32.2689 44.5602 30.8211 44.5602H28.2863V42.9363C28.2863 29.3111 39.4208 18.3024 52.9998 18.3024C66.5788 18.3024 77.7133 29.401 77.7133 42.9363V44.5602H75.1786C73.7301 44.5602 72.5537 45.7334 72.5537 47.1767V72.4422C72.5537 73.8861 73.7307 75.0587 75.1786 75.0587H76.3556C73.7307 81.1044 67.9371 85.3454 61.0566 85.8867C60.6948 84.1721 59.1553 82.8189 57.2546 82.8189H48.6548C46.482 82.8189 44.7625 84.5335 44.7625 86.6986V91.1203C44.7625 93.286 46.4827 95 48.6548 95H57.2546C59.1559 95 60.7851 93.6468 61.0566 91.7516C71.2862 91.2103 79.7954 84.2621 82.6923 74.8778C90.2059 73.8858 95.9995 67.4795 95.9995 59.8093L96 59.809Z" fill="#C32148"/>
                        </svg>
                    </Col>
                    <Col as="p" className="parraText fs30 semibold text-white lh-sm pb-3">
                        We are here for you. <span className="themeColor">Contact</span> our Support Team and<br className="d-none d-lg-inline" /> let us know how we can help you.
                    </Col>
                </Col>
            </Row>
        </Container>
        <Modal 
            className="order-response" 
            backdropClassName="action1"
            dialogClassName="action2 successfullyPop"
            contentClassName="action3 bgtheme border-0 border12"
            show={show} 
            centered
            onHide={handleClose}>
            <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
            <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white"/>
            </svg>
            </Modal.Header>
            <Modal.Body className="modal-input text-center m-0">
                <Col className="container">
                    <Row className="justify-content-center">
                        <Col md="11" className="modal-content-inner">
                            <Col as="span" className="rounded-circle d-block pb-2">
                            <svg width="94" height="86" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-2 mb-4">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.3686 6.90288C41.0327 -1.38885 52.9712 -1.38885 57.6352 6.90288L91.6751 67.4184C96.2495 75.5509 90.3727 85.5995 81.0415 85.5995H12.9619C3.63103 85.5995 -2.24589 75.5509 2.32868 67.4184L36.3686 6.90288ZM53.1011 67.3001C53.1011 70.6691 50.3701 73.4001 47.0011 73.4001C43.6323 73.4001 40.9012 70.6691 40.9012 67.3001C40.9012 63.931 43.6323 61.2001 47.0011 61.2001C50.3701 61.2001 53.1011 63.931 53.1011 67.3001ZM47.0011 18.5001C43.6323 18.5001 40.9012 21.2312 40.9012 24.6001V42.9001C40.9012 46.269 43.6323 49.0001 47.0011 49.0001C50.3701 49.0001 53.1011 46.269 53.1011 42.9001V24.6001C53.1011 21.2312 50.3701 18.5001 47.0011 18.5001Z" fill="white"/>
                            </svg>                            
                            </Col>
                            <Col as="p" className="colorwhite semibol fs28 lh-sm mb-0">
                                <h3 class="p-0 mb-1 fs30 semibold colorwhite col">Warning</h3>
                                You are about to permanently close and delete your account. If you intend to close it temporarily, consider suspending your account instead. By clicking 'Proceed', 
                                your account will be permanently deleted and this action is irreversible.
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>
            <Modal.Footer className="border-0 mt-4 mb-5 p-0 d-block">
                <Col md="auto" className={`text-center m-0 px-5 ${newClass ? "doneClass" : ""}`} >
                    <SwipeButton
                        mainText="Slide to Confirm"
                        overlayText="Confirm"
                        classList="myClass1 border-0 rounded-pill fs26 semibold gray82"
                        caretClassList="my-caret-class myClass2 p-0"
                        overlayClassList="my-overlay-class myClass3"
                        caret={<FiChevronRight size="30" className='themeColor' />}
                        onSwipeDone={function () {
                            setNewClass(true)
                            setShow01(true)

                            setTimeout(()=>{
                                setShow(false)
                                setShow01(false)
                            },80000)
                        }}
                    />
                </Col>
            </Modal.Footer>
        </Modal>
        <Modal 
            className="order-response planModal" 
            backdropClassName="action1 smsFill"
            dialogClassName="action2 successfullyPop"
            contentClassName="action3 bgtheme border-0 border12"            
            show={show01} 
            centered
            onHide={handleClose01}>
            <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
            <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white"/>
            </svg>
            </Modal.Header>
            <Modal.Body className='modal-input text-center m-0'>
                <Col className="container">
                    <Row className="justify-content-center">
                        <Col md="11" className="modal-content-inner">
                            <Col as="span" className="rounded-circle d-block pb-5">
                            <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M48.9992 97.8002C75.9509 97.8002 97.7992 75.9518 97.7992 49.0002C97.7992 22.0487 75.9509 0.200195 48.9992 0.200195C22.0477 0.200195 0.199219 22.0487 0.199219 49.0002C0.199219 75.9518 22.0477 97.8002 48.9992 97.8002ZM71.6125 41.1136C73.9946 38.7313 73.9946 34.8691 71.6125 32.4868C69.2305 30.1047 65.368 30.1047 62.9859 32.4868L42.8992 52.5736L35.0126 44.6868C32.6304 42.3047 28.7681 42.3047 26.3858 44.6868C24.0037 47.0691 24.0037 50.9315 26.3858 53.3135L38.5858 65.5135C40.9681 67.8956 44.8304 67.8956 47.2126 65.5135L71.6125 41.1136Z" fill="white"/>
                            </svg>
                            </Col>
                            <Col as="p" className="colorwhite semibol fs24 lh-sm mb-0">Your account has been successfully closed and deleted.
                                <span className="acc02"><br /><br /><br /></span>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>
        </Modal>
        </div>
        )    
    }
    export default MyAccount03