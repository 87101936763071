import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { HiPlus, HiMinus } from "react-icons/hi";

const HostAccordian = () => {
  const [open, setOpen] = useState(false);
  const [open01, setOpen01] = useState(false);


  return (
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Button className="fs20 px-0 semibold GrayComn" onClick={() => setOpen(!open)}>
              Lorem ipsum dolor sit amet, condipiscing elitadipiscing elit. 
              <span>{open ? (<HiMinus className="themeColor" size="35"/>) : (<HiPlus className="themeColor" size="35" />)}</span>
          </Accordion.Button>
          <Accordion.Body className="px-0 fs16 semibold GrayComn">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Button className="fs20 px-0 semibold GrayComn" onClick={() => setOpen01(!open01)}>
            Consectetur adipiscconsectr adipiscing ering elit. m diam ac.
            <span>{open01 ? (<HiMinus className="themeColor" size="35"/>) : (<HiPlus className="themeColor" size="35"/>)}</span>
          </Accordion.Button>
          <Accordion.Body className="px-0 fs16 semibold GrayComn">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
    </Accordion>
  );
};
export default HostAccordian;