import React ,{ useState} from 'react'
import { Container, Row, Col, Button, Form, FloatingLabel } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./hoststep.css";
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';
import { Link } from 'react-router-dom';

const MenuTempateEdit = () => {
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };
    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9">
                        <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-2 px-xxl-5 px-lg-2 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <Col md="auto" className='d-flex text-center flex-column flex-md-row text-md-start col-md-auto'>
                                        <span className="user-profile-circle me-md-3 mx-auto rounded-pill border border-2 border-danger p-1 d-inline-block"><img src="./assets/black_scoop.png" className='rounded-pill border-2' alt="food" /></span>
                                        <Col className="itemDetails">
                                            <Col as="div" className="itemHead mb-2">
                                                <h4 className="d-inline-block align-middle GrayDark fs46 semibold">City Home Cafe</h4>
                                            </Col>
                                            <Col as="p" className="d-inline-flex border04 grayf7f py-2 px-3 shadowMD align-items-center semibold align-middle">
                                                <Col className='border-end pe-3 me-3'>
                                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.0013 26.0947L15.8379 14.4258H24.1647L20.0013 26.0947Z" fill="#6992F2"/>
                                                    <path d="M0 14.3711L19.3602 31.4961L13.5297 14.4017L0 14.3711Z" fill="#3169ED"/>
                                                    <path d="M12.8381 12.0727L0.0273438 12.0743L6.42691 0.878906L12.8381 12.0727Z" fill="#4377EF"/>
                                                    <path d="M39.9998 14.3867L20.6396 31.5117L26.4701 14.4173L39.9998 14.3867Z" fill="#7CA0F4"/>
                                                    <path d="M27.1631 12.0845L39.9738 12.086L33.5859 0.890625L27.1631 12.0845Z" fill="#A1BBF7"/>
                                                    <path d="M24.2077 11.9639L15.8809 11.9649L20.0559 4.72656L24.2077 11.9639Z" fill="#8EADF6"/>
                                                    <path d="M19.5222 0.00155315L8.61328 0L14.0628 9.53337L19.5222 0.00155315Z" fill="#5684F0"/>
                                                    <path d="M20.5225 0.00155315L31.4314 0L25.9918 9.53337L20.5225 0.00155315Z" fill="#7CA0F4"/>
                                                    </svg>
                                                </Col>    
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6758 0.928603C12.0926 -0.309534 13.9074 -0.309534 14.3242 0.928603L16.4386 7.20961C16.6249 7.76332 17.1596 8.13821 17.7627 8.13821H24.605C25.9538 8.13821 26.5146 9.80412 25.4234 10.5693L19.8878 14.4512C19.3998 14.7934 19.1957 15.4 19.3821 15.9537L21.4965 22.2348C21.9132 23.4729 20.445 24.5025 19.3539 23.7372L13.8184 19.8554C13.3304 19.5131 12.6696 19.5131 12.1816 19.8554L6.64609 23.7372C5.55492 24.5025 4.08674 23.4729 4.50353 22.2348L6.61791 15.9537C6.8043 15.4 6.6001 14.7934 6.11212 14.4512L0.57661 10.5693C-0.514582 9.80412 0.0462183 8.13821 1.39499 8.13821H8.23727C8.84045 8.13821 9.37505 7.76332 9.56144 7.20961L11.6758 0.928603Z" fill="#EF9309"/>
                                                    </svg>
                                                <Col as="span" className='col-auto px-2 lh-1 align-middle fs26 GrayComn semibold'>
                                                    4.5
                                                </Col>
                                                <Link to="/" className='d-inline-block themeColor ps-3 semibold fs20 text-decoration-none'>See Reviews (55)</Link>
                                            </Col>
                                        </Col>
                                    </Col>
                                    <Col md="auto" className='text-center pt-0 pt-md-4 mt-2'>
                                        <Button variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 ">
                                        <svg className='align-text-bottom me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.4142 1.58577L22.4142 1.58582C23.1953 2.36684 23.1953 3.63313 22.4142 4.41418L22.4142 4.41421L11.3284 15.5H8.5V12.6716L19.5858 1.58579L19.5858 1.58577C20.3668 0.804745 21.6332 0.804745 22.4142 1.58577Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3 5C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18C18.5523 22 19 21.5523 19 21V15C19 14.7238 19.2238 14.5 19.5 14.5C19.7762 14.5 20 14.7238 20 15V21C20 22.1046 19.1046 23 18 23H3C1.89542 23 1 22.1046 1 21V6C1 4.89543 1.89543 4 3 4H9C9.27614 4 9.5 4.22386 9.5 4.5C9.5 4.77614 9.27614 5 9 5H3Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        Edit Template
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col as="section" className='ms-0 ms-xl-0 ms-xxl-5 ps-xl-0 ps-xxl-5 mb-5 ps-0'>
                            <Col className='container px-xl-2 px-xxl-5 px-lg-4 px-0'>
                                <Form className="menuform px-lg-2 px-xl-0">
                                    <Col as="section">
                                        <Col>
                                            <Col md="auto" className="tobbleButton bundle-switch semibold d-inline-block mt-2 mb-4 pb-2">
                                                <div className={`app ${isActive ? "left" : "right"}`}>
                                                    <span className="leftButton px-2" onClick={handleToggle}>Mandatory</span>
                                                    <span className="rightButton" onClick={handleToggle}>Optional</span>
                                                </div>
                                            </Col>
                                            <Row>
                                                <Col md="5">
                                                    <FloatingLabel label="Name of Selection Category" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" className="form-control bgLightGray p-3 border-0" id="floatingInput1" placeholder="Chiles en Nogada"/>
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>4 / 20 Characters</Col>
                                                    </FloatingLabel>
                                                    <Col className="custom-radio col-md-12 mb-4 mb-md-0">
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="edittemplate" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" for="edittemplate">Allow Diner to select up to 1 choice.</label>
                                                        </Col>
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="edittemplate01" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" for="edittemplate01">Allow Diner to select up to  
                                                            <Col as="span" className="shadowMD border08 py-2 px-3 mx-1">2</Col>choice.</label>
                                                        </Col>
                                                        <Col className="form-radio d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="edittemplate02" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" for="edittemplate02">Allow Diner to select up to all available choices.</label>
                                                        </Col>             
                                                    </Col>
                                                </Col>
                                                <Col md="5">
                                                    <FloatingLabel label="Selection 1" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" className="form-control bgLightGray p-3 border-0" id="floatingInput1" placeholder="Chiles en Nogada"/>
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>17 / 40 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 2" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" className="form-control bgLightGray p-3 border-0" id="floatingInput1" placeholder="Chiles en Nogada"/>
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>20 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md="2">
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold grayBdb lh-lg mb-4 label-turncate w-100" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" className="form-control bgLightGray p-3 border-0" id="floatingInput1" placeholder="Chiles en Nogada"/>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" className="form-control bgLightGray p-3 border-0" id="floatingInput1" placeholder="Chiles en Nogada"/>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                </Form>
                            </Col>   
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )    
}
export default MenuTempateEdit;