import React, { useState, useRef } from 'react'
import { Container, Row, Col, Button, Overlay, Tooltip, Modal, Card, ListGroup, Form, OverlayTrigger } from 'react-bootstrap';
import { FiMapPin, FiChevronDown } from "react-icons/fi";
import { GiCutDiamond } from "react-icons/gi";
import { IoFishSharp } from "react-icons/io5";
import { TbEdit } from "react-icons/tb";

import { BsShop, BsFillCircleFill, BsXLg, BsPatchCheckFill, BsStarFill } from "react-icons/bs";
import { HiChevronDown, HiMap, HiOutlineClock, HiOutlineBookOpen, HiOutlineTrash } from "react-icons/hi";

import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import "react-pro-sidebar/dist/css/styles.css";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Tabs, { TabPane } from "rc-tabs";
import "rc-slider/assets/index.css";
import "rc-tabs/assets/index.css";
import "./cartsummery.css"
import Header from "../header";
import Footer from "../footer";

const defaultTabKey = "1";

const PanelContent = ({ children }) => (
  <div style={{overflow: "hidden"}}>{children}</div>
);

const ReviewAndRating = () => {    
    const [start,setStart] = useState(0)
    const [tabKey,setTabKey] = useState('')
    const onTabClick = (key) => {
        console.log(`onTabClick ${key}`);
        setTabKey(key)
    }
    
    var callback = function (key) {};
        
    //modal
    const [show, setShow] = useState(false);
    const [ottClass, setOttClass] = useState(false);

    //tooptip
    const target = useRef(null);    
    const setClass = () =>{
        setShow(!show)
        if(!ottClass){
            document.getElementById("bodyWrap").classList.add("openTT");
            setOttClass(true)
        }else{
            document.getElementById("bodyWrap").classList.remove("openTT");
            setOttClass(false)
        }
    }

    const menuHalal = (props) => (
    <Tooltip id="button-tooltip" {...props}>Halal</Tooltip>
    );
    const menuFish = (props) => (
    <Tooltip id="button-tooltip" {...props}>Vegan</Tooltip>
    );

    //modal02
    const [showOutlet, setShowOutlet] = useState(false);
    const handleClose = () => setShowOutlet(false);
    const handleShow = () => setShowOutlet(true);
   
    const [clicked, setClicked] = useState('');
    const handleClick = () => {
        clicked ? setClicked('') : setClicked('base-state click-state bgLightGray border-0 rounded-pill d-inline-block w-auto position-relative');
    };
    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <br /><br />                
                <Row>
                    <Col md={11} className="m-auto">
                        <div className="mx-4 RestroAdd">
                            <Row>
                                <Col sm md="auto">
                                    <span className="rounded-circle border border-2 border-danger d-inline-block p-1">
                                        <img src={process.env.PUBLIC_URL+"assets/black_scoop.png"} alt=""/>
                                     </span>
                                </Col>
                                <Col md>
                                    <div className="itemDetails pt-2">
                                        <Col as="div" className="itemHead">
                                            <h4 className="d-inline align-middle me-3">City Home Cafe</h4>
                                            <GiCutDiamond size="30" color="var(--diamondBlue)" className="me-3" /> 
                                            <span ref={target} onClick={() => setClass()} className="border100 openNow btn bgGreen text-white semibold text-decoration-none me-2">Open Now</span>
                                                <a role="button" variant="primary" onClick={handleShow} className="border100 calenderIcon bgLight text-black semibold text-decoration-none">
                                                <BsShop size="14" color="var(--theme-color)" /> 3 Outlets</a>
      
                                            <Overlay target={target.current} show={show} placement="bottom">
                                                {(props) => (
                                                <Tooltip className="TTPopup" id="overlay-example" {...props}>
                                                    <ul className="listgroup listGRP p-0 mb-0">
                                                        <li className="listgroup d-flex align-items-center px-4 py-3">
                                                            <FiMapPin size="26" color="var(--bs-white)" className="me-3" />
                                                            <p className="w-75 text-start m-0">10 Bayfront Ave, #05-05, Singapore 018956</p>
                                                            <BsFillCircleFill size="11" color="var(--bs-greenDark)" className="ms-3" />
                                                        </li>
                                                        <li className="listgroup d-flex align-items-center px-4 py-3">
                                                            <FiMapPin size="26" color="var(--bs-white)" className="me-3" />
                                                            <p className="w-75 text-start m-0">170 Bencoolen St, #01-01, Singapore 189657</p>
                                                            <BsFillCircleFill size="11" color="var(--bs-greenDark)" className="ms-3" />
                                                        </li>
                                                        <li className="listgroup d-flex align-items-center px-4 py-3">
                                                            <FiMapPin size="26" color="var(--bs-white)" className="me-3" />
                                                            <p className="w-75 text-start m-0">3 Belilios Rd, #52-52, Singapore 219924</p>
                                                            <BsFillCircleFill size="11" color="var(--bs-red0C0)" className="ms-3" />
                                                        </li>
                                                    </ul>
                                                    <Button className="shadow" onClick={setClass}>
                                                        <BsXLg size="14" color="var(--bs-white)" />
                                                    </Button>
                                                </Tooltip>
                                                )}
                                            </Overlay>
                                        </Col>
                                        <Col as="div" className="itemaddr pt-1">
                                            <FiMapPin size="20" color="var(--bs-border)" className="me-1" />
                                            <p className="d-inline semibold align-middle me-1">10 Bayfront Ave, Singapore 018956</p>
                                            <HiMap size="18" color="var(--bs-border)" className="me-4" />
                                            <p className="d-inline semibold align-middle me-md-5">
                                                <HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> 1.2 KM
                                            </p>
                                            <p className="d-inline semibold align-middle">                                            
                                                <img src={process.env.PUBLIC_URL+"assets/rating.jpg"} alt="rating" />
                                            </p>
                                            <p className="semibold align-middle mb-1 mt-1 openHour" 
                                                data-bs-toggle="collapse" 
                                                data-bs-target="#collapseWidthExample" 
                                                aria-expanded="false" 
                                                aria-controls="collapseWidthExample"><strong>Opening Hours :</strong> <span className="lighTx">Monday to Friday, 8:00 AM to 10:00 PM</span> <HiChevronDown size="30" color="var(--theme-color)" /></p>
                                            <div className="collapse collapse-vertical pb-2 w-md-75" id="collapseWidthExample">
                                                <div className="card card-body">
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item"><strong>Sunday to Monday,</strong> 8:00 AM to 10:00 PM</li>
                                                        <li className="list-group-item"><strong>Monday to Tuesday,</strong> 8:00 AM to 10:00 PM</li>
                                                        <li className="list-group-item"><strong>Tuesday to Wednesday,</strong> 8:00 AM to 10:00 PM</li>
                                                        <li className="list-group-item"><strong>Wednesday to Thursday,</strong> 8:00 AM to 10:00 PM</li>
                                                        <li className="list-group-item"><strong>Thursday to Friday,</strong> 8:00 AM to 10:00 PM</li>
                                                        <li className="list-group-item"><strong>Friday to Saturday,</strong> 8:00 AM to 10:00 PM</li>
                                                        <li className="list-group-item"><strong>Saturday to Sunday,</strong> 8:00 AM to 10:00 PM</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="hotelType">
                                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={menuHalal}>
                                                    <span className="htl01 d-inline-block me-1 text-center">
                                                        <img role="button" src={process.env.PUBLIC_URL+"assets/halal_icon.png"} alt="food" />
                                                    </span>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={menuFish}>
                                                    <span className="htl01 d-inline-block text-center">
                                                        <IoFishSharp role="button" className="mt-1" size="20" />
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            
                                        </Col>
                                    </div>
                                </Col>
                                <Col md="3" className="text-md-end text-center mt-md-4">
                                    <button type="button" className="btn bgLight GrayComn text-nowrap border100 py-2 lh-sm mx-2 btn-light">
                                        <span className="px-2 fs20"><HiOutlineBookOpen size="24" color="var(--theme-color)" className="align-text-top" /> Back to Menu</span>
                                    </button>
                                    <Col role="button" as="a" className="commentBox">
                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75 10H2.75C1.92157 10 1.25 9.32845 1.25 8.5V2.5C1.25 1.67157 1.92157 1 2.75 1H13.25C14.0784 1 14.75 1.67157 14.75 2.5V8.5C14.75 9.32845 14.0784 10 13.25 10H9.5L5.75 13.75V10Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <br /><br /><br />
                <Row className="RestroTabs">
                    <Col md={11} className="m-auto">
                        <div className="WrapRestro border-0">
                            <div className="mx-4 RestroAdd">
                                <Tabs 
                                    className="rctabsrating"
                                    defaultActiveKey={defaultTabKey}                                    
                                    renderTabContent={() => <TabContent />}
                                    onChange={callback}
                                    tabBarGutter={100}
                                    renderTabBar={() => (
                                        <ScrollableInkTabBar onTabClick={onTabClick} />
                                    )}>
                                    <TabPane tab="Review &#38; Ratings" key="1" id="test1" >
                                        <PanelContent id={start}>
                                            <Col className="text-end sortby pt-5">
                                                <strong className="pe-2 fs18 semibold">Sort By :</strong>
                                                <Col as="div" className={clicked || 'base-state bgLightGray border-0 rounded-pill d-inline-block w-auto position-relative' } onClick={handleClick}>
                                                    <Form.Select className="d-inline-block w-auto text-dark bgLightGray border-0 rounded-pill" aria-label="Default select example">
                                                        <option>Latest</option>
                                                        <option value="2">Old</option>
                                                        <option value="3">Oldest</option>
                                                    </Form.Select>
                                                    <FiChevronDown size="20" className="chevronAro me-2 transition" color="var(--bs-grayDark)" />
                                                </Col>
                                            </Col>
                                            <Col as="section" className="outletDeals px-2">
                                                <Row className="custRev">
                                                    {/* left section */}
                                                    <Col md={12} lg={3} className="leftCust_old">
                                                        <ListGroup>
                                                            <ListGroup.Item className="border-0 p-0">
                                                                <div className="heading inner pb-3 mb-2 mt-4 pt-2">
                                                                    <h2 className="m-0 d-inline-block fs22 GrayComn">Ratings</h2>
                                                                </div>
                                                                <Col as="div" className="shadowSM p-3 text-center">
                                                                    <img src={process.env.PUBLIC_URL+"assets/customerRating.png"} className="img-fluid" alt="rating" />
                                                                </Col>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 mt-4">
                                                                <div className="heading inner pb-3 mt-3">
                                                                    <h2 className="m-0 d-inline-block fs22 GrayComn">Host Experience Level</h2>
                                                                </div>
                                                                <Col as="div" className="shadowMD px-3 text-center">
                                                                    <Col as="div" className="d-inline-block border border-5 grayf5f rounded-pill mt-5">
                                                                        <img src={process.env.PUBLIC_URL+"assets/master_chef.png"} className="bgLight rounded-pill m-1 p-3 img-fluid" alt="diamond" />                                                                        
                                                                    </Col>                                                                    
                                                                    <p className="GrayDark text-center fs26 bold mt-3 pb-4">Master Chef</p>
                                                                </Col>
                                                            </ListGroup.Item>                                                            
                                                        </ListGroup>
                                                    </Col>
                                                    {/* right section */}
                                                    <Col md={12} lg={9} className="pe-0">
                                                        <Col as="section" className="ps-md-5 rightcustList">
                                                            <div className="heading inner pb-3 mb-2 mt-4 pt-2">
                                                                <h2 className="m-0 d-inline-block fs22 GrayComn">Reviews</h2>
                                                            </div>
                                                            <Scrollbars 
                                                                style={{overflowX: "hidden"}}
                                                                className="mh100 hthorizontalscroll"
                                                                universal={true}>
                                                                <Row className="mb-5 mx-0">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                                            </ListGroup.Item>                                                                        
                                                                            
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                                <TbEdit size="24" color="var(--theme-color)" className="me-sm-3" />
                                                                                <HiOutlineTrash size="24" color="var(--theme-color)" />
                                                                            </ListGroup.Item>                                                                        
                                                                        </ListGroup>

                                                                        {/* <ListGroup horizontal className="justify-content-end align-items-center">
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup> */}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-5 mx-0 pt-3">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"       alt=""/>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-5 mx-0 pt-3">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} alt='user post' width={90} height={90} className="rounded-pill border border-danger" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"       alt=""/>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-5 mx-0 pt-3">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} alt='user post' width={90} height={90} className="rounded-pill border border-danger" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"       alt=""/>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-5 mx-0 pt-3">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} alt='user post' width={90} height={90} className="rounded-pill border border-danger" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"       alt=""/>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-5 mx-0 pt-3">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} alt='user post' width={90} height={90} className="rounded-pill border border-danger" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt="userreview" />
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt="userreview" />
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4" alt="userreview" />
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-5 mx-0 pt-3">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} alt='user post' width={90} height={90} className="rounded-pill border border-danger" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"       alt=""/>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup>
                                                                    </Col>                                                                
                                                                </Row>
                                                                <Row className="mb-5 mx-0 pt-3">
                                                                    <Col md="auto">
                                                                        <span className="rounded-pill border border-danger p-1 d-inline-block">
                                                                            <img src={process.env.PUBLIC_URL+"assets/frame02_old.png"} alt='user post' width={90} height={90} className="rounded-pill border border-danger" />
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="contentpart">
                                                                        <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                                            <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                                            <p className="d-inline-block bg-light semibold p-1 position-relative m-0">
                                                                                <BsStarFill className="align-top" size="16" color="var(--rating)" /> 
                                                                                <span className="d-inline-block">&nbsp;4.5</span>
                                                                            </p>
                                                                            <span className="float-end fs16 graya1a">Today</span>
                                                                        </h6>
                                                                        <p className="fs16 semibold pe-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam ultrices bibendum. 
                                                                            Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus amet consectetur 
                                                                            ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        </p>
                                                                        <ListGroup horizontal className="justify-content-between align-items-center">
                                                                            <ListGroup.Item className="border-0 p-0">
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                                                <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4" alt=""/>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item role="button" className="border-0 p-0 fs18 semibold themeColor">
                                                                            <svg className="me-1" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.625 5.25C0.796573 5.25 0.125 5.92157 0.125 6.75C0.125 7.57843 0.796573 8.25 1.625 8.25V5.25ZM15.875 15.5C15.875 16.3284 16.5466 17 17.375 17C18.2034 17 18.875 16.3284 18.875 15.5H15.875ZM2.68566 5.68934C2.09987 5.10355 1.15013 5.10355 0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066L2.68566 5.68934ZM5.81434 13.0607C6.40013 13.6464 7.34987 13.6464 7.93566 13.0607C8.52145 12.4749 8.52145 11.5251 7.93566 10.9393L5.81434 13.0607ZM0.56434 5.68934C-0.0214466 6.27513 -0.0214466 7.22487 0.56434 7.81066C1.15013 8.39645 2.09987 8.39645 2.68566 7.81066L0.56434 5.68934ZM7.93566 2.56066C8.52145 1.97487 8.52145 1.02513 7.93566 0.43934C7.34987 -0.146447 6.40013 -0.146447 5.81434 0.43934L7.93566 2.56066ZM1.625 8.25H10.375V5.25H1.625V8.25ZM10.375 8.25C13.4126 8.25 15.875 10.7124 15.875 13.75H18.875C18.875 9.05556 15.0694 5.25 10.375 5.25V8.25ZM15.875 13.75V15.5H18.875V13.75H15.875ZM0.56434 7.81066L5.81434 13.0607L7.93566 10.9393L2.68566 5.68934L0.56434 7.81066ZM2.68566 7.81066L7.93566 2.56066L5.81434 0.43934L0.56434 5.68934L2.68566 7.81066Z" fill="var(--theme-color)"/>
                                                                            </svg>
                                                                                Reply
                                                                            </ListGroup.Item>
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Scrollbars>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </PanelContent>
                                    </TabPane>
                                    <TabPane tab="Media Gallery" key="2">
                                        <PanelContent id={start}>
                                            <Col as="section" className="outletDeals row col mx-0">
                                                <div className="heading inner mt-5 mb-4 pb-3">
                                                    <h2 className="m-0 d-inline-block fs22 GrayComn">&nbsp;</h2>
                                                </div>
                                                <Scrollbars className="mh100" universal={true}>
                                                    <Row className="px-md-3">
                                                        <Col xs={12} md={6} className="mb-4 rows2"><img src={process.env.PUBLIC_URL+"assets/Rec_463.png"} className="img-fluid w-100 h-100" alt=""/></Col>
                                                        <Col xs={12} md={6} className="mb-4">
                                                            <Row className="mb-4">
                                                                <Col className="col"><img src={process.env.PUBLIC_URL+"assets/Rec_436.png"} className="img-fluid" alt=""/></Col>
                                                                <Col className="col"><img src={process.env.PUBLIC_URL+"assets/Rec_428.png"} className="img-fluid" alt=""/></Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="col"><img src={process.env.PUBLIC_URL+"assets/Rec_432.png"} className="img-fluid" alt=""/></Col>
                                                                <Col className="col"><img src={process.env.PUBLIC_URL+"assets/Rec_433.png"} className="img-fluid" alt=""/></Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="px-md-3">
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_435.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_436.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_437.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_438.png"} className="img-fluid" alt=""/></Col>
                                                    </Row>
                                                    <Row className="px-md-3">
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_456.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_457.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_458.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_459.png"} className="img-fluid" alt=""/></Col>
                                                    </Row>
                                                    <Row className="px-md-3">
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_461.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_462.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_463.png"} className="img-fluid" alt=""/></Col>
                                                        <Col xs={6} md={3} className="mb-4"><img src={process.env.PUBLIC_URL+"assets/Rec_464.png"} className="img-fluid" alt=""/></Col>
                                                    </Row>
                                                </Scrollbars>
                                            </Col>                                            
                                        </PanelContent>
                                    </TabPane>                                    
                                </Tabs>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <br /><br />
            <Footer />

            <div className="BGoverlay"></div>
            <Modal show={showOutlet} onHide={handleClose} animation={true} centered className="outLetPopUp">
                <Modal.Header closeButton className="CloseButton position-absolute p-0 justify-content-center">
                    <BsXLg size="14" color="var(--bs-white)" />
                </Modal.Header>
                <Modal.Body className="p-0 imgSec">
                    <Col as="div">
                        <Card.Img variant="top" src="./assets/outletPP.png" alt="map" />
                    </Col>
                </Modal.Body>
                <Modal.Footer className="justify-content-start ftroutlet ps-5">
                    <p className="areaLocation m-0"><FiMapPin size="32" color="var(--bs-grayDark)" /> 10 Bayfront Ave, Singapore 018956</p>
                </Modal.Footer>
            </Modal>
        </div>
    )    
}
export default ReviewAndRating