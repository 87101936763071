import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import "react-pro-sidebar/dist/css/styles.css";
import "./careers.css";
import Header from "../header";
import Footer from "../footer";


const BecomeAHost = () => {
    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>                
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Row>
                    <Col className="aboutbanner p-0 position-relative">
                        <figure className="figure position-absolutes m-0">
                            <img src={process.env.PUBLIC_URL+"assets/bannerHost.png"} className="figure-img img-fluid d-block" alt="careerpicture" />
                        </figure>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={11} className="m-auto">
                        <div className="mx-md-4 checkoutAdd">
                            {/* <Col as="section">
                                <figure className="figure position-absolute text-center hostHeading">
                                    <h3 className="fs52vw semibold black lh-md m-0">Become A Host</h3>
                                    <h2 className="fs80vw semibold black lh-md">Host Cribbly</h2>
                                    <p className="fs40 semibold gray82 pt-2 lh-sm mb-0 pb-4">Amet minim mollit non deserunt</p>
                                    <img src={process.env.PUBLIC_URL+"assets/crossStick.png"} className="figure-img m-auto img-fluid d-block mb-3" alt="careerpicture" />
                                    <Button variant="danger" className="rounded-pill px-5 py-3 lh-sm fs30 mt-5">Signup Now</Button>
                                </figure>
                            </Col> */}
                            <Col as="section" className="hostCanvas mt-5">
                                <Row className="justify-content-between">
                                    <Col xl="3" lg="5" md="6">
                                        <h2 className='fs46 GrayComn semibold'>Coming Soon</h2>
                                        <p className='fs22 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                    </Col>
                                    <Col xl="3" lg="5" md="6">
                                        <h2 className='fs46 GrayComn semibold'>Coming Soon</h2>
                                        <p className='fs22 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                    </Col>
                                </Row>
                                <Row className="justify-content-between">
                                    <Col className='midCricle'><img src={process.env.PUBLIC_URL+"assets/hostcanvas.svg"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" /></Col>
                                </Row>
                                <Row className="justify-content-between">
                                <Col xl="3" lg="5" md="6">
                                        <h2 className='fs46 GrayComn semibold'>Coming Soon</h2>
                                        <p className='fs22 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                    </Col>
                                    <Col xl="3" lg="5" md="6">
                                        <h2 className='fs46 GrayComn semibold'>Coming Soon</h2>
                                        <p className='fs22 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Col as="div" className="coreSection royaltyCore">
                <Col md={11} className="m-auto">
                    <Row className="mx-md-4 rylBox">
                        <Col md="6" className='fs96vw semibold GrayComn lh-sm vlBox'>
                            Cribbly <br />Royalty Program
                            <p className="fs46 semibold pt-3">Amet minim mollit non deserunt</p>
                            <p className='fs28 gray82 semibold mt-3 pt-3'>Amet minim mollit non deserunt ullamco est sit <br />aliqua dolor do amet sintduis enim Exercitation <br />veniam consequat sunt.</p>
                            <Button variant="danger" className="btn-lg py-4 fs26 border100 royaltybutton">Learn More</Button>
                        </Col>
                        <Col md="6" className="royaltyright">
                        <img src={process.env.PUBLIC_URL+"assets/royaltyImg.png"} className="figure-img m-auto img-fluid d-block w-100 h-100" alt="svgcanvas" />
                        </Col>
                    </Row>
                </Col>
            </Col>            
            <Col as="div">
                <Col md={11} className="m-auto">
                    <Row className="mx-md-4 hypart m-0">
                        <Col md="12" className="mt-5 pt-5 text-center">
                            <h2 className="fs72 semibold GrayDark">Why Partners With <br />Cribble</h2>
                            <p className="fs28 GrayComn semibold lh-sm pt-4">Ordering your next meal should not be rocket science. With Cribbly, we make it <br />easy for you. Follow these 5 steps and you are all set for your next meal!</p>
                        </Col>
                        <Col md="12" className="pt-5 mt-4 text-center orderStepSec">
                            <Row>
                                <Col sm="12" md="12" lg="4">
                                    <img src={process.env.PUBLIC_URL+"assets/orderStep01.png"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" />
                                    <p className="fs28 GrayComn semibold lh-sm pt-5 px-2">Search and browse for your favourite food, offered by our lovely Hosts. You may wish to search by location to find the nearest to you.</p>
                                </Col>
                                <Col sm="12" md="12" lg="4">
                                    <img src={process.env.PUBLIC_URL+"assets/orderStep02.png"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" />
                                    <p className="fs28 GrayComn semibold lh-sm pt-5">Select your desired food and check if ingredient may contain any possible food allergen(Please note not all food content possible allergens are indicated, please check directly with your Hosts prior to ordering.)</p>
                                </Col>
                                <Col sm="12" md="12" lg="4">
                                    <img src={process.env.PUBLIC_URL+"assets/orderStep03.png"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" />
                                    <p className="fs28 GrayComn semibold lh-sm pt-5 px-2">Once you are set and ready, proceed to checkout by selecting "Buy Now". In put your order details as shown on your screen. Check to ensure 
                                    all your order details are correct and confirm your order.</p>
                                </Col>
                                </Row>
                                <Row className="mt-5">
                                <Col sm="12" md="12" lg="4">
                                    <img src={process.env.PUBLIC_URL+"assets/orderStep04.png"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" />
                                    <p className="fs28 GrayComn semibold lh-sm pt-5 px-2">Search and browse for your favourite food, offered by our lovely Hosts. You may wish to search by location to find the nearest to you.</p>
                                </Col>
                                <Col sm="12" md="12" lg="4">
                                    <img src={process.env.PUBLIC_URL+"assets/orderStep05.png"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" />
                                    <p className="fs28 GrayComn semibold lh-sm pt-5">Select your desired food and check if ingredient may contain any possible food allergen(Please note not all food content possible allergens are indicated, please check directly with your Hosts prior to ordering.)</p>
                                </Col>
                                <Col sm="12" md="12" lg="4">
                                    <img src={process.env.PUBLIC_URL+"assets/orderStep06.png"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" />
                                    <p className="fs28 GrayComn semibold lh-sm pt-5 px-2">Once you are set and ready, proceed to checkout by selecting "Buy Now". In put your order details as shown on your screen. Check to ensure all your order 
                                    details are correct and confirm your order.</p>
                                </Col>
                            </Row>
                            <Button variant="danger" className="btn-lg fs40 w-25 border100 my-5">Go To FAQ</Button>
                        </Col>
                    </Row>
                </Col>
            </Col>

            <br /><br />
            <Footer />
        </div>
    )    
}
export default BecomeAHost