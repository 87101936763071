import React from 'react'
import { Container, Row, Col, Button, Form, Badge, Figure, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import { FiCreditCard, FiChevronRight } from "react-icons/fi";
import { IoCloseCircle } from "react-icons/io5";
import { HiOutlineHome } from "react-icons/hi";
import { BsFillCircleFill } from 'react-icons/bs';
import { AiFillInfoCircle } from "react-icons/ai";
import "react-pro-sidebar/dist/css/styles.css";

import "./cartsummery.css"
import Header from "../header";
import Footer from "../footer";
import { Link } from 'react-router-dom';


const CartCheckout = () => {    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col md={11} className="m-auto">
                        <div className="mx-4 checkoutAdd">
                            <h1 className="mb-4">My Cart</h1>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className="tabContainer">
                                <Col sm md={9} className="position-relative">
                                <Nav variant="pills" className="d-flex tabClickable rounded-pill">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" to="/">Credit / Debit Card</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second" to="/">Other Payment Methods</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                    <Col as="section" className="cartBox zindex topPart">
                                        <Row>
                                            <Col md>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                    <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                        <Col as="div" className="cafeImage me-3 mt-3">
                                                            <h2 className="fs28 m-0 semibold"><FiCreditCard className="align-top" size="33" /> Payment Method</h2>
                                                        </Col>                                                    
                                                    </Col>
                                                    <Col as="div" className="cartList">
                                                        <h6 className="m-0 ms-5 cardhead">
                                                        <span className="brMob">&nbsp;Credit / Debit Card</span>
                                                        <Badge pill bg="false" role="button" className="bgLightGreen ms-2">Preferred</Badge>

                                                        <Dropdown className="float-end morecards">
                                                        <Dropdown.Toggle variant="success" className="moreCard badge bgLightGray border-0 rounded-pill" id="dropdown-basic">
                                                            2 More Cards <FiChevronRight size="20" className="chevronAro" color="var(--theme-color)" />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                        <ul className="listgroup listGRP p-0 mb-0">
                                                            <li className="listgroup px-2 py-3">
                                                                <Row className="text-center">
                                                                    <Col><img src="./assets/visapic.png" className="img-fluid" alt="visapic" /></Col>
                                                                    <Col>08/29</Col>
                                                                    <Col>2534</Col>
                                                                </Row>
                                                            </li>
                                                            <li className="listgroup px-2 py-3">
                                                                <Row className="text-center">
                                                                    <Col><img src="./assets/marstercardpic.png" className="img-fluid" alt="visapic" /></Col>
                                                                    <Col>07/28</Col>
                                                                    <Col auto>1720</Col>
                                                                </Row>
                                                            </li>
                                                            <li className="listgroup px-2 py-3">
                                                                <Row className="text-center">
                                                                    <Col><img src="./assets/visapic.png" className="img-fluid" alt="visapic" /></Col>
                                                                    <Col>11/26</Col>
                                                                    <Col>8874</Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                        </Dropdown.Menu>
                                                        </Dropdown>
                                                        </h6>
                                                        <Row className="align-items-center w-100 mw-100 m-auto">
                                                            <Col md="auto" className="listID">
                                                                <div className="form-radio">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="card01" />
                                                                    <label className="form-check-label" for="card01"><span>&nbsp;</span></label>
                                                                </div>
                                                            </Col>
                                                            <Col className="listCard" md="5">
                                                                <Col as="div" className="cardPost">
                                                                    <img src="./assets/blackCard.png" className="img-fluid w-100" alt="paymentmethod" />
                                                                </Col>
                                                            </Col>
                                                            <Col className="cardNumber ms-md-5 pe-md-0">
                                                                <br />
                                                                <Figure className="dotsCircle">
                                                                    <h6 className="semibold m-0">Card Number</h6>
                                                                    <Figure.Caption>
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />
                                                                        
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />

                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="ms-4" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me1" />
                                                                        <BsFillCircleFill size="12" color="var(--bs-999)" className="me-3" />

                                                                        <span className="ms-4 text">9214</span>
                                                                    </Figure.Caption>
                                                                </Figure>
                                                                <Figure className="d-flex mt-3 mb-4">
                                                                    <Col xs="5">
                                                                        <h6 className="semibold m-0">Expiry Date</h6>
                                                                        <Figure.Caption>
                                                                            <span className="text letter-spacing-normal">08/29</span>
                                                                        </Figure.Caption>
                                                                    </Col>
                                                                    <Col>
                                                                        <h6 className="semibold m-0">Card Holder Name</h6>
                                                                        <Figure.Caption>
                                                                            <span className="text letter-spacing-normal">John Doe</span>
                                                                        </Figure.Caption>
                                                                    </Col>                                                                
                                                                </Figure>
                                                                <Figure className="d-md-flex align-items-end justify-content-between pt-2">
                                                                    <Figure.Caption>
                                                                        <Form className="cvvNu">
                                                                            <Form.Group controlId="formBasicText">
                                                                                <Form.Control type="text" placeholder="CVV" className="shadow-none" />
                                                                                <span className="infoIcon" >
                                                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">CVV is the last three digits on the back of your credit card.</Tooltip>}>
                                                                                        {({ ref, ...triggerHandler }) => (
                                                                                            <Button variant="light" {...triggerHandler} className="d-inline-flex align-items-center p-0">
                                                                                            <Image ref={ref} roundedCircle src="holder.js/20x20?text=J&bg=28a745&fg=FFF" />
                                                                                            <AiFillInfoCircle role="button" size="20" color="var(--bs-border)" />
                                                                                            </Button>
                                                                                        )}
                                                                                    </OverlayTrigger>                                                                                    
                                                                                </span>
                                                                            </Form.Group>
                                                                        </Form>
                                                                    </Figure.Caption>
                                                                    <Figure.Caption className="linktext">
                                                                        <Link to="" className="text-decoration-none">Manage Payment Method</Link>
                                                                    </Figure.Caption>
                                                                </Figure>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                            <br />
                                                                <hr className="separator mb-4" />                                                            
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-center w-100 mw-100 m-auto">
                                                            <Col md="auto" className="listID">
                                                                <div className="form-radio">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="card02" />
                                                                    <label className="form-check-label" for="card02"><span>&nbsp;</span></label>
                                                                </div>
                                                            </Col>
                                                            <Col className="listCard" md="5">
                                                                <Col as="div" className="cardPost">
                                                                    <img src="./assets/redCard.png" className="img-fluid w-100" alt="paymentmethod" />
                                                                </Col>
                                                            </Col>
                                                            <Col className="cardNumber ms-md-5 pe-md-0">
                                                                <Figure className="mb-2">
                                                                    <h6 className="semibold m-0">Card Number</h6>
                                                                    <Figure.Caption>
                                                                    <Form>
                                                                        <Form.Group controlId="formBasicText" className="d-md-flex">
                                                                            <Form.Control type="number" placeholder="" className="shadow-none me-4" />
                                                                            <Form.Control type="number" placeholder="" className="shadow-none me-4" />
                                                                            <Form.Control type="number" placeholder="" className="shadow-none me-4" />
                                                                            <Form.Control type="number" placeholder="" className="shadow-none" />
                                                                        </Form.Group>
                                                                    </Form>                                                                    
                                                                    </Figure.Caption>
                                                                </Figure>
                                                                <Figure className="row mb-2">
                                                                    <Col>
                                                                        <h6 className="semibold m-0">Expiry Date</h6>
                                                                        <Figure.Caption>
                                                                            <Form>
                                                                                <Form.Group controlId="formBasicText">
                                                                                    <Form.Control type="text" placeholder="MM/YY" className="shadow-none mw-100" />
                                                                                </Form.Group>
                                                                            </Form>
                                                                        </Figure.Caption>
                                                                    </Col>
                                                                    <Col>
                                                                        <h6 className="semibold m-0">CVV</h6>
                                                                        <Figure.Caption>
                                                                            <Form>
                                                                                <Form.Group controlId="formBasicText">
                                                                                    <Form.Control type="text" placeholder="" className="shadow-none mw-100" />
                                                                                </Form.Group>
                                                                            </Form>
                                                                        </Figure.Caption>
                                                                    </Col>                                                                
                                                                </Figure>
                                                                <Figure className="row mb-2">
                                                                    <Col>
                                                                        <h6 className="semibold m-0">Card Holder Name </h6>
                                                                        <Figure.Caption>
                                                                            <Form>
                                                                                <Form.Group controlId="formBasicText">
                                                                                    <Form.Control type="text" placeholder="Holder Name" className="shadow-none mw-100" />
                                                                                </Form.Group>
                                                                            </Form>
                                                                        </Figure.Caption>
                                                                    </Col>                                                                                                                              
                                                                </Figure>
                                                                <Figure className="row pt-2 mb-0">
                                                                    <Col>
                                                                        <Figure.Caption>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flex04" />
                                                                            <label className="form-check-label" for="flex04"><span className="ps-2">Save Card to File</span></label>
                                                                        </div> 
                                                                        </Figure.Caption>
                                                                    </Col>                                                                                                                              
                                                                </Figure>
                                                            </Col>
                                                        </Row> 
                                                    </Col>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                            <Col as="div" className="cafeImage me-3 mt-3">
                                                                <h2 className="fs28 m-0 semibold">
                                                                <svg width="38" height="28" viewBox="0 0 38 28" fill="none" className="me-3 aling-top mb-1">
                                                                    <path d="M21.5003 16.5003C20.1114 16.5003 18.9309 16.0142 17.9587 15.042C16.9864 14.0698 16.5003 12.8892 16.5003 11.5003C16.5003 10.1114 16.9864 8.93088 17.9587 7.95866C18.9309 6.98644 20.1114 6.50033 21.5003 6.50033C22.8892 6.50033 24.0698 6.98644 25.042 7.95866C26.0142 8.93088 26.5003 10.1114 26.5003 11.5003C26.5003 12.8892 26.0142 14.0698 25.042 15.042C24.0698 16.0142 22.8892 16.5003 21.5003 16.5003ZM8.16699 22.3337C7.47255 22.3337 6.88227 22.0906 6.39616 21.6045C5.91005 21.1184 5.66699 20.5281 5.66699 19.8337V3.16699C5.66699 2.47255 5.91005 1.88227 6.39616 1.39616C6.88227 0.910048 7.47255 0.666992 8.16699 0.666992H34.8337C35.5281 0.666992 36.1184 0.910048 36.6045 1.39616C37.0906 1.88227 37.3337 2.47255 37.3337 3.16699V19.8337C37.3337 20.5281 37.0906 21.1184 36.6045 21.6045C36.1184 22.0906 35.5281 22.3337 34.8337 22.3337H8.16699ZM12.3337 19.8337H30.667C30.667 18.667 31.0698 17.6809 31.8753 16.8753C32.6809 16.0698 33.667 15.667 34.8337 15.667V7.33366C33.667 7.33366 32.6809 6.93088 31.8753 6.12533C31.0698 5.31977 30.667 4.33366 30.667 3.16699H12.3337C12.3337 4.33366 11.9309 5.31977 11.1253 6.12533C10.3198 6.93088 9.33366 7.33366 8.16699 7.33366V15.667C9.33366 15.667 10.3198 16.0698 11.1253 16.8753C11.9309 17.6809 12.3337 18.667 12.3337 19.8337ZM32.3337 27.3337H3.16699C2.47255 27.3337 1.88227 27.0906 1.39616 26.6045C0.910048 26.1184 0.666992 25.5281 0.666992 24.8337V5.66699H3.16699V24.8337H32.3337V27.3337ZM8.16699 19.8337V3.16699V19.8337Z" fill="#374151"/>
                                                                </svg>
                                                                    Others Methods</h2>
                                                            </Col>                                                            
                                                        </Col>
                                                        <Col as="div" className="cartList">
                                                            <br /><br /><br />
                                                            <Row className="text-center cardmethod">
                                                                <Col>
                                                                    <img src="./assets/paytm_img.png" className="img-fluid" alt="paytm" />
                                                                    <br /><br /><br />
                                                                    <div className="form-radio d-inline-block">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="method01" />
                                                                        <label className="form-check-label" for="method01"><span className="fs24 semibold GrayDark ps-3">Paypal</span></label>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <img src="./assets/apple_pay_img.png" className="img-fluid" alt="applepay" />
                                                                    <br /><br /><br />
                                                                    <div className="form-radio d-inline-block">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="method02" />
                                                                        <label className="form-check-label" for="method02"><span className="fs24 semibold GrayDark ps-3">Paypal</span></label>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <img src="./assets/googlpay_img.png" className="img-fluid" alt="googlpay" />
                                                                    <br /><br /><br />
                                                                    <div className="form-radio d-inline-block">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="method03" />
                                                                        <label className="form-check-label" for="method03"><span className="fs24 semibold GrayDark ps-3">Paypal</span></label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br /><br /><br /><br /><br /><br />
                                                        </Col>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Row md className="mt-5">
                                        <Col md className="mt-3 d-md-flex">
                                            <Col as="section" className="cartBox zindex me-3">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                        <h2 className="fs28 m-0 semibold">
                                                        <svg width="24" height="30" viewBox="0 0 24 30" fill="none" className="align-top me-3">
                                                            <path d="M7.5 13.5C6.67157 13.5 6 14.1716 6 15C6 15.8284 6.67157 16.5 7.5 16.5V13.5ZM16.5 16.5C17.3284 16.5 18 15.8284 18 15C18 14.1716 17.3284 13.5 16.5 13.5V16.5ZM7.5 19.5C6.67157 19.5 6 20.1716 6 21C6 21.8284 6.67157 22.5 7.5 22.5V19.5ZM16.5 22.5C17.3284 22.5 18 21.8284 18 21C18 20.1716 17.3284 19.5 16.5 19.5V22.5ZM13.9394 1.93934L12.8786 2.99991L12.8787 2.99999L13.9394 1.93934ZM22.0606 10.0607L23.1214 9.00009L23.1213 9.00001L22.0606 10.0607ZM7.5 16.5H16.5V13.5H7.5V16.5ZM7.5 22.5H16.5V19.5H7.5V22.5ZM19.5 27H4.5V30H19.5V27ZM4.5 27C3.67156 27 3 26.3285 3 25.5H0C0 27.9853 2.01473 30 4.5 30V27ZM3 25.5V4.5H0V25.5H3ZM3 4.5C3 3.67157 3.67157 3 4.5 3V0C2.01472 0 0 2.01472 0 4.5H3ZM4.5 3H12.8787V0H4.5V3ZM12.8787 3L12.8786 2.99991L15.0001 0.87876C14.4375 0.316025 13.6742 0 12.8787 0V3ZM12.8787 2.99999L21 11.1213L23.1213 9.00001L15 0.878677L12.8787 2.99999ZM20.9999 11.1212L21 11.1213H24C24 10.3255 23.6838 9.56255 23.1214 9.00009L20.9999 11.1212ZM21 11.1213V25.5H24V11.1213H21ZM21 25.5C21 26.3285 20.3285 27 19.5 27V30C21.9853 30 24 27.9853 24 25.5H21Z" fill="#404040"/>
                                                        </svg>
                                                         Billing Address
                                                         
                                                         <Button className="semibold float-end p-0 themeColor text-decoration-none fs18 mt-1 me-3" variant="link">Edit</Button>
                                                         </h2>
                                                    </Col>
                                                </Col>
                                                <Col className="cartList pb-5">
                                                    <Row className="cardNumber">
                                                        <Col md="7">
                                                            <h6 class="semibold m-0">Card Holder Name</h6>
                                                            <p class="fs18 semibold m-0">170 Bencoolen Street</p>
                                                        </Col>
                                                        <Col md="3">
                                                            <h6 class="semibold m-0">Floor No.</h6>
                                                            <p class="fs18 semibold m-0">01</p>
                                                        </Col>
                                                        <Col md="auto">
                                                            <h6 class="semibold m-0">Unit No.</h6>
                                                            <p class="fs18 semibold m-0">01</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="cardNumber mt-3">
                                                        <Col md="7">
                                                            <h6 class="semibold m-0">Address 2</h6>
                                                            <p class="fs18 semibold m-0">-</p>
                                                        </Col>
                                                        <Col md="4">
                                                            <h6 class="semibold m-0">Postal Code</h6>
                                                            <p class="fs18 semibold m-0">189657</p>
                                                        </Col>
                                                        <Col md="auto">
                                                            <h6 class="semibold m-0">&nbsp;</h6>
                                                            <p class="fs18 semibold m-0">&nbsp;</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                            <Col as="section" className="cartBox zindex ms-3">
                                                <Col as="div" className="cartHeader d-md-flex justifiy-space-between">
                                                    <Col as="div" className="cafeImage me-3 mt-3">
                                                        <h2 className="fs28 m-0 semibold">
                                                            <HiOutlineHome className="align-top me-3" size="33" />Collection Address                                                        
                                                        </h2>
                                                    </Col>
                                                </Col>
                                                <Col className="cartList">
                                                    <Row className="cardNumber">
                                                        <Col md="7">
                                                            <h6 class="semibold m-0">Card Holder Name</h6>
                                                            <p class="fs18 semibold m-0">170 Bencoolen Street</p>
                                                        </Col>
                                                        <Col md="3">
                                                            <h6 class="semibold m-0">Floor No.</h6>
                                                            <p class="fs18 semibold m-0">05</p>
                                                        </Col>
                                                        <Col md="auto">
                                                            <h6 class="semibold m-0">Unit No.</h6>
                                                            <p class="fs18 semibold m-0">05</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="cardNumber mt-3">
                                                        <Col md="7">
                                                            <h6 class="semibold m-0">Address 2</h6>
                                                            <p class="fs18 semibold m-0">Marina Bay Sands</p>
                                                        </Col>
                                                        <Col md="4">
                                                            <h6 class="semibold m-0">Postal Code</h6>
                                                            <p class="fs18 semibold m-0">018956</p>
                                                        </Col>
                                                        <Col md="auto">
                                                            <h6 class="semibold m-0">&nbsp;</h6>
                                                            <p class="fs18 semibold m-0">&nbsp;</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm md={3}>
                                    <Col className="cartBox colTotal">
                                        <Col as="div" className="cartHeader rightCol">
                                            <Form className="ColPromo position-relative">
                                                <Form.Group controlId="formBasicNumber">
                                                    <Form.Control className="shadow-none" type="number" placeholder="Promo Code" />
                                                </Form.Group>                                                
                                                <Button variant="primary" type="submit">Apply</Button>
                                            </Form>
                                        </Col>
                                        <Col as="div" className="cartMid rightCol bg-white">
                                            <div className="card py-2">
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Total Item          <span className="float-end">5</span></li>
                                                    <li className="list-group-item pt-0 mt-0 pb-0 mb-0">Total Price         <span className="float-end">S$221.71</span></li>
                                                    <li className="list-group-item ps-4 ms-2 fs14">Discount  <span className="float-end textGreen">(S$10.00)</span></li>
                                                    <li className="list-group-item filterCol ps-4 ms-2 fs14">Promo Code<span className="float-end textGreen">(S$20.00)</span></li>
                                                    <li className="list-group-item filterCol ps-4 ms-2"><span className="badge rounded-pill bgLightGreen mt-2">DEC20OFF <IoCloseCircle role="button" size="13" className="align-top" color="var(--bs-greenDark)" /></span></li>
                                                    <li className="list-group-item">Takeaway Charges    <span className="float-end">S$1.40</span></li>
                                                    <li className="list-group-item pt-0">Delivery Charges    <span className="float-end">S$0.00</span></li>
                                                    <li className="list-group-item pt-0">Tax Inclusive       <span className="float-end">S$15.45</span></li>
                                                    <li className="list-group-item GrandTotal">Grand Total       <span className="float-end">S$208.56</span></li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Col>
                                    <Col as="div" className="cartCheckout">
                                        <Form className="position-relative">
                                            <Button variant="primary" type="submit">Checkout - S$208.56</Button>
                                        </Form>
                                    </Col>
                                </Col>
                            </Row>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
                <br /><br /><br />
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default CartCheckout