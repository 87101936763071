import React from 'react'
import { Container, Row, Col, Breadcrumb, Button, Tab, Tabs, Badge } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./stylenew.css";
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { MdOutlineFileDownload, MdRemoveRedEye } from 'react-icons/md';
import { TbVolumeOff } from 'react-icons/tb';

const TicketDashboard = () => {    
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Help Centre</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Support</Breadcrumb.Item>
                            <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
                            <Breadcrumb.Item>Ticket Dashboard</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="outerspace pt-2">
                                <Col as="section" className="innerspace pt-4">
                                    <Row className="mx-md-4">
                                        <Col className="tabsElite ticketTabs p-lg-0">
                                            <Tabs
                                                defaultActiveKey="Open"
                                                id="justify-tab-example"
                                                className="col tabsThist" 
                                                justify>
                                                <Tab eventKey="Open" title="Open" className="pt-5 tabHome" tabClassName="fs28 d-inline wauto">                                                  
                                                <Scrollbars 
                                                    className="overflow-hidden"
                                                    autoHideTimeout={900}
                                                    autoHideDuration={200}
                                                    autoHeight
                                                    autoHeightMax={720}
                                                    thumbMinSize={30}                                                    
                                                    universal={true}>
                                                    <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                    <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                    <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                </svg>
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <Button variant="light" className="bgtheme text-white rounded-pill buttonNOfix p-0 mx-3">2</Button>
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/ticketnudas.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                    <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                    <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                </svg>
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <span className="eyeoffIcons"><TbVolumeOff size="22" color="var(--theme-color)" /></span>
                                                            <Button variant="light" className="bgtheme text-white rounded-pill buttonNOfix p-0 mx-3">2</Button>
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/couponticket.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                    <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                    <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                </svg>
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/userticket.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative lightred rounded-pill text-center">
                                                            <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width="35" className="img-fluid border100 border border-danger border border-2" alt="media01" />
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <span className="eyeoffIcons"><TbVolumeOff size="22" color="var(--theme-color)" /></span>
                                                            <Button variant="light" className="bgtheme text-white rounded-pill buttonNOfix p-0 mx-3">2</Button>
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                    <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                    <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                </svg>
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <span className="eyeoffIcons"><TbVolumeOff size="22" color="var(--theme-color)" /></span>
                                                            <Button variant="light" className="bgtheme text-white rounded-pill buttonNOfix p-0 mx-3">2</Button>
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width="35" className="img-fluid border100 border border-danger border border-2" alt="media01" />
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <Button variant="light" className="bgtheme text-white rounded-pill buttonNOfix p-0 mx-3">2</Button>
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                    <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                    <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                </svg>
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-0 pb-0 align-items-center justify-content-between tabDine mx-0">
                                                        <Col sm="auto">
                                                            <span className="d-inline-block rounded-pill rndBxcnr">
                                                                <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                            </span>
                                                        </Col>
                                                        <Col sm="10" lg="10" xl="10" xxl="6" className="mb-sm-3 my-xl-3">
                                                            <Row>
                                                                <Col className="col-12 mb-2">
                                                                    <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                        <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                    </h5>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                </Col>
                                                                <Col>
                                                                    <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                    <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="position-relative ps-5">
                                                            <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                    <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                    <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                </svg>
                                                            </span>
                                                            <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                Lorem ipsum dolor sit amet, conse...
                                                                <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                            </span>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                        </Col>
                                                    </Row>
                                                </Scrollbars>
                                                </Tab>
                                                <Tab eventKey="Resolved" title="Resolved" className="pb-4 pt-5 tabRewards" tabClassName="fs28 d-inline wauto">
                                                    <Scrollbars 
                                                        className="overflow-hidden"
                                                        autoHideTimeout={900}
                                                        autoHideDuration={200}
                                                        autoHeight
                                                        autoHeightMax={720}
                                                        thumbMinSize={30}                                                    
                                                        universal={true}>
                                                        <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                    </svg>
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/ticketnudas.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                    </svg>
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/couponticket.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                    </svg>
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/userticket.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative lightred rounded-pill text-center">
                                                                <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width="35" className="img-fluid border100 border border-danger border border-2" alt="media01" />
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                    </svg>
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage02.png"} width="35" className="img-fluid border100 border border-danger border border-2" alt="media01" />
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-center border-bottom pb-4 mb-4 tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                    </svg>
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-0 pb-0 align-items-center justify-content-between tabDine mx-0">
                                                            <Col sm="auto">
                                                                <span className="d-inline-block rounded-pill rndBxcnr">
                                                                    <img src={process.env.PUBLIC_URL+"assets/cartbag.svg"} className="cartbagIcon" alt="media01" />                                                                
                                                                </span>
                                                            </Col>
                                                            <Col xxl="6">
                                                                <Row>
                                                                    <Col className="col-12 mb-2">
                                                                        <h5 className="m-0 fs16 GrayComn semibold">Ticket Number: #HC17B34V81
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; 29 June, 2022  -  05:25 PM</span>
                                                                            <span className="gray82">&nbsp;&nbsp;|&nbsp; johndoe132@gmail.com</span>
                                                                        </h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Help Category</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Issue with an Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Subject</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">Mistake with my Order</Badge>
                                                                    </Col>
                                                                    <Col>
                                                                        <h5 className="m-0 fs22 GrayComn semibold">Order ID (If Any)</h5>
                                                                        <Badge className="p-0 fs20 semibold gray82" bg="light">A17725GN6CX</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="position-relative ps-5">
                                                                <span className="position-absolute commentrelative gray333 rounded-pill text-center">
                                                                    <svg width="25" height="22" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.10591 15.881C4.14796 15.3737 4.22273 14.8733 4.32553 14.3892C4.65495 12.8487 5.28342 11.4287 6.14552 10.187C7.57535 8.12766 9.64533 6.55475 12.0494 5.73471C12.0517 5.73239 12.0541 5.73239 12.0564 5.73239C12.1592 5.69533 12.2644 5.66058 12.3695 5.62815C13.7806 5.19033 15.2992 5.00733 16.8646 5.13473L17.9556 5.22276L20.0653 3.68692C20.4952 3.37419 20.5606 2.95259 20.1237 2.61669C15.9184 -0.642629 10.2084 -0.325268 5.63854 2.99892C-0.190583 7.24044 -2.08301 14.8872 2.75552 21.4267C4.40963 23.6598 6.43756 25.2049 8.6267 26.0921C5.59181 23.7409 3.76948 19.9696 4.10591 15.881Z" fill="white"/>
                                                                        <path d="M22.3292 11.4196C21.7474 10.7409 21.0792 10.178 20.355 9.73088C17.3271 7.8661 13.3039 8.05142 10.4443 10.4606C8.04254 12.4806 7.07063 15.5592 7.62901 18.427C7.86498 19.6525 8.38364 20.8408 9.19201 21.8925C9.28313 22.013 9.37892 22.1288 9.47704 22.2446C12.4862 25.7588 17.8177 26.1896 21.3619 23.2037C24.7169 20.3775 25.2846 15.4943 22.7847 12.0011C22.6422 11.8018 22.4904 11.6096 22.3292 11.4196V11.4196Z" fill="white"/>
                                                                        <path d="M27.2432 6.07607C27.2058 6.03901 27.1661 6.00194 27.1264 5.96488C26.1825 5.12862 24.8017 5.03827 23.7621 5.69385C23.5915 5.8004 23.4303 5.92781 23.2808 6.07607C22.1851 7.16251 22.1851 8.92305 23.2808 10.0072C23.6429 10.3662 24.0798 10.6072 24.5401 10.7276C25.0587 10.8643 25.6078 10.8527 26.1194 10.6882C26.5306 10.5585 26.9161 10.3315 27.2432 10.0072C28.3389 8.92305 28.3389 7.16251 27.2432 6.07607V6.07607Z" fill="white"/>
                                                                    </svg>
                                                                </span>
                                                                <span className="fs20 semibold GrayComn gray02 d-inline-block rounded-pill px-4 py-2 position-relative text-nowrap ms-3">
                                                                    Lorem ipsum dolor sit amet, conse...
                                                                    <span className="graycourderimg position-absolute"><img src={process.env.PUBLIC_URL+"assets/graycorder.png"} className="img-fluid" alt="media01" /></span>
                                                                </span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button variant="light" className="grayf2f border12 buttonfix px-0"><MdRemoveRedEye size="22" color="var(--theme-color)" /></Button>
                                                                <Button variant="light" className="bg-white p-0 mx-3"><MdOutlineFileDownload size="26" color="var(--theme-color)" /></Button>
                                                            </Col>
                                                        </Row>
                                                    </Scrollbars>
                                                </Tab>
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )    
}
export default TicketDashboard