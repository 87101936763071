import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { HiHome, HiCheckCircle } from "react-icons/hi";
import { Link } from 'react-router-dom';
import './loginpage.css';

const NewPassword = () => {
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <div>
            <Container className="userLogin" fluid>
                <Row className="dFlex justify-content-center">
                    <Col md={11} className="row justify-content-center midContainer">
                        <Col md={5}>
                            <Col as="div" className="logo_login my-5 py-md-5">
                                <Link to="/" className="loginLogo mb-5 d-block"><img src="./assets/loginLogo.png" alt='logo' /></Link>                            
                                <Col className="headingLogin">
                                    <Col as="div" className="heading mb-5">
                                        <h2 className="m-0 small text-black">
                                            Enjoy a cozy hot meal at your neighbour's crib.
                                        </h2>
                                        <p className="parra mt-4">
                                            Join us and be rewarded whenever you eat with us. 
                                            A good taste deserves more treats.
                                        </p>
                                    </Col>
                                    <Col as="div" className="appStoreIcons pt-1">
                                        <h2>Download App</h2>
                                        <div className="d-inline-block aapblock">
                                            <img src="./assets/googlePlay.png" className="img-fluid" alt='googlePayicon' />
                                            <img src="./assets/appStore.png" className="img-fluid" alt='appStoreicon' />
                                        </div>
                                    </Col>                                    
                                </Col>
                                <br /><br />
                                <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-5">
                                    <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                                </Link>                                
                            </Col>
                        </Col>
                        <Col md={6} className="pe-0">
                            <Row className="d-md-flex h-100">
                                <Col className="rightLog position-relative mt-5">
                                <br /><br />
                                    <section id="scalOut" className="backImage position-absolute scalImg ">
                                        <img src="./assets/imageBunch.png" className="img-fluid" alt='bgpic' />
                                    </section>
                                    <section id="signUpFlip" className="mobilePur m-auto cardBox">
                                        <div className="front">
                                            <div className='heading mb-4'>
                                                <h2 className='small lh-1 fs-normal mb-0'>New Password</h2>
                                                <p className="semibold">Please enter your new password.</p>
                                            </div>

                                            <Form>                                                
                                                <Form.Group controlId="formBasicPassword" label="New Password" className="mb-3 position-relative">
                                                    <Form.Control type="password" placeholder="New Password" />
                                                    <FiEye size="20" color="var(--bs-border)" className="showPass" />
                                                    <FiEyeOff size="20" color="var(--bs-border)" className="showPassOff" />
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword" label="Re-enter Password" className="mb-5 position-relative">
                                                    <Form.Control type="password" placeholder="Re-enter Password" />
                                                    <FiEye size="20" color="var(--bs-border)" className="showPass" />
                                                    <FiEyeOff size="20" color="var(--bs-border)" className="showPassOff" />
                                                </Form.Group>
                                                <Button onClick={handleShow} className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary">Reset Password</Button>
                                            </Form>                                            
                                        </div>                                        
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>                
            </Container> 
            
            
            <Modal 
            className="successfullyPop" centered
            aria-labelledby="contained-modal-title-vcenter"
            show={show} onHide={handleClose}>
                <Modal.Body className='text-center'>
                    <br /><br /><br />
                    <HiCheckCircle size="100" className='mb-3' color="var(--bs-white)" />
                    <p>Your password has been successfully reset.</p>
                    <br /><br /><br />
                </Modal.Body>                
            </Modal>

        </div>        
    )
}
export default NewPassword