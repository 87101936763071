import React from 'react'
import { Container, Row, Col, Button, Breadcrumb, Badge, Tabs, Tab } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";

import "react-pro-sidebar/dist/css/styles.css";
import "./dinerstyle.css"
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';


const TransactionHistory = () => {    
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Transaction</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account</Breadcrumb.Item>
                            <Breadcrumb.Item>Transaction</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section" className="ms-md-0">
                            <Col className="pt-3">                                
                                <Row className="tabsElite pt-2">
                                    <Col className="p-0">
                                        <Tabs
                                            defaultActiveKey="dining"
                                            id="justify-tab-example"
                                            className="col tabsThist"
                                            justify
                                            >
                                            <Tab eventKey="dining" title="Dining" className="pt-5 tabHome">
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom pb-4 mb-4">
                                                    <Col lg="12" className="mb-3">
                                                        <h5 className="m-0 fs16 GrayComn semibold">Transaction Number : #05821330450 <span className="gray82">&nbsp;|&nbsp; 170 Bencoolen Street #01-01, Singapore 189657</span></h5>                                                        
                                                    </Col>
                                                    <Col lg="12" className="tabDine">
                                                        <Row className="justify-content-between align-items-center">
                                                            <Col sm="auto" lg="auto" className="pe-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">City Home Cafe</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">29 June, 2022  &nbsp;|&nbsp;  05:25 PM</Badge>
                                                            </Col>
                                                            <Col className="ps-md-5">
                                                                <h5 className="m-0 pb-1 fs18 GrayComn semibold">Payment Method</h5>
                                                                <Badge className="p-0 fs16 semibold gray82" bg="light">Credit Card ****4404 </Badge>
                                                            </Col>
                                                            <Col sm="auto" lg="auto">
                                                                <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Amount</Badge>
                                                                <h4 className="m-0 fs30 themeColor GrayComn semibold lh-1">S$31.02</h4>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button className="fs18 semibold p-0 themeColor text-decoration-none" variant="link">View Invoice</Button>
                                                            </Col>
                                                        </Row>                                                        
                                                    </Col>
                                                </Row>
                                                
                                                
                                                <Col className="space110">&nbsp;</Col>
                                            </Tab>
                                            <Tab eventKey="hosting" title="Hosting" className="pb-4 pt-5 tabRewards">
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center text-center">
                                                    <Col className="fs80vw gray82">
                                                        Coming Soon
                                                    </Col>                                                    
                                                </Row>                                                
                                            </Tab>                                           
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-4">&nbsp;</Col>                        
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default TransactionHistory