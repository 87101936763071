import React, { useState } from 'react'
import { Container, Row, Col, Button, Breadcrumb, Badge, Tabs, Tab, ListGroup, Modal } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import { BsFillCircleFill } from 'react-icons/bs';
import "react-pro-sidebar/dist/css/styles.css";
import "./dinerstyle.css"
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Slider from "react-slick";
import Footer from '../footer';
import { FaCircle } from 'react-icons/fa';

const MemberShip = () => {    
    
    const currentTier = {
        dots: false,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: "180px",
        slidesToShow: 1.7,
        slidesToScroll: 1,
        easing: 'cubic',
        
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                    centerMode: false,
                    dots: false
                }
            },{
                breakpoint: 1270,
                settings: {
                    slidesToShow: 1.7,
                    centerPadding: "0px",
                    centerMode: true,
                    dots: false
                }
            }
        ]
    };

    //modalpop
    const [rwdShow, setrwdShow] = useState(false);
    const rwdleClose = () => setrwdShow(false);
    const rwdleShow = () => setrwdShow(true);

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-4 pe-5">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Membership</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account</Breadcrumb.Item>
                            <Breadcrumb.Item>Membership</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section" className="ms-md-4">
                            <Col className="pt-3">                                
                                <Col lg="12" className="cardhead text-center pb-4">
                                    <Badge pill bg="false" role="button" className="bgLightGreen ms-2">Current Tier</Badge>
                                </Col>
                                <Row className="cardsTier">
                                    <Col>
                                        <Slider className="featureProd" {...currentTier}>
                                            <div className="card border-0">
                                                <img src={process.env.PUBLIC_URL+"assets/midcard.png"} className="img-fluid" alt="paymentmethod" />
                                            </div>
                                            <div className="card border-0">
                                                <img src={process.env.PUBLIC_URL+"assets/midcard.png"} className="img-fluid" alt="paymentmethod" />
                                            </div>
                                            <div className="card border-0">
                                                <img src={process.env.PUBLIC_URL+"assets/midcard.png"} className="img-fluid" alt="paymentmethod" />
                                            </div>
                                            <div className="card border-0">
                                                <img src={process.env.PUBLIC_URL+"assets/midcard.png"} className="img-fluid" alt="paymentmethod" />
                                            </div>
                                        </Slider>
                                    </Col>
                                </Row>                                
                                <Row className="text-center currtPoint">
                                    <Col lg="1"></Col>
                                    <Col>
                                        <img src={process.env.PUBLIC_URL+"assets/walletPoints.png"} className="img-fluid" alt="paymentmethod" />
                                        <p className="mt-3 semibold">*Earn at least 1 Point to extend validity by 6 Months.</p>
                                    </Col>
                                    <Col lg="1"></Col>
                                    <Col>
                                        <img src={process.env.PUBLIC_URL+"assets/circlePoints.png"} className="img-fluid" alt="paymentmethod" />
                                        <p className="mt-3 semibold">Points will reset by end of 31st September 2022, your Membership Tier shall be based on your last achieved tier before the reset.</p>
                                    </Col>
                                    <Col lg="1"></Col>
                                </Row>
                                <Row className="tabsElite mt-5 shadowSM pt-2">
                                    <Col className="p-0">
                                        <Tabs
                                            defaultActiveKey="rewards"
                                            id="justify-tab-example"
                                            className="mb-0"
                                            justify>
                                            <Tab eventKey="home" title="Tier Benefits" className="pt-5 tabHome">
                                                <ListGroup horizontal className="justify-content-center">
                                                    <ListGroup.Item className="border-0 mb-md-5 p-0 me-md-5 text-center">
                                                        <h3 className="fs38 semibold text-uppercase GrayComn m-0 lh-1">Elite</h3>
                                                        <p className="semibold GrayComn">You are currently on Elite Tier and will enjoy the following benefits</p>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                <ListGroup horizontal className="justify-content-center flex-wrap">
                                                    <ListGroup.Item className="border-0 p-0 position-relative">
                                                        <img src={process.env.PUBLIC_URL+"assets/tierBenafit.png"} className="img-fluid" alt="paymentmethod" />
                                                        <p className="fs20 GrayComn semibold position-absolute textfit">Earn <span className="themeColor">3 Points</span> for every <span className="themeColor">S$1</span> spent.</p>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0 position-relative">
                                                        <img src={process.env.PUBLIC_URL+"assets/tierBenafit.png"} className="img-fluid" alt="paymentmethod" />
                                                        <p className="fs20 GrayComn semibold position-absolute textfit">Get exclusive invites for Premier Tier &amp; lower events.</p>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0 position-relative">
                                                        <img src={process.env.PUBLIC_URL+"assets/tierBenafit.png"} className="img-fluid" alt="paymentmethod" />
                                                        <p className="fs20 GrayComn semibold position-absolute textfit">Access to Premier Tier &amp; lower <span className="themeColor">exclusive promotions.</span></p>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0 position-relative">
                                                        <img src={process.env.PUBLIC_URL+"assets/tierBenafit.png"} className="img-fluid" alt="paymentmethod" />
                                                        <p className="fs20 GrayComn semibold position-absolute textfit">
                                                            Enjoy Premier Tier <span className="themeColor">special rates</span>, where applicable.
                                                        </p>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                <Col className="space110">&nbsp;</Col>
                                            </Tab>
                                            <Tab eventKey="rewards" title="Rewards" className="pb-4 pt-5 tabRewards">
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center border-bottom">
                                                    <Col md="auto" lg="auto" className="ps-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/logoBadge.png"} className="img-fluid" alt="paymentmethod" />
                                                    </Col>
                                                    <Col md="5" lg="5">
                                                        <h5 className="m-0 fs24 GrayComn semibold">Cribbly: S$5 Promo Code</h5>
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">Unlimited Redemption </Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">60 Days Validity</Badge>
                                                    </Col>
                                                    <Col>
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">500</h4>
                                                    </Col>
                                                    <Col md="auto" lg="auto">
                                                        <Button onClick={rwdleShow} variant="danger" className="rounded-pill py-1 fs15 semibold px-4">&nbsp;&nbsp;&nbsp;View&nbsp;&nbsp;&nbsp;</Button>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center border-bottom">
                                                    <Col lg="auto" className="ps-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/logoBadge.png"} className="img-fluid" alt="paymentmethod" />
                                                    </Col>
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs24 GrayComn semibold">Cribbly: S$5 Promo Code</h5>
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">Unlimited Redemption </Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">60 Days Validity</Badge>
                                                    </Col>
                                                    <Col>
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">500</h4>
                                                    </Col>
                                                    <Col lg="auto">
                                                        <Button onClick={rwdleShow} variant="danger" className="rounded-pill py-1 fs15 semibold px-4">&nbsp;&nbsp;&nbsp;View&nbsp;&nbsp;&nbsp;</Button>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center border-bottom">
                                                    <Col lg="auto" className="ps-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/logoBadge.png"} className="img-fluid" alt="paymentmethod" />
                                                    </Col>
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs24 GrayComn semibold">Cribbly: S$5 Promo Code</h5>
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">Unlimited Redemption </Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">60 Days Validity</Badge>
                                                    </Col>
                                                    <Col>
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">500</h4>
                                                    </Col>
                                                    <Col lg="auto">
                                                        <Button onClick={rwdleShow} variant="danger" className="rounded-pill py-1 fs15 semibold px-4">&nbsp;&nbsp;&nbsp;View&nbsp;&nbsp;&nbsp;</Button>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center">
                                                    <Col lg="auto" className="ps-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/logoBadge.png"} className="img-fluid" alt="paymentmethod" />
                                                    </Col>
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs24 GrayComn semibold">Cribbly: S$5 Promo Code</h5>
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">Unlimited Redemption </Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs16 semibold gray82" bg="light">60 Days Validity</Badge>
                                                    </Col>
                                                    <Col>
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">500</h4>
                                                    </Col>
                                                    <Col lg="auto">
                                                        <Button onClick={rwdleShow} variant="danger" className="rounded-pill py-1 fs15 semibold px-4">&nbsp;&nbsp;&nbsp;View&nbsp;&nbsp;&nbsp;</Button>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="cbblerTab" title="Cribbler" className="teaserBG tabCri" style={{height: "820px"}}>
                                                <Row className="m-0 text-center align-items-center h-100 justify-content-center">
                                                    <Col lg="5">
                                                        <h2 className="fs80vw GrayComn semibold">Coming Soon</h2>
                                                        <p className="fs18 GrayComn semibold lh-sm mb-0">Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                                        <Button href="#" variant="danger" className="rounded-pill py-2 fs20 semibold px-5 mt-5">Contact Us</Button>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="contact" title="History" className="pt-5 tabHistory">
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom">
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs18 GrayComn semibold">City Home Cafe</h5>
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Order ID : 5GA1772N6CX</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Transaction Number : #04505821330</Badge>
                                                        <br />
                                                        <Badge className="p-0 fs14 semibold gray82 mt-2" bg="light">25 June 2022</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">1:00 PM</Badge>

                                                    </Col>
                                                    <Col lg="auto">
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">40</h4>
                                                    </Col>                                                    
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom">
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs18 GrayComn semibold">Love Clip Cafe</h5>
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Order ID : 5GA1772N6CX</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Transaction Number : #04505821330</Badge>
                                                        <br />
                                                        <Badge className="p-0 fs14 semibold gray82 mt-2" bg="light">25 June 2022</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">1:00 PM</Badge>

                                                    </Col>
                                                    <Col lg="auto">
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">40</h4>
                                                    </Col>                                                    
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom">
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs18 GrayComn semibold">Max Coffee Crib</h5>
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Order ID : 5GA1772N6CX</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Transaction Number : #04505821330</Badge>
                                                        <br />
                                                        <Badge className="p-0 fs14 semibold gray82 mt-2" bg="light">25 June 2022</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">1:00 PM</Badge>

                                                    </Col>
                                                    <Col lg="auto">
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">40</h4>
                                                    </Col>                                                    
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between border-bottom">
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs18 GrayComn semibold">Brunch Bistro</h5>
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Order ID : 5GA1772N6CX</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Transaction Number : #04505821330</Badge>
                                                        <br />
                                                        <Badge className="p-0 fs14 semibold gray82 mt-2" bg="light">25 June 2022</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">1:00 PM</Badge>

                                                    </Col>
                                                    <Col lg="auto">
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">40</h4>
                                                    </Col>                                                    
                                                </Row>
                                                <Row className="mx-md-5 mb-3 pb-3 align-items-center justify-content-between">
                                                    <Col lg="5">
                                                        <h5 className="m-0 fs18 GrayComn semibold">Fire & Grill</h5>
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Order ID : 5GA1772N6CX</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">Transaction Number : #04505821330</Badge>
                                                        <br />
                                                        <Badge className="p-0 fs14 semibold gray82 mt-2" bg="light">25 June 2022</Badge>
                                                        <BsFillCircleFill size="7" color="var(--bs-999)" className="me1 mx-2" />
                                                        <Badge className="p-0 fs14 semibold gray82" bg="light">1:00 PM</Badge>
                                                    </Col>
                                                    <Col lg="auto">
                                                        <Badge className="p-0 fs12 semibold GrayComn" bg="light" text="dark">Redeem Amount</Badge>
                                                        <h4 className="m-0 fs30 themeColor semibold lh-1">40</h4>
                                                    </Col>                                                    
                                                </Row>
                                                
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-4">&nbsp;</Col>                        
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
            <Modal 
                size="xl"
                dialogClassName="addCardModal"
                contentClassName="addModalMember border12 border-0"
                backdropClassName="opacityXtra"
                show={rwdShow} 
                onHide={rwdleClose} 
                centered>
                <Modal.Header closeButton className="border-0 text-white closeC d-block position-relative p-0">
                    <img src={process.env.PUBLIC_URL+"assets/membership-popup-banner.png"} className="img-fluid position-relative" alt="paymentmethod" />
                    <svg className="position-absolute" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white"/>
                    </svg>
                </Modal.Header>
                <Modal.Body className="p-lg-5">
                    <h2 className="fs52 GrayComn semibold lh-1">Cribbly: S$10 Promo Code</h2>
                    <p className="GrayComn semibold lh-1">Deserunt amet minim mollit no ullamco est sit aliqua dolor non deserunt aliqua dolor do amet sintduis.</p>

                    <ListGroup as="ul" horizontal className="justify-content-center justify-content-lg-between text-center mt-5 flex-wrap">
                        <ListGroup.Item as="li" className="border-0 p-0 m-3 m-lg-0">
                            <Col as="span" className="lightred themeColor semibold lh-sm px-3 py-1 rounded-pill d-inline text-nowrap">Account Limit</Col>
                            <Col as="div" className="fs26 GrayComn semibold mt-2">2 Redemptions</Col>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 m-3 m-lg-0">
                            <Col as="span" className="lightred themeColor semibold lh-sm px-3 py-1 rounded-pill d-inline text-nowrap">Validity Period</Col>
                            <Col as="div" className="fs26 GrayComn semibold mt-2">60 Days</Col>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0">
                            <Col as="span" className="lightred themeColor semibold lh-sm px-3 py-1 rounded-pill d-inline text-nowrap">Point Redemption</Col>
                            <Col as="div" className="fs26 GrayComn semibold mt-2">500 Points</Col>
                        </ListGroup.Item>
                    </ListGroup>


                    <ListGroup as="ul" className="justify-content-between mt-4 pt-4 border-top">
                        <Col as="h2" className="fs20 gray82 semibold mb-4 w-100 lh-lg">Terms & Conditions</Col>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Deserunt amet minim mollit no ullamco est sit aliqua eserunt ullamco</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Amet minim mollit no ullamco est sit aliqua dolor enim Exercitation veniam.</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Mollit no ullamco est sit Exercitation sintduis.</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Aliqua dolor do amet sintduis enim Exercitation veniam.</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Veniam Aliqua Amet minim mollit no ullamco aliqua dolor do exercitation.</ListGroup.Item>
                    </ListGroup>
                    
                    <ListGroup as="ul" className="justify-content-between mt-2 pt-4 border-top">
                        <Col as="h2" className="fs20 gray82 semibold mb-4 w-100 lh-lg">How to Use the Promo code?</Col>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Minim mollit no ullamco est sit aliqua eserunt.</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Aliqua dolor do amet sintduis.</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Mollit no ullamco est sit aliqua  tduis minim mollit no ullamco est sit aliqum.</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Aliqua dolor do amet sintduis enim Exercitation veniam.</ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 p-0 fs20 GrayComn semibold lh-sm mb-4"><FaCircle color="var(--bs-grayDark)" size="8" className="me-2" /> Aliqua Amet minim mollit no ullamco est sit aliqua dolor do exercitation veniam.</ListGroup.Item>
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer className="pb-4 mb-5 nofifyButton d-block text-center border-0">
                    <Button href="#" variant="danger" className="rounded-pill py-2 fs26 px-5">Redeem Now</Button>                    
                </Modal.Footer>
            </Modal>
        </div>
    )    
}
export default MemberShip