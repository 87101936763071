import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { HiHome } from "react-icons/hi";
import { BsClock } from "react-icons/bs";
import { Link } from 'react-router-dom';
import './loginpage.css';

const ForgotPassTwo = () => {

    return (
        <div>
            <Container className="userLogin" fluid>
                <Row className="dFlex justify-content-center">
                    <Col md={11} className="row justify-content-center midContainer">
                        <Col md={5}>
                            <Col as="div" className="logo_login my-5 py-md-5">
                                <Link to="/" className="loginLogo mb-5 d-block"><img src="./assets/loginLogo.png" alt="logo" /></Link>                            
                                <Col className="headingLogin">
                                    <Col as="div" className="heading mb-5">
                                        <h2 className="m-0 small text-black">
                                            Enjoy a cozy hot meal at your neighbour's crib.
                                        </h2>
                                        <p className="parra mt-4">
                                            Join us and be rewarded whenever you eat with us. 
                                            A good taste deserves more treats.
                                        </p>
                                    </Col>
                                    <Col as="div" className="appStoreIcons pt-1">
                                        <h2>Download App</h2>
                                        <div className="d-inline-block aapblock">
                                            <img src="./assets/googlePlay.png" className="img-fluid"   alt='googlePayicon' />
                                            <img src="./assets/appStore.png" className="img-fluid"     alt='appStoreicon' />
                                        </div>
                                    </Col>                                    
                                </Col>
                                <br /><br />
                                <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-5">
                                    <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                                </Link>                                
                            </Col>
                        </Col>
                        <Col md={6} className="pe-0">
                            <Row className="d-md-flex h-100">
                                <Col className="rightLog position-relative mt-5">
                                <br /><br />
                                    <section id="scalOut" className="backImage position-absolute scalImg ">
                                        <img src="./assets/imageBunch.png" className="img-fluid" alt="bgpic" />
                                    </section>
                                    <section id="signUpFlip" className="mobilePur m-auto cardBox">
                                        <div className="front">
                                            <div className='heading mb-4'>
                                                <h2 className='small lh-1 fs-normal mb-0'>User Verification</h2>
                                                <p className="semibold">A 6-Digit Code has been send your number +61 XXXX X567</p>
                                            </div>
                                            <Form>                                                
                                                <Form.Group controlId="formBasicName" className="mb-3 pb-1 row otpScreem">
                                                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                                                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                                                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                                                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                                                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                                                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                                                </Form.Group>
                                                <Form.Group className="mt-4 mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label className='float-start'><Link to="#" className="forgotPass text-decoration-none">Resend?</Link></Form.Label>                                        
                                                    <Form.Label className='float-end'><BsClock size="20" /> <b>00.25</b></Form.Label>                                        
                                                </Form.Group>                                                
                                                <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary" type="submit">Continue</Button>
                                            </Form>                                            
                                        </div>                                        
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container> 
        </div>        
    )
}
export default ForgotPassTwo