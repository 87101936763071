import { useState } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { FiChevronDown, FiCamera } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import { TiPlus } from "react-icons/ti";
import Slider from "react-slick";
import "react-pro-sidebar/dist/css/styles.css";
import "./hoststep.css";
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';
import { Link } from 'react-router-dom';

const HostCribThree = () => {
    const settings = {
        dots: true,
        infinite: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        easing: 'cubic',
        
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }            
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    const [alpha, setAlpha] = useState();
    const [alpha01, setAlpha01] = useState();    

    const getCheckedValue = (e) => {
        switch (e.target.name) {
            case "case01":
                e.target.checked ? setAlpha("On") : setAlpha("Off")
                break;
            case "case02":
                e.target.checked ? setAlpha01("On") : setAlpha01("Off")
                break;
            default:
        }
    }


    const [formValues, setFormValues] = useState([{ name: "", text : ""}])

    let handleSubmit = (event) => {
        event.preventDefault();
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }
    let addFormFields = () => {
        setFormValues([...formValues, { name: "", text: "" }])        
    }
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);        
    }


    const [formValues1, setFormValues1] = useState([{ name: "", text : ""}])

    let handleChange1 = (i, e) => {
        let newFormValues1 = [...formValues1];
        newFormValues1[i][e.target.name] = e.target.value;
        setFormValues1(newFormValues1);
    }
    let addFormFields1 = () => {
        setFormValues1([...formValues1, { name: "", text: "" }])        
    }
    let removeFormFields1 = (i) => {
        let newFormValues1 = [...formValues1];
        newFormValues1.splice(i, 1);
        setFormValues1(newFormValues1);        
    }

    const [formValues2, setFormValues2] = useState([{ name: "", text : ""}])
    let handleChange2 = (i, e) => {
        let newFormValues2 = [...formValues2];
        newFormValues2[i][e.target.name] = e.target.value;
        setFormValues2(newFormValues2);
    }
    let addFormFields2 = () => {
        setFormValues2([...formValues2, { name: "", text: "" }])        
    }
    let removeFormFields2 = (i) => {
        let newFormValues2 = [...formValues2];
        newFormValues2.splice(i, 1);
        setFormValues2(newFormValues2);        
    }

    const [formValues3, setFormValues3] = useState([{ name: "", text : ""}])
    let handleChange3 = (i, e) => {
        let newFormValues3 = [...formValues3];
        newFormValues3[i][e.target.name] = e.target.value;
        setFormValues3(newFormValues3);
    }
    let addFormFields3 = () => {
        setFormValues3([...formValues2, { name: "", text: "" }])        
    }
    let removeFormFields3 = (i) => {
        let newFormValues3 = [...formValues3];
        newFormValues3.splice(i, 1);
        setFormValues3(newFormValues3);        
    }

    const [formValues4, setFormValues4] = useState([{ name: "", text : ""}])
    let handleChange4 = (i, e) => {
        let newFormValues4 = [...formValues4];
        newFormValues4[i][e.target.name] = e.target.value;
        setFormValues4(newFormValues4);
    }
    let addFormFields4 = () => {
        setFormValues4([...formValues4, { name: "", text: "" }])        
    }
    let removeFormFields4 = (i) => {
        let newFormValues4 = [...formValues4];
        newFormValues4.splice(i, 1);
        setFormValues4(newFormValues4);        
    }

    const [formValues5, setFormValues5] = useState([{ name: "", text : ""}])
    let handleChange5 = (i, e) => {
        let newFormValues5 = [...formValues5];
        newFormValues5[i][e.target.name] = e.target.value;
        setFormValues5(newFormValues5);
    }
    let addFormFields5 = () => {
        setFormValues5([...formValues5, { name: "", text: "" }])        
    }
    let removeFormFields5 = (i) => {
        let newFormValues5 = [...formValues5];
        newFormValues5.splice(i, 1);
        setFormValues5(newFormValues5);        
    }

    const [formValues6, setFormValues6] = useState([{ name: "", text : ""}])
    let handleChange6 = (i, e) => {
        let newFormValues6 = [...formValues6];
        newFormValues6[i][e.target.name] = e.target.value;
        setFormValues6(newFormValues6);
    }
    let addFormFields6 = () => {
        setFormValues6([...formValues6, { name: "", text: "" }])        
    }
    let removeFormFields6 = (i) => {
        let newFormValues6 = [...formValues6];
        newFormValues6.splice(i, 1);
        setFormValues6(newFormValues6);        
    }


    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9">
                    <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-5 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <Col md="auto" className='d-flex text-center flex-column flex-md-row text-md-start col-md-auto'>
                                        <span className="user-profile-circle me-md-3 mx-auto rounded-pill border border-2 border-danger p-1 d-inline-block position-relative">
                                            <img src="./assets/black_scoop.png" className='rounded-pill border-2' alt="food" />
                                            <Link to=" " className="upload-user-profile rounded-pill bgLightGray shadowMD position-absolute text-center pt-1">
                                                <FiCamera size="21" className="themeColor"/>
                                            </Link>
                                        </span>
                                        <Col className="itemDetails">
                                            <Col as="div" className="itemHead mb-2">
                                                <h4 className="d-inline-block align-middle GrayDark fs46 semibold">City Home Cafe</h4>
                                            </Col>
                                            <Col as="p" className="d-inline-flex border04 grayf7f py-2 px-3 shadowMD align-items-center semibold align-middle">
                                                <Col className='border-end pe-3 me-3'>
                                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.0013 26.0947L15.8379 14.4258H24.1647L20.0013 26.0947Z" fill="#6992F2"/>
                                                    <path d="M0 14.3711L19.3602 31.4961L13.5297 14.4017L0 14.3711Z" fill="#3169ED"/>
                                                    <path d="M12.8381 12.0727L0.0273438 12.0743L6.42691 0.878906L12.8381 12.0727Z" fill="#4377EF"/>
                                                    <path d="M39.9998 14.3867L20.6396 31.5117L26.4701 14.4173L39.9998 14.3867Z" fill="#7CA0F4"/>
                                                    <path d="M27.1631 12.0845L39.9738 12.086L33.5859 0.890625L27.1631 12.0845Z" fill="#A1BBF7"/>
                                                    <path d="M24.2077 11.9639L15.8809 11.9649L20.0559 4.72656L24.2077 11.9639Z" fill="#8EADF6"/>
                                                    <path d="M19.5222 0.00155315L8.61328 0L14.0628 9.53337L19.5222 0.00155315Z" fill="#5684F0"/>
                                                    <path d="M20.5225 0.00155315L31.4314 0L25.9918 9.53337L20.5225 0.00155315Z" fill="#7CA0F4"/>
                                                    </svg>
                                                </Col>    
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6758 0.928603C12.0926 -0.309534 13.9074 -0.309534 14.3242 0.928603L16.4386 7.20961C16.6249 7.76332 17.1596 8.13821 17.7627 8.13821H24.605C25.9538 8.13821 26.5146 9.80412 25.4234 10.5693L19.8878 14.4512C19.3998 14.7934 19.1957 15.4 19.3821 15.9537L21.4965 22.2348C21.9132 23.4729 20.445 24.5025 19.3539 23.7372L13.8184 19.8554C13.3304 19.5131 12.6696 19.5131 12.1816 19.8554L6.64609 23.7372C5.55492 24.5025 4.08674 23.4729 4.50353 22.2348L6.61791 15.9537C6.8043 15.4 6.6001 14.7934 6.11212 14.4512L0.57661 10.5693C-0.514582 9.80412 0.0462183 8.13821 1.39499 8.13821H8.23727C8.84045 8.13821 9.37505 7.76332 9.56144 7.20961L11.6758 0.928603Z" fill="#EF9309"/>
                                                    </svg>
                                                <Col as="span" className='col-auto px-2 lh-1 align-middle fs26 GrayComn semibold'>
                                                    4.5
                                                </Col>
                                                <Link to="/" className='d-inline-block themeColor ps-3 semibold fs20 text-decoration-none'>See Reviews (55)</Link>
                                            </Col>
                                        </Col>
                                    </Col>
                                    <Col md="auto" className='text-md-end py-md-4 mt-2 text-center'>
                                        <Button variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 ">
                                        <svg className='align-text-bottom me-2' width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.75 21.1C17.3713 21.1 17.9683 20.8649 18.4097 20.4448C18.8511 20.025 19.1 19.4539 19.1 18.8572V7.20653C19.1 6.73443 18.9441 6.27474 18.6544 5.89324C18.1899 5.27873 17.2367 4.019 16.4389 2.96458C16.1118 2.53225 15.8108 2.13444 15.5803 1.82977L15.5802 1.82972C15.1843 1.30715 14.5757 0.975784 13.9123 0.911567L13.9244 0.9H13.6751H3.25005C1.95668 0.9 0.9 1.89957 0.9 3.14292V18.8572C0.9 20.1006 1.95674 21.1 3.25005 21.1H16.75ZM3.89696 2.52857L3.89703 4.5716C3.89703 5.81479 4.95372 6.81436 6.24692 6.81436H10.747C12.0402 6.81436 13.0969 5.81483 13.0969 4.5716V2.52869H13.675C13.8862 2.52869 14.082 2.62557 14.2035 2.78555L17.2784 6.84904L17.2785 6.84915C17.3577 6.95342 17.3999 7.07868 17.3999 7.20649V18.8572C17.3999 19.0182 17.3321 19.1745 17.2112 19.2897L17.2802 19.3621L17.2112 19.2897C17.0892 19.4059 16.9237 19.4714 16.7499 19.4714H3.24997C2.88575 19.4714 2.59991 19.1918 2.59991 18.8572V3.14292C2.59991 2.80831 2.88576 2.52857 3.24997 2.52857H3.89696ZM5.59704 2.52857H11.3972V4.57149C11.3972 4.90589 11.1106 5.18568 10.7473 5.18568H6.24717C5.88387 5.18568 5.59728 4.90587 5.59728 4.57149V4.57148L5.59704 2.52857ZM16.0968 13.143C16.0968 11.8996 15.0401 10.9 13.7469 10.9H6.24676C4.95355 10.9 3.89687 11.8996 3.89687 13.143V16.0001C3.89687 17.2435 4.95361 18.2429 6.24676 18.2429H13.7469C15.0401 18.2429 16.0968 17.2434 16.0968 16.0001V13.143ZM14.3968 13.143V16.0001C14.3968 16.3346 14.1102 16.6143 13.7469 16.6143H6.24676C5.88344 16.6143 5.59687 16.3346 5.59687 16.0001V13.143C5.59687 12.8084 5.88349 12.5286 6.24676 12.5286H13.7469C14.1102 12.5286 14.3968 12.8084 14.3968 13.143Z" fill="white" stroke="white" stroke-width="0.2"/>
                                        </svg>
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col as="section" className='ms-0 ms-xl-5 ps-xl-5 mb-5 ps-0 '>
                            <Col className='container px-xl-5 px-lg-4 px-0'>
                                <Form className="accForm px-lg-2 px-xl-0" onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md="6" xl="5" xxl="6">
                                            <Row className="align-items-end">
                                                <Col>
                                                    <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Name</Col>
                                                    <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Name of Outlet</Form.Label>
                                                        <Form.Control type="text" placeholder="Bayfront" />
                                                        <Form.Label className='char-limit'>8 / 20 Characters</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="auto" className='fs16 semibold GrayComn pb-4 px-2 mx-md-0'>
                                                    Crib
                                                </Col>
                                            </Row>                                            
                                        </Col>
                                        
                                        <Col md="6" lg="6" xl="4" xxl="4">
                                            <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Crib Status</Col>
                                            <Form.Group className="approve-field position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Current Status</Form.Label>
                                                <Form.Control  className='bgLightGray textGreen' type="text" placeholder="Approved and Published" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="12" lg="12" xl="3" xxl="2" className='pt-4 pt-xl-0 pb-0'>
                                            <Row className='flex-xl-column row-cols-auto text-end'>
                                                <Col md="auto">
                                                    <Button variant="light" className="w-100 text-nowrap rounded-pill bgLightGray py-1 px-4 mb-3 GrayComn border-0 fs20">Suspend Crib</Button>
                                                </Col>
                                                <Col md="auto">
                                                    <Button variant="light" className="w-100 text-nowrap rounded-pill bgtheme py-1 px-4 text-white border-0 fs20 ">Delete Crib</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4 graye0e opacity-100"></Col>
                                    <Row>
                                        <Col md="6"  className='mb-4'>
                                            <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Address</Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Street Address</Form.Label>
                                                <Form.Control type="text" placeholder="10 Bayfront Ave" />
                                            </Form.Group>                        
                                        </Col>
                                        
                                        <Col md="6" className='mb-4'>
                                            <Col as="p" className='fs18 semibold textGreen text-end mb-3 lh-sm'>
                                                <svg className='me-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L7.70711 9.29289ZM9 12L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L9 12ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L13.7071 8.70711ZM6.29289 10.7071L8.29289 12.7071L9.70711 11.2929L7.70711 9.29289L6.29289 10.7071ZM9.70711 12.7071L13.7071 8.70711L12.2929 7.29289L8.29289 11.2929L9.70711 12.7071ZM18 10C18 14.4183 14.4183 18 10 18V20C15.5229 20 20 15.5229 20 10H18ZM10 18C5.58172 18 2 14.4183 2 10H0C0 15.5229 4.47716 20 10 20V18ZM2 10C2 5.58172 5.58172 2 10 2V0C4.47716 0 0 4.47716 0 10H2ZM10 2C14.4183 2 18 5.58172 18 10H20C20 4.47716 15.5229 0 10 0V2Z" fill="#00676B"/>
                                                </svg>
                                                Verified
                                                </Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Building Name</Form.Label>
                                                <Form.Control  type="text" placeholder="-" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" md="2" lg="3" xxl="2"  className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Floor Number</Form.Label>
                                                <Form.Control type="text" placeholder="01" />
                                            </Form.Group>                        
                                        </Col>
                                        
                                        <Col xs="6" md="2" lg="3" xxl="2" className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Unit Number</Form.Label>
                                                <Form.Control  type="text" placeholder="01" />
                                            </Form.Group>
                                        </Col>
                                        <Col className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control  type="text" placeholder="Singapore" />
                                            </Form.Group>
                                        </Col>
                                        <Col className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Postal Code</Form.Label>
                                                <Form.Control  type="text" placeholder="018956" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4  mt-2 graye0e opacity-100"></Col>
                                    <Col>
                                        <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Operating Hours</Col>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Sunday</Col>                       
                                            </Col>
                                            <Col md="10" className="add-field-section">
                                                <Row>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="2:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {formValues.map((element, index) => (
                                                        <Col md="auto" key={index}>
                                                            <Row className="position-relative" >
                                                                <Col md="auto" className='my-md-3 mb-4 mb-md-0 pt-1 d-flex border-sapertor-line align-items-start'>
                                                                    <Col as="span" className='border-end py-3'></Col>
                                                                </Col>
                                                                <Col xs={6} md="auto" className='mb-4'>
                                                                    <Form.Group value={element.name || ""} onChange={e => handleChange(index, e)} className="position-relative mxwidth150"  controlId="exampleForm.ControlInput1">
                                                                        <Form.Label>Opening Time 1</Form.Label>
                                                                        <Form.Control  type="text" placeholder="9:00 AM" />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={6} md="auto" className='mb-4'>
                                                                    <Form.Group value={element.text || ""} onChange={e => handleChange(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                        <Form.Label>Opening Time 1</Form.Label>
                                                                        <Form.Control  type="text" placeholder="2:00 PM" />
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                index ?
                                                                    <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeFormFields(index)} />                                                
                                                                : null
                                                                }
                                                            </Row>
                                                        </Col>
                                                    ))}
                                                    <Col md="auto" className="d-flex pb-4">
                                                        <Col as="button" onClick={() => addFormFields()} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                            <TiPlus size={18} className="me-2"/>Add Time Slot 3
                                                        </Col>
                                                    </Col> 
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Monday</Col>                       
                                            </Col>
                                            <Col md="10" className="add-field-section">
                                                <Row>
                                                {formValues2.map((element, index) => (
                                                    <Col md="auto" key={index}>
                                                        <Row className="position-relative">
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.name || ""} onChange={e => handleChange2(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.email || ""} onChange={e => handleChange2(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="10:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                            index ?
                                                                <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeFormFields2(index)} />                                                
                                                            : null
                                                            }
                                                        </Row>
                                                    </Col>
                                                ))}
                                                 <Col md="auto" className="d-flex pb-4">
                                                    <Col as="button" onClick={() => addFormFields2()} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                        <TiPlus size={18} className="me-2"/>Add Time Slot 2
                                                    </Col>
                                                </Col> 
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Tuesday</Col>                       
                                            </Col>
                                            <Col md="10" className="add-field-section">
                                                <Row>
                                                {formValues3.map((element, index) => (
                                                    <Col md="auto" key={index}>
                                                        <Row className="position-relative">
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.name || ""} onChange={e => handleChange3(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.email || ""} onChange={e => handleChange3(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="10:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                            index ?
                                                                <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeFormFields3(index)} />                                                
                                                            : null
                                                            }
                                                        </Row>
                                                    </Col>
                                                ))}
                                                 <Col md="auto" className="d-flex pb-4">
                                                    <Col as="button" onClick={() => addFormFields3()} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                        <TiPlus size={18} className="me-2"/>Add Time Slot 2
                                                    </Col>
                                                </Col> 
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Wednesday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  type="text" placeholder="-:-- AM / PM " />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  type="text" placeholder="-:-- AM / PM " />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Thursday</Col>                       
                                            </Col>
                                            <Col md="10" className="add-field-section">
                                                <Row>
                                                {formValues4.map((element, index) => (
                                                    <Col md="auto" key={index}>
                                                        <Row className="position-relative">
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.name || ""} onChange={e => handleChange4(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.email || ""} onChange={e => handleChange4(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="10:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                            index ?
                                                                <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeFormFields4(index)} />                                                
                                                            : null
                                                            }
                                                        </Row>
                                                    </Col>
                                                ))}
                                                 <Col md="auto" className="d-flex pb-4">
                                                    <Col as="button" onClick={() => addFormFields4()} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                        <TiPlus size={18} className="me-2"/>Add Time Slot 2
                                                    </Col>
                                                </Col> 
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Friday</Col>                       
                                            </Col>
                                            <Col md="10" className="add-field-section">
                                                <Row>
                                                {formValues5.map((element, index) => (
                                                    <Col md="auto" key={index}>
                                                        <Row className="position-relative">
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.name || ""} onChange={e => handleChange5(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.email || ""} onChange={e => handleChange5(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="10:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                            index ?
                                                                <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeFormFields5(index)} />                                                
                                                            : null
                                                            }
                                                        </Row>
                                                    </Col>
                                                ))}
                                                 <Col md="auto" className="d-flex pb-4">
                                                    <Col as="button" onClick={() => addFormFields5()} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                        <TiPlus size={18} className="me-2"/>Add Time Slot 2
                                                    </Col>
                                                </Col> 
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Saturday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="2:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                        <Col as="span" className='border-end py-3'></Col>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 2</Form.Label>
                                                                    <Form.Control  type="text" placeholder="4:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Closing Time 2</Form.Label>
                                                                    <Form.Control  type="text" placeholder="7:30 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                        <Col as="span" className='border-end py-3'></Col>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 2</Form.Label>
                                                                    <Form.Control  type="text" placeholder="4:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Closing Time 2</Form.Label>
                                                                    <Form.Control  type="text" placeholder="7:30 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Eve Of Public Holiday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="text" placeholder="Normal Hours" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="text" placeholder="Normal Hours" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Col as="section" className="mb-4 publick-holiday">
                                            <Row className="profilecheckbox">
                                                <Col md="12" className="main-col">
                                                    <Col className="form-check position-relative">
                                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="mypro01" />
                                                        <label className="form-check-label top-0 fs16 GrayComn semibold text-truncate overflow-hidden" for="mypro01"><span>Same as Normal Operating Hours?</span></label>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Row >
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Public Holiday</Col>                       
                                            </Col>
                                            <Col md="10" className="add-field-section">
                                                <Row>
                                                {formValues6.map((element, index) => (
                                                    <Col md="auto" key={index}>
                                                        <Row className="position-relative">
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.name || ""} onChange={e => handleChange6(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group value={element.email || ""} onChange={e => handleChange6(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  type="text" placeholder="10:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                            index ?
                                                                <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeFormFields6(index)} />                                                
                                                            : null
                                                            }
                                                        </Row>
                                                    </Col>
                                                ))}
                                                 <Col md="auto" className="d-flex pb-4">
                                                    <Col as="button" onClick={() => addFormFields6()} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                        <TiPlus size={18} className="me-2"/>Add Time Slot 2
                                                    </Col>
                                                </Col> 
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Col as="section" className="mb-4 publick-holiday">
                                            <Row className="profilecheckbox">
                                                <Col md="12" className="main-col">
                                                    <Col className="form-check position-relative">
                                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="mypro02" />
                                                        <label className="form-check-label top-0 fs16 GrayComn semibold text-truncate overflow-hidden" for="mypro02"><span>Same as Normal Operating Hours?</span></label>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                    <Col as="hr" className="my-4 mt-2 graye0e opacity-100"></Col>
                                    <Row>
                                        <Col md="12" className='mb-4'>
                                            <Col className='d-flex justify-content-between align-items-center mb-4 collection-switch'>
                                                <Col as="p" className='fs18 semibold GrayDark text-start mb-0 lh-sm'>Pickup Collections</Col>
                                                <Form.Check 
                                                    className="togglerBtn fs18 semibold GrayComn text-uppercase float-end togglerSize"
                                                    type="switch"
                                                    id="custom-switch01"
                                                    label={alpha ? alpha: "Off"}
                                                    name="case01"
                                                    onChange={getCheckedValue}
                                                />
                                            </Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Collection Instructions</Form.Label>
                                                <Form.Control type="text" placeholder="Please collect your order at ground floor lift lobby. Block 10, Lobby A." />
                                                <Form.Label className='char-limit'>72 / 150 Characters</Form.Label>
                                            </Form.Group>                        
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4 mt-2 graye0e opacity-100"></Col>
                                    <Row>
                                        <Col md="12" className='mb-4'>
                                            <Col className='d-flex justify-content-between align-items-center mb-4 collection-switch'>
                                                <Col as="p" className='fs18 semibold GrayDark text-start mb-0 lh-sm'>Dine In Collections</Col>
                                                <Form.Check 
                                                    className="togglerBtn fs18 semibold GrayComn text-uppercase float-end togglerSize"
                                                    type="switch"
                                                    id="custom-switch02"
                                                    label={alpha01 ? alpha01: "Off"}
                                                    name="case02"
                                                    onChange={getCheckedValue}
                                                />
                                            </Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Collection Instructions</Form.Label>
                                                <Form.Control type="text" placeholder="Please ring the door bell and provide your Order ID when you arrive at the gate." />
                                                <Form.Label className='char-limit'>80 / 150 Characters</Form.Label>
                                            </Form.Group>                        
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col md="auto">
                                                    <Row>
                                                        <Col md="auto" className='mb-4'>
                                                            <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Table Number</Form.Label>
                                                                <Form.Control  className='bgLightGray' type="text" placeholder="01" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                            <Col as="span" className='border-end py-3'></Col>
                                                        </Col>
                                                        <Col md="auto" className='mb-4'>
                                                            <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Number of Seats</Form.Label>
                                                                <Form.Control  type="text" placeholder="08" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            
                                                <Row>
                                                    <Col md="auto">
                                                    {formValues1.map((element, index) => (
                                                        <Row className="position-relative">
                                                            <Col md="auto" className='mb-4' key={index}>
                                                                <Form.Group value={element.name || ""} onChange={e => handleChange1(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Table Number</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="text" placeholder="02" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                                <Col as="span" className='border-end py-3'></Col>
                                                            </Col>
                                                            <Col md="auto" className='mb-4'>
                                                                <Form.Group value={element.email || ""} onChange={e => handleChange1(index, e)} className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Number of Seats</Form.Label>
                                                                    <Form.Control  type="text" placeholder="06" />
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                            index ?
                                                                <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeFormFields1(index)} />                                                
                                                            : null
                                                            }
                                                        </Row>
                                                         ))}
                                                         <Col as="button" onClick={() => addFormFields1()} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                            <TiPlus size={18} className="me-2"/>Add Table
                                                        </Col>
                                                    </Col>
                                                </Row>
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4 graye0e opacity-100"></Col>
                                </Form>
                                <Col>
                                    <Col as="p" className='fs18 semibold GrayDark text-start mb-3 mb-md-5 lh-sm'>Delivery Collections</Col>
                                    <Col className='text-center'>
                                        <Col as="h2" className='fs80vw semibold GrayComn'>Coming Soon</Col>
                                        <Col as="p" className='fs18 semibold GrayComn'>Stay tuned for more exciting features! We'd love to hear your <br className='d-none d-md-block '/> feedback. Let us know what you think.</Col>
                                    </Col>
                                </Col>
                                <Col as="hr" className="my-4 mt-2 graye0e opacity-100"></Col>
                                <Col className='gallery-slider'>
                                    <Col className='d-flex justify-contnet-between'>
                                        <Col className='GrayComn fs18 semibold mb-3'>Gallery</Col>
                                        <Col className='text-end mb-3'>
                                        <Link to=" " className='themeColor fs18 semibold text-decoration-none'>View Gallery</Link>
                                        </Col>
                                    </Col>
                                    <Slider className="featureProd galleryProd" {...settings}>
                                        <div className="card border-0">
                                            <div className="promo"> 
                                                <img src={process.env.PUBLIC_URL+"assets/gallery1.png"} className="img-fluid" alt="gallery01" />                                    
                                            </div>
                                        </div>
                                        <div className="card border-0">
                                            <div className="promo">
                                                <img src={process.env.PUBLIC_URL+"assets/gallery3.png"} className="img-fluid" alt="gallery03" />
                                            </div>
                                        </div>
                                        <div className="card border-0">
                                            <div className="promo">
                                                <img src={process.env.PUBLIC_URL+"assets/gallery2.png"} className="img-fluid" alt="gallery02" />
                                            </div>
                                        </div>
                                        <div className="card border-0">
                                            <div className="promo">
                                                <img src={process.env.PUBLIC_URL+"assets/gallery1.png"} className="img-fluid" alt="gallery01" />
                                            </div>
                                        </div>
                                    </Slider>
                                </Col> 
                            </Col>   
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )    
}
export default HostCribThree;