import React, { useState } from 'react'
import { Container, Row, Col, Breadcrumb, Form, Button, FloatingLabel, Modal } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "./identity.css"
import Header from "../header";
import Footer from "../footer";
import ProfileMenu from "../profilemenu";

const IdentityStep04 = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);    

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0"><Header /></Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label"><ProfileMenu /></Col>
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4 mb-0 GrayComn">My Profile</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account Settings</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Manage  Payments</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section" className="stepWrap ms-md-4 pt-md-5">
                            <Row className="text-center justify-content-center pb-2">
                                <Col xs={12} lg="10" xl="7" className="text-start">
                                    <h2 className="fs22 GrayComn semibold">Please confirm your personal registration details.</h2>
                                    <Col lg="12" as="section" className="stepsBar2 pt-4">
                                        <Col as="ul" className="list-unstyled">
                                            <Col as="li" className="p2 active first"><span className="fs14" style={{marginLeft: "calc(100%/1.03)"}}>4</span></Col>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="text-center justify-content-center">
                                <Col lg="7">
                                    <Row>
                                        <Col as="section" className="signpUser">
                                            <Form className='accForm text-start'>
                                              
                                                <FloatingLabel controlId="floatingInput" label="Gender" className="mb-3">
                                                    <Form.Control type="text" placeholder="First Name " />
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInput" label="Citizenship" className="mb-3">
                                                    <Form.Control type="text" placeholder="NRIC Number" />
                                                </FloatingLabel>
                                                
                                                <FloatingLabel controlId="floatingInput" label="Address" className="mb-3">
                                                    <Form.Control type="text" placeholder="NRIC Number" />
                                                </FloatingLabel>                                                
                                            
                                                <div className="form-radio circleBox">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="AccRad01" />
                                                    <label className="form-check-label ps-3 themeColor text-truncate w-75" for="AccRad01">Information is correct. No amendment required.</label>
                                                </div>
                                                <div className="form-radio circleBox">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="AccRad02" />
                                                    <label className="form-check-label ps-3 themeColor text-truncate w-75" for="AccRad02">Information is incorrect. Proceed to amend.</label>
                                                </div>

                                                <Row className="pt-3 mt-2">
                                                    <Col className="align-self-center mb-4">
                                                        <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button">
                                                            <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                            Previous
                                                        </Button>
                                                    </Col>
                                                    <Col lg="4" className="mb-4">
                                                        <Button onClick={setShow} className="w-100 py-2 lh-lg border-0 hvr-float-shadow rounded-pill" variant="primary">Complete</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>        
            </Container>
            <Footer />
            
            <Modal 
                className="order-response" 
                backdropClassName="action1"
                dialogClassName="action2 successfullyPop"
                contentClassName="action3 bgtheme border-0 border12 pb-5"
                show={show} 
                centered
                onHide={handleClose}>
            <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
                <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white"/>
                </svg>
            </Modal.Header>
            <Modal.Body className='modal-input text-center m-0'>
                <Col className="container">
                    <Row className="justify-content-center">
                        <Col md="11" className="modal-content-inner">
                            <Col as="span" className="rounded-circle d-block pb-4">
                                <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.9992 97.8002C75.9509 97.8002 97.7992 75.9518 97.7992 49.0002C97.7992 22.0487 75.9509 0.200195 48.9992 0.200195C22.0477 0.200195 0.199219 22.0487 0.199219 49.0002C0.199219 75.9518 22.0477 97.8002 48.9992 97.8002ZM71.6125 41.1136C73.9946 38.7313 73.9946 34.8691 71.6125 32.4868C69.2305 30.1047 65.368 30.1047 62.9859 32.4868L42.8992 52.5736L35.0126 44.6868C32.6304 42.3047 28.7681 42.3047 26.3858 44.6868C24.0037 47.0691 24.0037 50.9315 26.3858 53.3135L38.5858 65.5135C40.9681 67.8956 44.8304 67.8956 47.2126 65.5135L71.6125 41.1136Z" fill="white"/>
                                </svg>
                            </Col>
                            <h2 className="fs32 medium text-white">Success!</h2>
                            <Col as="p" className="colorwhite semibol fs24 lh-sm mb-0">
                                We have received your application for Identity Verification, if you edited any fields, 
                                your information will be manually verified prior to receiving your 'Verified' status.
                            </Col>                            
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>            
        </Modal>
        </div>
    )    
}
export default IdentityStep04