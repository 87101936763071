import React, { useState } from 'react'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./hoststep.css";
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';
import { Link } from 'react-router-dom';

const HostMenufive = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9">
                        <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-5 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <Col md="auto" className='d-flex text-center flex-column flex-md-row text-md-start col-md-auto'>
                                        <span className="user-profile-circle me-md-3 mx-auto rounded-pill border border-2 border-danger p-1 d-inline-block position-relative">
                                            <img src="./assets/black_scoop.png" className='rounded-pill border-2' alt="food" />
                                        </span>
                                        <Col className="itemDetails">
                                            <Col as="div" className="itemHead mb-2">
                                                <h4 className="d-inline-block align-middle GrayDark fs46 semibold">City Home Cafe</h4>
                                            </Col>
                                            <Col as="p" className="d-inline-flex border04 grayf7f py-2 px-3 shadowMD align-items-center semibold align-middle">
                                                <Col className='border-end pe-3 me-3'>
                                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.0013 26.0947L15.8379 14.4258H24.1647L20.0013 26.0947Z" fill="#6992F2"/>
                                                    <path d="M0 14.3711L19.3602 31.4961L13.5297 14.4017L0 14.3711Z" fill="#3169ED"/>
                                                    <path d="M12.8381 12.0727L0.0273438 12.0743L6.42691 0.878906L12.8381 12.0727Z" fill="#4377EF"/>
                                                    <path d="M39.9998 14.3867L20.6396 31.5117L26.4701 14.4173L39.9998 14.3867Z" fill="#7CA0F4"/>
                                                    <path d="M27.1631 12.0845L39.9738 12.086L33.5859 0.890625L27.1631 12.0845Z" fill="#A1BBF7"/>
                                                    <path d="M24.2077 11.9639L15.8809 11.9649L20.0559 4.72656L24.2077 11.9639Z" fill="#8EADF6"/>
                                                    <path d="M19.5222 0.00155315L8.61328 0L14.0628 9.53337L19.5222 0.00155315Z" fill="#5684F0"/>
                                                    <path d="M20.5225 0.00155315L31.4314 0L25.9918 9.53337L20.5225 0.00155315Z" fill="#7CA0F4"/>
                                                    </svg>
                                                </Col>    
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6758 0.928603C12.0926 -0.309534 13.9074 -0.309534 14.3242 0.928603L16.4386 7.20961C16.6249 7.76332 17.1596 8.13821 17.7627 8.13821H24.605C25.9538 8.13821 26.5146 9.80412 25.4234 10.5693L19.8878 14.4512C19.3998 14.7934 19.1957 15.4 19.3821 15.9537L21.4965 22.2348C21.9132 23.4729 20.445 24.5025 19.3539 23.7372L13.8184 19.8554C13.3304 19.5131 12.6696 19.5131 12.1816 19.8554L6.64609 23.7372C5.55492 24.5025 4.08674 23.4729 4.50353 22.2348L6.61791 15.9537C6.8043 15.4 6.6001 14.7934 6.11212 14.4512L0.57661 10.5693C-0.514582 9.80412 0.0462183 8.13821 1.39499 8.13821H8.23727C8.84045 8.13821 9.37505 7.76332 9.56144 7.20961L11.6758 0.928603Z" fill="#EF9309"/>
                                                    </svg>
                                                <Col as="span" className='col-auto px-2 lh-1 align-middle fs26 GrayComn semibold'>
                                                    4.5
                                                </Col>
                                                <Link to="/" className='d-inline-block themeColor ps-3 semibold fs20 text-decoration-none'>See Reviews (55)</Link>
                                            </Col>
                                        </Col>
                                    </Col>
                                    <Col md="auto" className='text-md-end py-md-4 mt-2 text-center'>
                                        <Button variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 ">
                                        <svg className='align-text-bottom me-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3335 2.41536C1.3335 1.81706 1.81853 1.33203 2.41683 1.33203H17.5835C18.1818 1.33203 18.6668 1.81706 18.6668 2.41536V4.58203C18.6668 5.18033 18.1818 5.66536 17.5835 5.66536H2.41683C1.81853 5.66536 1.3335 5.18033 1.3335 4.58203V2.41536Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3335 11.0833C1.3335 10.485 1.81853 10 2.41683 10H8.91683C9.51515 10 10.0002 10.485 10.0002 11.0833V17.5833C10.0002 18.1817 9.51515 18.6667 8.91683 18.6667H2.41683C1.81853 18.6667 1.3335 18.1817 1.3335 17.5833V11.0833Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3335 11.0833C14.3335 10.485 14.8185 10 15.4168 10H17.5835C18.1818 10 18.6668 10.485 18.6668 11.0833V17.5833C18.6668 18.1817 18.1818 18.6667 17.5835 18.6667H15.4168C14.8185 18.6667 14.3335 18.1817 14.3335 17.5833V11.0833Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        Item Templates
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col as="section" className='menu-box-icon ms-0 ms-xl-5 ps-xl-5 mb-5 ps-0'>
                            <Col className='container px-xl-5 px-lg-4 px-0'>
                                <Row>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                            <svg className="mt-5" width="98" height="97" viewBox="0 0 98 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.75C4 7.02211 6.51842 4 9.625 4H88.375C91.4817 4 94 7.02211 94 10.75V24.25C94 27.9779 91.4817 31 88.375 31H9.625C6.51842 31 4 27.9779 4 24.25V10.75Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 53.625C4 50.5183 6.51842 48 9.625 48H43.375C46.4817 48 49 50.5183 49 53.625V87.375C49 90.4817 46.4817 93 43.375 93H9.625C6.51842 93 4 90.4817 4 87.375V53.625Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M70 53.625C70 50.5183 72.5743 48 75.75 48H87.25C90.4257 48 93 50.5183 93 53.625V87.375C93 90.4817 90.4257 93 87.25 93H75.75C72.5743 93 70 90.4817 70 87.375V53.625Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Col as="p" className='fs28 fs28sm semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Ala Carte (Burger)</Col>
                                            </Link>
                                        </Col>
                                    </Col>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                            <svg className="mt-5" width="98" height="97" viewBox="0 0 98 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.75C4 7.02211 6.51842 4 9.625 4H88.375C91.4817 4 94 7.02211 94 10.75V24.25C94 27.9779 91.4817 31 88.375 31H9.625C6.51842 31 4 27.9779 4 24.25V10.75Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 53.625C4 50.5183 6.51842 48 9.625 48H43.375C46.4817 48 49 50.5183 49 53.625V87.375C49 90.4817 46.4817 93 43.375 93H9.625C6.51842 93 4 90.4817 4 87.375V53.625Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M70 53.625C70 50.5183 72.5743 48 75.75 48H87.25C90.4257 48 93 50.5183 93 53.625V87.375C93 90.4817 90.4257 93 87.25 93H75.75C72.5743 93 70 90.4817 70 87.375V53.625Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Col as="p" className='fs28 fs28sm semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Sides</Col>
                                            </Link>
                                        </Col>
                                    </Col>
                                    <Col md="4" className='px-lg-2 px-xxl-4 px-xl-2 px-md-3 mb-xxl-5 mb-3'>
                                        <Col className='cribbox border12 text-center shadowMD'>
                                            <Link to=" " className='d-block py-3 py-xxl-5 px-2 text-decoration-none'>
                                            <svg className="mt-5" width="98" height="97" viewBox="0 0 98 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.75C4 7.02211 6.51842 4 9.625 4H88.375C91.4817 4 94 7.02211 94 10.75V24.25C94 27.9779 91.4817 31 88.375 31H9.625C6.51842 31 4 27.9779 4 24.25V10.75Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 53.625C4 50.5183 6.51842 48 9.625 48H43.375C46.4817 48 49 50.5183 49 53.625V87.375C49 90.4817 46.4817 93 43.375 93H9.625C6.51842 93 4 90.4817 4 87.375V53.625Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M70 53.625C70 50.5183 72.5743 48 75.75 48H87.25C90.4257 48 93 50.5183 93 53.625V87.375C93 90.4817 90.4257 93 87.25 93H75.75C72.5743 93 70 90.4817 70 87.375V53.625Z" stroke="#404040" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Col as="p" className='fs28 fs28sm semibold mt-4 mb-5 mb-0 pt-2 GrayDark'>Drinks</Col>
                                        </Link>
                                        </Col>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="4" className='px-lg-2 px-xl-4 px-md-3'>
                                        <Col className='add-cribbox border12 text-center lightred shadowMD '>
                                            <Col role="button" onClick={setShow} className='d-block py-xl-5 py-3 px-2 text-decoration-none'>
                                                <svg className="mt-5" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="22.8086" width="14.4" height="60" rx="7.2" fill="#C32148"/>
                                                <rect y="37.1758" width="14.4" height="60" rx="7.2" transform="rotate(-90 0 37.1758)" fill="#C32148"/>
                                                </svg>

                                                <Col as="p" className='fs28 fs28sm semibold mt-4 mb-5 mb-0 pt-2 GrayComn'>Create New Template</Col>
                                            </Col>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Modal 
                className="order-response" 
                backdropClassName="action1"
                dialogClassName="action2 successfullyPop"
                contentClassName="action3 bgtheme border-0 border12"
                show={show} 
                centered
                onHide={handleClose}>
                <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
                <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white"/>
                </svg>
                </Modal.Header>
                <Modal.Body className='text-center px-5 pb-5'>
                    <Col className="modal-content-inner">
                        <Col as="h3" className="p-0 mb-1 fs32 colorwhite">Order Response</Col>
                        <Col as="p" className="px-2 colorwhite fs24 mb-5 lh-sm pb-2">Do you wish to Accept this <br className='d-none d-lg-inline' /> Order Request?</Col>
                    </Col>
                </Modal.Body>
            </Modal>
            <Footer />
        </div>
    )    
}
export default HostMenufive;