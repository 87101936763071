import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { FiHeart, FiShare2 } from "react-icons/fi";
import { GoClock, GoPrimitiveDot } from "react-icons/go";
import { IoDiamond } from "react-icons/io5";

import Slider00 from "rc-slider";
import "rc-slider/assets/index.css";
import "react-pro-sidebar/dist/css/styles.css";

import "./meal.css"
import Header from "../header";
import Slider from "react-slick";
import Footer from '../footer';

const FindMyMeal = () => {
    const heightMarks = {
        10: "$ Economy",
        20: "$$ Standard",
        30: "$$$ Plus",
        40: "$$$$ Premium",
    };
    const navRef = React.useRef(null);
    const onToggleClick = (e) => {
        navRef.current.classList.toggle("rightActive");
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slickGoTo: true
    };
    const settings01 = {
        dots: false,
        infinite: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,        
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }            
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    }; 

    return (
        <div>
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>                    
                </Row>
                <Row>
                    <Col md="2" className="LeftSidebar px-0 h-100">
                        <div className="h-100 overflow-auto pb-5">
                            <section className="FilterSection">
                                <div className="headFilter">
                                    <h2 className="semibold">Sort by</h2>
                                    <p>Select which items to be displayed first.</p>
                                </div>
                                <div className="form-radio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">Distance (Nearest)</label>
                                </div>
                                <div className="form-radio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Price (Lowest)</label>
                                </div>
                                <div className="form-radio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label" for="flexRadioDefault3">Price (Lowest)</label>
                                </div>
                                <div className="form-radio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label" for="flexRadioDefault4">Price (Lowest)</label>
                                </div>
                            </section>
                            <section className="FilterSection">
                                <div className="headFilter">
                                    <h2 className="semibold">Host Experience</h2>
                                    <p>Show only Hosts of this level.</p>
                                </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault5" />
                                        <label className="form-check-label" for="flexRadioDefault5">Prime Chef</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault6" />
                                        <label className="form-check-label" for="flexRadioDefault6">Super Chef</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault7" />
                                        <label className="form-check-label" for="flexRadioDefault7">Master Chef</label>
                                    </div>                                
                            </section>
                            <section className="FilterSection">
                                <div className="headFilter">
                                    <h2 className="semibold">Price Filter</h2>
                                    <p>Economy to Plus</p>
                                </div>
                                <div className="form-range-slider">
                                    <Slider00
                                        defaultValue={40}
                                        min={10}
                                        max={40}
                                        step={1}
                                        marks={heightMarks}
                                    />
                                </div>
                            </section>
                            <section className="FilterSection">
                                <div className="headFilter">
                                    <h2 className="semibold">Dietary Preference</h2>
                                    <p>Select dietary preference to be displayed.</p>
                                </div>
                                <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="check01" />
                                        <label className="form-check-label" for="check01">Halal</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="check02" />
                                        <label className="form-check-label" for="check02">Vegetarian</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="check03" />
                                        <label className="form-check-label" for="check03">Vegan</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="check04" />
                                        <label className="form-check-label" for="check04">Pescatarian</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="check05" />
                                        <label className="form-check-label" for="check05">Kosher</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="check06" />
                                        <label className="form-check-label" for="check06">Gluten-Free</label>
                                    </div>
                            </section>
                            <section className="FilterSection">
                                <div className="headFilter">
                                    <h2 className="semibold">Food Allergens</h2>
                                    <p>Select ingredients that you are allergic to</p>
                                </div>
                                <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="fooding01" />
                                        <label className="form-check-label" for="fooding01">Dairy</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="fooding02" />
                                        <label className="form-check-label" for="fooding02">Seafood (Shellfish)</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="fooding03" />
                                        <label className="form-check-label" for="fooding03">Seafood (Fish)</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="fooding04" />
                                        <label className="form-check-label" for="fooding04">Nuts (Tree Nuts)</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="fooding05" />
                                        <label className="form-check-label" for="fooding05">Nuts (Ground Nuts)</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="fooding06" />
                                        <label className="form-check-label" for="fooding06">Soy Bean</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="fooding07" />
                                        <label className="form-check-label" for="fooding07">Wheat</label>
                                    </div>
                            </section>
                            <br />                            
                        </div>
                    </Col>                    
                    <Col ref={navRef} className="RightSidebar">                    
                        <Row>
                            <Col className="pe-0 mb-5">
                                <section className="me3">
                                    <div className="calBox">
                                        <img src="./assets/mapBig.png" className="img-fluid bgFluid" alt='calender' />
                                        <div className="grayBgScroll">
                                            <span className='d-block text-center'><b></b></span>
                                            <span className='d-block text-center'><b></b></span>
                                        </div>
                                        <Slider className="colsmall col-xl-3 sliderReact" {...settings}>
                                            <div className="card col-md-3 border-0 mb-4">
                                                <div className="imgBox position-relative">
                                                    <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                                    <span className="brandLogo share rounded-pill">
                                                        <FiShare2 size={22} color="var(--theme-color)" />
                                                    </span>
                                                    <span className="brandLogo rounded-pill">
                                                        <FiHeart size={22} color="var(--theme-color)" />
                                                    </span>
                                                </div>
                                                <span className="offerPrice">
                                                    <span>20% <i>Off</i></span>
                                                    <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                                </span>
                                                <div className="card-body pt-2 px-0 prod_decription">
                                                    <div className="cafeName">
                                                        <span className="cafeImage position-relative">
                                                            <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                            <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                                        </span>
                                                        <span className="cafeTitle ms-2">Home Cafe</span>
                                                        <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                                    </div>
                                                    <div className="cafeMenu mt-2 mb-3">
                                                        <span className="menuTag me-3">Eggs</span>
                                                        <span className="menuTag me-3">Fish</span>
                                                        <span className="menuTag">Shellfish</span>
                                                    </div>
                                                    <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                                    <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                                    <div className="priceBox d-flex justify-content-between mt-3">
                                                        <div className="priceTotal">
                                                            <h3>S$ 10.99</h3>
                                                            <h5>S$ 12.99</h5>
                                                        </div>
                                                        <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card col-md-3 border-0 mb-4">
                                                <div className="imgBox position-relative">
                                                    <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                                    <span className="brandLogo share rounded-pill">
                                                        <FiShare2 size={22} color="var(--theme-color)" />
                                                    </span>
                                                    <span className="brandLogo rounded-pill">
                                                        <FiHeart size={22} color="var(--theme-color)" />
                                                    </span>
                                                </div>
                                                <span className="offerPrice">
                                                    <span>20% <i>Off</i></span>
                                                    <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                                </span>
                                                <div className="card-body pt-2 px-0 prod_decription">
                                                    <div className="cafeName">
                                                        <span className="cafeImage position-relative">
                                                            <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                            <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                                        </span>
                                                        <span className="cafeTitle ms-2">Home Cafe</span>
                                                        <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                                    </div>
                                                    <div className="cafeMenu mt-2 mb-3">
                                                        <span className="menuTag me-3">Eggs</span>
                                                        <span className="menuTag me-3">Fish</span>
                                                        <span className="menuTag">Shellfish</span>
                                                    </div>
                                                    <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                                    <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                                    <div className="priceBox d-flex justify-content-between mt-3">
                                                        <div className="priceTotal">
                                                            <h3>S$ 10.99</h3>
                                                            <h5>S$ 12.99</h5>
                                                        </div>
                                                        <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card col-md-3 border-0 mb-4">
                                                <div className="imgBox position-relative">
                                                    <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                                    <span className="brandLogo share rounded-pill">
                                                        <FiShare2 size={22} color="var(--theme-color)" />
                                                    </span>
                                                    <span className="brandLogo rounded-pill">
                                                        <FiHeart size={22} color="var(--theme-color)" />
                                                    </span>
                                                </div>
                                                <span className="offerPrice">
                                                    <span>20% <i>Off</i></span>
                                                    <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                                </span>
                                                <div className="card-body pt-2 px-0 prod_decription">
                                                    <div className="cafeName">
                                                        <span className="cafeImage position-relative">
                                                            <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                            <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                                        </span>
                                                        <span className="cafeTitle ms-2">Home Cafe</span>
                                                        <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                                    </div>
                                                    <div className="cafeMenu mt-2 mb-3">
                                                        <span className="menuTag me-3">Eggs</span>
                                                        <span className="menuTag me-3">Fish</span>
                                                        <span className="menuTag">Shellfish</span>
                                                    </div>
                                                    <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                                    <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                                    <div className="priceBox d-flex justify-content-between mt-3">
                                                        <div className="priceTotal">
                                                            <h3>S$ 10.99</h3>
                                                            <h5>S$ 12.99</h5>
                                                        </div>
                                                        <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card col-md-3 border-0 mb-4">
                                                <div className="imgBox position-relative">
                                                    <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                                    <span className="brandLogo share rounded-pill">
                                                        <FiShare2 size={22} color="var(--theme-color)" />
                                                    </span>
                                                    <span className="brandLogo rounded-pill">
                                                        <FiHeart size={22} color="var(--theme-color)" />
                                                    </span>
                                                </div>
                                                <span className="offerPrice">
                                                    <span>20% <i>Off</i></span>
                                                    <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                                </span>
                                                <div className="card-body pt-2 px-0 prod_decription">
                                                    <div className="cafeName">
                                                        <span className="cafeImage position-relative">
                                                            <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                            <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                                        </span>
                                                        <span className="cafeTitle ms-2">Home Cafe</span>
                                                        <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                                    </div>
                                                    <div className="cafeMenu mt-2 mb-3">
                                                        <span className="menuTag me-3">Eggs</span>
                                                        <span className="menuTag me-3">Fish</span>
                                                        <span className="menuTag">Shellfish</span>
                                                    </div>
                                                    <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                                    <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                                    <div className="priceBox d-flex justify-content-between mt-3">
                                                        <div className="priceTotal">
                                                            <h3>S$ 10.99</h3>
                                                            <h5>S$ 12.99</h5>
                                                        </div>
                                                        <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card col-md-3 border-0 mb-4">
                                                <div className="imgBox position-relative">
                                                    <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                                    <span className="brandLogo share rounded-pill">
                                                        <FiShare2 size={22} color="var(--theme-color)" />
                                                    </span>
                                                    <span className="brandLogo rounded-pill">
                                                        <FiHeart size={22} color="var(--theme-color)" />
                                                    </span>
                                                </div>
                                                <span className="offerPrice">
                                                    <span>20% <i>Off</i></span>
                                                    <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                                </span>
                                                <div className="card-body pt-2 px-0 prod_decription">
                                                    <div className="cafeName">
                                                        <span className="cafeImage position-relative">
                                                            <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                            <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                                        </span>
                                                        <span className="cafeTitle ms-2">Home Cafe</span>
                                                        <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                                    </div>
                                                    <div className="cafeMenu mt-2 mb-3">
                                                        <span className="menuTag me-3">Eggs</span>
                                                        <span className="menuTag me-3">Fish</span>
                                                        <span className="menuTag">Shellfish</span>
                                                    </div>
                                                    <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                                    <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                                    <div className="priceBox d-flex justify-content-between mt-3">
                                                        <div className="priceTotal">
                                                            <h3>S$ 10.99</h3>
                                                            <h5>S$ 12.99</h5>
                                                        </div>
                                                        <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </section>
                            </Col>
                        </Row>
                            <div className="heading inner mb-4 pb-3 ms-md-5 me-2">
                                <h2 className="m-0 d-inline-block">Featured Near You</h2>                            
                                <section className="tobbleButton wAuto float-end me-2">
                                    <div className="app left">
                                        <span onClick={onToggleClick} className="leftButton small position-relative">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 3C3.89543 3 3 3.89543 3 5V7C3 8.10457 3.89543 9 5 9H7C8.10457 9 9 8.10457 9 7V5C9 3.89543 8.10457 3 7 3H5Z" fill="var(--bs-gray05)"/>
                                                <path d="M5 11C3.89543 11 3 11.8954 3 13V15C3 16.1046 3.89543 17 5 17H7C8.10457 17 9 16.1046 9 15V13C9 11.8954 8.10457 11 7 11H5Z" fill="var(--bs-gray05)"/>
                                                <path d="M11 5C11 3.89543 11.8954 3 13 3H15C16.1046 3 17 3.89543 17 5V7C17 8.10457 16.1046 9 15 9H13C11.8954 9 11 8.10457 11 7V5Z" fill="var(--bs-gray05)"/>
                                                <path d="M11 13C11 11.8954 11.8954 11 13 11H15C16.1046 11 17 11.8954 17 13V15C17 16.1046 16.1046 17 15 17H13C11.8954 17 11 16.1046 11 15V13Z" fill="var(--bs-gray05)"/>
                                            </svg>
                                        </span>
                                        <span onClick={onToggleClick} className="rightButton small">
                                            <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.65036 20.048L9.02592 12.2637L5.57219 13.485L0 23.8696L6.65036 20.048Z" fill="var(--bs-gray05)"/>
                                                <path d="M12.9091 16.0707L12.8564 15.9626L12.5817 15.2322L12.5643 15.124C12.4854 14.9195 12.4036 14.712 12.316 14.5075C12.2896 14.4432 12.2604 14.3789 12.2312 14.3146C12.1464 14.1189 12.0559 13.9231 11.9624 13.7302C11.9391 13.6835 11.9186 13.6367 11.8952 13.5929C11.7812 13.3562 11.6585 13.1253 11.5329 12.8916L9.75938 12.1816L7.3252 20.1207L12.909 24.0682L12.9091 16.0707Z" fill="var(--bs-gray05)"/>
                                                <path d="M26.5695 23.8694L21.0003 13.4849L17.4502 12.2314L18.0902 14.3293L19.8171 19.9892L26.5695 23.8694Z" fill="var(--bs-gray05)"/>
                                                <path d="M13.1478 15.0246L13.2939 15.4102C13.3523 15.2554 13.4166 15.1035 13.4779 14.9486C13.5217 14.8463 13.5596 14.741 13.6035 14.6388C13.6473 14.5366 13.729 14.3467 13.7933 14.2094C13.8577 14.072 13.9161 13.9375 13.9804 13.8032L14.1412 13.4409L14.4041 12.9267L14.5415 12.6695C14.5853 12.5906 14.6232 12.5117 14.667 12.4358C14.667 12.4358 14.6845 12.4183 14.6904 12.4066H14.6905C15.4501 11.0633 16.3303 9.79193 17.3203 8.608C18.1058 7.66853 18.5389 6.48425 18.5447 5.25952C18.5447 3.86456 17.9905 2.52685 17.0042 1.54053C16.0178 0.554211 14.68 0 13.2852 0H13.0659C11.7415 0.0547291 10.4865 0.607591 9.55223 1.54775C8.61778 2.48808 8.07298 3.74654 8.02665 5.07132C7.98032 6.3961 8.43612 7.6895 9.30261 8.69279C10.3242 9.86783 11.2212 11.1458 11.979 12.506C11.9886 12.5206 11.9963 12.5364 12.0024 12.5527C12.0123 12.5676 12.021 12.5832 12.0287 12.5995C12.1543 12.8304 12.2742 13.0612 12.3882 13.2949C12.4174 13.3562 12.4466 13.4176 12.4788 13.4789C12.5695 13.6718 12.66 13.8645 12.7448 14.0633C12.774 14.1335 12.806 14.2007 12.8354 14.2678C12.923 14.4812 13.0107 14.6944 13.0896 14.9048L13.1363 15.0188L13.1478 15.0246ZM9.55678 5.25957C9.55678 4.27071 9.94946 3.32234 10.6487 2.62308C11.348 1.92399 12.2963 1.53116 13.2852 1.53116C14.274 1.53116 15.2222 1.92401 15.9215 2.62308C16.6208 3.32234 17.0136 4.27071 17.0136 5.25957C17.0136 6.24842 16.6207 7.19679 15.9215 7.89588C15.2222 8.59515 14.274 8.98797 13.2852 8.98797C12.2963 8.98797 11.348 8.59512 10.6487 7.89588C9.94943 7.19679 9.55678 6.24842 9.55678 5.25957Z" fill="var(--bs-gray05)"/>
                                                <path d="M17.9525 15.8807L16.8129 12.1494L15.1357 12.8184L15.0363 12.9996L14.9164 13.2217C14.8258 13.397 14.7353 13.5694 14.6505 13.7446C14.5949 13.8556 14.5424 13.9668 14.4898 14.0749C14.4373 14.183 14.3643 14.3495 14.3028 14.4869C14.2415 14.6242 14.1829 14.7528 14.1275 14.8843C14.072 15.0157 14.0282 15.1297 13.9785 15.2524C13.9288 15.3752 13.8732 15.5066 13.8265 15.6352L13.5752 16.2955V24.0678L19.229 20.0706L17.9525 15.8807Z" fill="var(--bs-gray05)"/>
                                            </svg>
                                        </span>
                                    </div>
                                </section>
                            </div>
                        <Row className="ps-md-5 pe-3 me-2">
                            <section className='nearYou d-md-flex row border pt-4 pb-3 ms-2'>
                            <Slider className="featureProd" {...settings01}>
                                
                                <div className="card col-md-3 border-0">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>                                                
                                            <span className="menuTag me-3">Fish</span>                                                
                                            <span className="menuTag me-3">Shellfish</span>                                                
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="card col-md-3 border-0">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>                                                
                                            <span className="menuTag me-3">Fish</span>                                                
                                            <span className="menuTag me-3">Shellfish</span>                                                
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="card col-md-3 border-0">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>                                                
                                            <span className="menuTag me-3">Fish</span>                                                
                                            <span className="menuTag me-3">Shellfish</span>                                                
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="card col-md-3 border-0">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>                                                
                                            <span className="menuTag me-3">Fish</span>                                                
                                            <span className="menuTag me-3">Shellfish</span>                                                
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="card col-md-3 border-0">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>                                                
                                            <span className="menuTag me-3">Fish</span>                                                
                                            <span className="menuTag me-3">Shellfish</span>                                                
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div> 
                                
                                                                               
                            </Slider>
                            </section>
                        </Row>
                        <Row className='FDcribs ps-md-5 pe-3'>                            
                                <div className="heading inner mt-5 mb-4 pb-3">
                                    <h2 className="m-0 d-inline-block">Food Cribs Near Your</h2>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                    </div>
                                    <span className="offerPrice">
                                        <span>20% <i>Off</i></span>
                                        <img src="./assets/offerTag.png" className="img-fluid BGTag" alt="food" />                                
                                    </span>
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                        <div className="closedTag text-center">
                                            <img src="./assets/closed.png" className="img-fluid" alt="food" />
                                        </div>
                                    </div>                                
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                        <div className="closedTag text-center">
                                            <img src="./assets/closed.png" className="img-fluid" alt="food" />
                                        </div>
                                    </div>                                
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                        <div className="closedTag text-center">
                                            <img src="./assets/closed.png" className="img-fluid" alt="food" />
                                        </div>
                                    </div>                                
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-md-3 border-0 mb-4">
                                    <div className="imgBox position-relative">
                                        <img src="./assets/img01.png" className="img-fluid w-100" alt="food" />
                                        <span className="brandLogo share rounded-pill">
                                            <FiShare2 size={22} color="var(--theme-color)" />
                                        </span>
                                        <span className="brandLogo rounded-pill">
                                            <FiHeart size={22} color="var(--theme-color)" />
                                        </span>
                                        <div className="closedTag text-center">
                                            <img src="./assets/closed.png" className="img-fluid" alt="food" />
                                        </div>
                                    </div>                                
                                    <div className="card-body pt-2 px-0 prod_decription">
                                        <div className="cafeName">
                                            <span className="cafeImage position-relative">
                                                <img src="./assets/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe" />
                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' />
                                            </span>
                                            <span className="cafeTitle ms-2">Home Cafe</span>
                                            <span className="cafeRating"><img src="./assets/rating.jpg" className="img-fluid float-end mt-2" alt="food" /></span>
                                        </div>
                                        <div className="cafeMenu mt-2 mb-3">
                                            <span className="menuTag me-3">Eggs</span>
                                            <span className="menuTag me-3">Fish</span>
                                            <span className="menuTag">Shellfish</span>
                                        </div>
                                        <h5 className="card-title m-0 fw-bold">Chiles en Nogada</h5>                                    
                                        <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div>
                                        <div className="priceBox d-flex justify-content-between mt-3">
                                            <div className="priceTotal">
                                                <h3>S$ 10.99</h3>
                                                <h5>S$ 12.99</h5>
                                            </div>
                                            <div className="cartTotal"><img src="./assets/shoppingCart.svg" className="img-fluid" alt="shoppingCart" /></div>
                                        </div>
                                    </div>
                                </div>                            
                        </Row>
                    </Col>
                </Row>                
            </Container>                
            <Footer />
        </div>
    )
}
export default FindMyMeal