import React from 'react'
import { Container, Row, Col, Breadcrumb, Form, Button, FloatingLabel } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "./identity.css"
import Header from "../header";
import Footer from "../footer";
import ProfileMenu from "../profilemenu";
import { BsCalendar2 } from 'react-icons/bs';

const IdentityStep03 = () => {
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0"><Header /></Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label"><ProfileMenu /></Col>
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4 mb-0 GrayComn">My Profile</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account Settings</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Manage  Payments</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section" className="stepWrap ms-md-4 pt-md-5">
                            <Row className="text-center justify-content-center pb-2">
                                <Col xs={12} lg="10" xl="7" className="text-start">
                                    <h2 className="fs22 GrayComn semibold">Please confirm your personal registration details.</h2>
                                    <Col lg="12" as="section" className="stepsBar2 pt-4">
                                        <Col as="ul" className="list-unstyled">
                                            <Col as="li" className="p2 active first"><span className="fs14" style={{marginLeft: "calc(100%/1.3)"}}>3</span></Col>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="text-center justify-content-center">
                                <Col lg="7">
                                    <Row>
                                        <Col as="section" className="signpUser">                                        
                                            <Form className='accForm text-start'>
                                              
                                                <FloatingLabel controlId="floatingInput" label="John Doe" className="mb-3">
                                                    <Form.Control type="text" placeholder="First Name " />
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInput" label="S1234567A" className="mb-3">
                                                    <Form.Control type="text" placeholder="NRIC Number" />
                                                </FloatingLabel>

                                                <Row className="mb-1">
                                                    <Col>
                                                    <FloatingLabel controlId="floatingInput" label="25 November 1995" className="mb-3">
                                                        <Form.Control type="text" placeholder="DOB" />
                                                        <BsCalendar2 className="calendarIcon mt-1 position-absolute top-0 end-0 mt-4 me-4" size="20" color="var(--theme-color)" />
                                                    </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                    <FloatingLabel controlId="floatingInput" label="Age" className="mb-3">
                                                        <Form.Control type="text" placeholder="Age" disabled />
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            
                                                <div className="form-radio circleBox">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="AccRad01" />
                                                    <label className="form-check-label ps-3 themeColor text-truncate w-75" for="AccRad01">Information is correct. No amendment required.</label>
                                                </div>
                                                <div className="form-radio circleBox">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="AccRad02" />
                                                    <label className="form-check-label ps-3 themeColor text-truncate w-75" for="AccRad02">Information is incorrect. Proceed to amend.</label>
                                                </div>

                                                <Row className="pt-3 mt-2">
                                                    <Col className="align-self-center mb-4">
                                                        <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button">
                                                            <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                            Previous
                                                        </Button>
                                                    </Col>
                                                    <Col lg="4" className="mb-4">
                                                        <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow rounded-pill" variant="primary" type="submit">Next</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>                                        
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>        
            </Container>
            <Footer />
        </div>
    )    
}
export default IdentityStep03