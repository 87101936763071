import React, {useState} from 'react'
import { Container, Row, Col, Breadcrumb, ListGroup, Badge, Form, Collapse } from 'react-bootstrap';
import { FiChevronDown, FiEdit } from "react-icons/fi";
import ScrollArea from 'react-scrollbar';
import "react-pro-sidebar/dist/css/styles.css";
import "./stylenew.css";
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';
import { BsPatchCheckFill, BsStarFill } from 'react-icons/bs';
import { AiFillStar } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';

const AllReviews = () => {

    const [openReview, setOpenReview] = useState(false);
    const [openReview01, setOpenReview01] = useState(false);
    const [openReview02, setOpenReview02] = useState(false);
    const [openReview03, setOpenReview03] = useState(false);
    const [openReview04, setOpenReview04] = useState(false);
    const [openReview05, setOpenReview05] = useState(false);
    const [openReview06, setOpenReview06] = useState(false);    

    const [click01, setCat] = useState('');
    const categoryClick = () => {
        click01 ? setCat('') : setCat('base-state click-state bgLightGray border-0 rounded-pill d-inline-block w-auto position-relative');
    };
    const [click02, setAut] = useState('');
    const authorClick = () => {
        click02 ? setAut('') : setAut('base-state click-state bgLightGray border-0 rounded-pill d-inline-block w-auto position-relative');
    };
    const [click03, setSor] = useState('');
    const sortClick = () => {
        click03 ? setSor('') : setSor('base-state click-state bgLightGray border-0 rounded-pill d-inline-block w-auto position-relative');
    };

    //click row
    const [isActive, setIsActive] = useState(false);
    const handleClick = event => {    
        setIsActive(current => !current);
    };

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Posted Reviews</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">My Profile</Breadcrumb.Item>
                            <Breadcrumb.Item>Posted Reviews</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="outerspace pt-2">
                            <Row>
                                <Col className="pe-sm-5 pb-4">                                    
                                    <Col className="text-end sortby pe-3">
                                        <strong className="pe-2 fs22 semibold d-inline-block">Sort By :</strong>
                                        <Col as="div" onClick={categoryClick} className={click01 || 'base-state bgLightGray border-0 rounded-pill d-inline-block w-auto position-relative' }>
                                            <Form.Select role="button" className="d-inline-block w-auto text-dark bgLightGray border-0 rounded-pill" aria-label="Default select example">
                                                <option>Latest</option>
                                                <option value="2">Latest 01</option>
                                                <option value="3">Latest 02</option>
                                            </Form.Select>
                                            <FiChevronDown size="20" className="chevronAro me-2 transition" color="var(--bs-grayDark)" />
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                                <Col as="section" className="innerspace pt-4 px-md-5 pb-4">
                                    <ScrollArea
                                        smoothScrolling
                                        speed={0.9}
                                        className="scrollcontentarea ordrHtry pe-sm-5"
                                        horizontal={false}>
                                        <Row className={isActive ? 'mb-5 treemenu treemenureview position-relative rowactive' : 'mb-5 treemenu treemenureview position-relative'} onClick={handleClick}>
                                            <Col role="button" 
                                                onClick={() => setOpenReview(!openReview)} 
                                                aria-controls="Review-collapse" 
                                                aria-expanded={openReview} 
                                                md="auto"
                                                className="bg-white">
                                                <span className="rounded-pill border border-danger p-2 d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/avtarImage01.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                </span>
                                            </Col>
                                            <Col className="contentpart">
                                                <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                    <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                    <Badge bg="light" className="bgLightGray" text="dark">
                                                        <span className="rating d-block fs18">
                                                            <BsStarFill size="16" className="align-text-top me-1" color="var(--rating)" /> 
                                                            <strong className="position-relative GrayDark">4.5</strong>
                                                        </span>
                                                    </Badge>
                                                    <span className="float-end fs16 graya1a">Today</span>
                                                </h6>
                                                <Col as="div" className="order-des px-2 shadowMD py-2 grayf7f border04 d-inline-block mb-3">
                                                        <ListGroup className='flex-row'>
                                                            <ListGroup.Item className='border-0 px-2 py-0 colrtranparent'>
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-start border-end py-0 px-2 border-0 semibold fs20 GrayComn colrtranparent'>
                                                            <Col as="span" className='d-inline-blcok themeColor'>Order ID :</Col> A17725GN6CX
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-0 py-0 semibold px-2 fs20 GrayComn colrtranparent'>
                                                        <svg className='align-text-top me-2' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.89436 2.72603C2.89451 2.72603 2.89465 2.72603 2.8948 2.72603L2.89438 2.74329L2.89436 2.72603ZM3.1619 2.72602L4.41464 1.13477L13.0245 2.72602H3.1619ZM13.0889 2.72602H13.0877C13.0881 2.72602 13.0885 2.72602 13.0889 2.72602ZM4.35015 1.13477H4.35143C4.351 1.13477 4.35058 1.13477 4.35015 1.13477V1.13477Z" stroke="#C32148"/>
                                                            <path d="M19.8684 20.2558L16.9469 18.6828V7.22099L19.8684 20.2558Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M17.9346 20.9647H14.2568L16.1637 20.0112L17.9346 20.9647Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M15.4066 18.6668L12.1317 20.304L15.4066 6.73687V18.6668Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M5.05764 4.46732H13.0866C13.5812 4.46856 14.0485 4.24143 14.3532 3.8521C14.3534 3.85189 14.3535 3.85167 14.3537 3.85146L14.4435 3.73735L10.3093 20.8657H0.65206L5.05764 4.46732Z" stroke="#C32148"/>
                                                            <path d="M6.36134 6.47914C6.4248 6.26769 6.61944 6.12288 6.84021 6.12287L11.4484 6.12268C11.7764 6.12267 12.0156 6.43316 11.9319 6.75029L10.9972 10.2915C10.9392 10.5109 10.7407 10.6639 10.5137 10.6639L5.77748 10.6639C5.44253 10.6639 5.2023 10.3409 5.29859 10.0201L6.36134 6.47914Z" stroke="#C32148"/>
                                                        </svg>
                                                        Pickup
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col as="div" className="fs20 medium pe-sm-5 mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam 
                                                    ultrices bibendum. Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus 
                                                    amet consectetur ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                </Col>
                                                <ListGroup horizontal className="justify-content-between align-items-center">
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <FiEdit role="button" size="22" className="me-4" color="var(--theme-color)" />
                                                        <HiOutlineTrash role="button" size="24" color="var(--theme-color)" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                
                                                {/* review container */}
                                                <Collapse className="mt-4 shadowXS mx-1 tree-active treeReview position-relative" in={openReview}>
                                                    <Col as="div" id="Review-collapse">
                                                        <ListGroup horizontal className="align-items-center">
                                                            <ListGroup.Item className="border-0 p-0 me-4">
                                                                <span className="shadowXS rounded-pill p-0 d-inline-block">
                                                                    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.0214 38.2135H40.4479C41.9032 38.2135 43.0676 36.9037 43.0676 35.2301V20.2396C43.0676 18.6386 41.9032 17.2562 40.4479 17.2562H18.6177C17.1624 17.2562 15.998 18.566 15.998 20.2396V35.3025C15.998 36.9035 17.1624 38.2859 18.6177 38.2859H19.5637V44.3983L25.0214 38.2135ZM37.683 25.3337C38.9928 25.3337 40.0843 26.4252 40.0843 27.735C40.0843 29.0448 38.9928 30.1363 37.683 30.1363C36.3732 30.1363 35.2816 29.0448 35.2816 27.735C35.2816 26.4252 36.3732 25.3337 37.683 25.3337ZM29.5328 25.3337C30.8426 25.3337 31.9341 26.4252 31.9341 27.735C31.9341 29.0448 30.8426 30.1363 29.5328 30.1363C28.223 30.1363 27.1315 29.0448 27.1315 27.735C27.1315 26.4252 28.223 25.3337 29.5328 25.3337ZM18.9813 27.735C18.9813 26.4252 20.0728 25.3337 21.3826 25.3337C22.6925 25.3337 23.784 26.4252 23.784 27.735C23.784 29.0448 22.6925 30.1363 21.3826 30.1363C20.0002 30.1363 18.9813 29.0448 18.9813 27.735Z" fill="#C32148"/>
                                                                        <path d="M48.3067 25.1144H44.5227V33.4099C44.8139 33.2645 45.1776 33.1916 45.5414 33.1916C46.8512 33.1916 47.9427 34.2831 47.9427 35.593C47.9427 36.9028 46.8512 37.9943 45.5414 37.9943C44.9593 37.9943 44.377 37.776 43.9404 37.4122C43.2127 38.722 41.9028 39.6681 40.3747 39.6681L25.6761 39.6679L23.9297 41.7054V43.1607C23.9297 44.7617 25.0941 46.1441 26.5493 46.1441H41.9758L47.4334 52.2566V46.1441H48.3794C49.8347 46.1441 50.9991 44.8343 50.9991 43.1607V28.0978C50.9262 26.4243 49.7619 25.1144 48.3066 25.1144L48.3067 25.1144Z" fill="#C32148"/>
                                                                    </svg>
                                                                </span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 fs20 semibold GrayComn">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore.
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row className={isActive ? 'mb-5 treemenu treemenureview position-relative rowactive' : 'mb-5 treemenu treemenureview position-relative'} onClick={handleClick}>
                                            <Col role="button" 
                                                onClick={() => setOpenReview01(!openReview01)} 
                                                aria-controls="Review-collapse" 
                                                aria-expanded={openReview01} 
                                                md="auto"
                                                className="bg-white">
                                                <span className="rounded-pill border border-danger p-2 d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/coffee01.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                </span>
                                            </Col>
                                            <Col className="contentpart">
                                                <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                    <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                    <Badge bg="light" className="bgLightGray" text="dark">
                                                        <span className="rating d-block fs18">
                                                            <BsStarFill size="16" className="align-text-top me-1" color="var(--rating)" /> 
                                                            <strong className="position-relative GrayDark">4.5</strong>
                                                        </span>
                                                    </Badge>
                                                    <span className="float-end fs16 graya1a">Today</span>
                                                </h6>
                                                <Col as="div" className="order-des px-2 shadowMD py-2 grayf7f border04 d-inline-block mb-3">
                                                        <ListGroup className='flex-row'>
                                                            <ListGroup.Item className='border-0 px-2 py-0 colrtranparent'>
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-start border-end py-0 px-2 border-0 semibold fs20 GrayComn colrtranparent'>
                                                            <Col as="span" className='d-inline-blcok themeColor'>Order ID :</Col> A17725GN6CX
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-0 py-0 semibold px-2 fs20 GrayComn colrtranparent'>
                                                        <svg className='align-text-top me-2' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.89436 2.72603C2.89451 2.72603 2.89465 2.72603 2.8948 2.72603L2.89438 2.74329L2.89436 2.72603ZM3.1619 2.72602L4.41464 1.13477L13.0245 2.72602H3.1619ZM13.0889 2.72602H13.0877C13.0881 2.72602 13.0885 2.72602 13.0889 2.72602ZM4.35015 1.13477H4.35143C4.351 1.13477 4.35058 1.13477 4.35015 1.13477V1.13477Z" stroke="#C32148"/>
                                                            <path d="M19.8684 20.2558L16.9469 18.6828V7.22099L19.8684 20.2558Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M17.9346 20.9647H14.2568L16.1637 20.0112L17.9346 20.9647Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M15.4066 18.6668L12.1317 20.304L15.4066 6.73687V18.6668Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M5.05764 4.46732H13.0866C13.5812 4.46856 14.0485 4.24143 14.3532 3.8521C14.3534 3.85189 14.3535 3.85167 14.3537 3.85146L14.4435 3.73735L10.3093 20.8657H0.65206L5.05764 4.46732Z" stroke="#C32148"/>
                                                            <path d="M6.36134 6.47914C6.4248 6.26769 6.61944 6.12288 6.84021 6.12287L11.4484 6.12268C11.7764 6.12267 12.0156 6.43316 11.9319 6.75029L10.9972 10.2915C10.9392 10.5109 10.7407 10.6639 10.5137 10.6639L5.77748 10.6639C5.44253 10.6639 5.2023 10.3409 5.29859 10.0201L6.36134 6.47914Z" stroke="#C32148"/>
                                                        </svg>
                                                        Pickup
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col as="div" className="fs20 medium pe-5 mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam 
                                                    ultrices bibendum. Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus 
                                                    amet consectetur ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                                </Col>
                                                <ListGroup horizontal className="justify-content-between align-items-center">
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <FiEdit role="button" size="22" className="me-4" color="var(--theme-color)" />
                                                        <HiOutlineTrash role="button" size="24" color="var(--theme-color)" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                {/* review container */}
                                                <Collapse className="mt-4 shadowXS mx-1 tree-active treeReview position-relative" in={openReview01}>
                                                    <Col as="div" id="Review-collapse">
                                                        <ListGroup horizontal className="align-items-center">
                                                            <ListGroup.Item className="border-0 p-0 me-4">
                                                                <span className="shadowXS rounded-pill p-0 d-inline-block">
                                                                    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.0214 38.2135H40.4479C41.9032 38.2135 43.0676 36.9037 43.0676 35.2301V20.2396C43.0676 18.6386 41.9032 17.2562 40.4479 17.2562H18.6177C17.1624 17.2562 15.998 18.566 15.998 20.2396V35.3025C15.998 36.9035 17.1624 38.2859 18.6177 38.2859H19.5637V44.3983L25.0214 38.2135ZM37.683 25.3337C38.9928 25.3337 40.0843 26.4252 40.0843 27.735C40.0843 29.0448 38.9928 30.1363 37.683 30.1363C36.3732 30.1363 35.2816 29.0448 35.2816 27.735C35.2816 26.4252 36.3732 25.3337 37.683 25.3337ZM29.5328 25.3337C30.8426 25.3337 31.9341 26.4252 31.9341 27.735C31.9341 29.0448 30.8426 30.1363 29.5328 30.1363C28.223 30.1363 27.1315 29.0448 27.1315 27.735C27.1315 26.4252 28.223 25.3337 29.5328 25.3337ZM18.9813 27.735C18.9813 26.4252 20.0728 25.3337 21.3826 25.3337C22.6925 25.3337 23.784 26.4252 23.784 27.735C23.784 29.0448 22.6925 30.1363 21.3826 30.1363C20.0002 30.1363 18.9813 29.0448 18.9813 27.735Z" fill="#C32148"/>
                                                                        <path d="M48.3067 25.1144H44.5227V33.4099C44.8139 33.2645 45.1776 33.1916 45.5414 33.1916C46.8512 33.1916 47.9427 34.2831 47.9427 35.593C47.9427 36.9028 46.8512 37.9943 45.5414 37.9943C44.9593 37.9943 44.377 37.776 43.9404 37.4122C43.2127 38.722 41.9028 39.6681 40.3747 39.6681L25.6761 39.6679L23.9297 41.7054V43.1607C23.9297 44.7617 25.0941 46.1441 26.5493 46.1441H41.9758L47.4334 52.2566V46.1441H48.3794C49.8347 46.1441 50.9991 44.8343 50.9991 43.1607V28.0978C50.9262 26.4243 49.7619 25.1144 48.3066 25.1144L48.3067 25.1144Z" fill="#C32148"/>
                                                                    </svg>
                                                                </span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 fs20 semibold GrayComn">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore.
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row className={isActive ? 'mb-5 treemenu treemenureview position-relative rowactive' : 'mb-5 treemenu treemenureview position-relative'} onClick={handleClick}>
                                            <Col role="button" 
                                                onClick={() => setOpenReview02(!openReview02)} 
                                                aria-controls="Review-collapse" 
                                                aria-expanded={openReview02} 
                                                md="auto"
                                                className="bg-white">
                                                <span className="rounded-pill border border-danger p-2 d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/coffee02.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                </span>
                                            </Col>
                                            <Col className="contentpart">
                                                <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                    <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                    <Badge bg="light" className="bgLightGray" text="dark">
                                                        <span className="rating d-block fs18">
                                                            <BsStarFill size="16" className="align-text-top me-1" color="var(--rating)" /> 
                                                            <strong className="position-relative GrayDark">4.5</strong>
                                                        </span>
                                                    </Badge>
                                                    <span className="float-end fs16 graya1a">Today</span>
                                                </h6>
                                                <Col as="div" className="order-des px-2 shadowMD py-2 grayf7f border04 d-inline-block mb-3">
                                                        <ListGroup className='flex-row'>
                                                            <ListGroup.Item className='border-0 px-2 py-0 colrtranparent'>
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-start border-end py-0 px-2 border-0 semibold fs20 GrayComn colrtranparent'>
                                                            <Col as="span" className='d-inline-blcok themeColor'>Order ID :</Col> A17725GN6CX
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-0 py-0 semibold px-2 fs20 GrayComn colrtranparent'>
                                                        <svg className='align-text-top me-2' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.89436 2.72603C2.89451 2.72603 2.89465 2.72603 2.8948 2.72603L2.89438 2.74329L2.89436 2.72603ZM3.1619 2.72602L4.41464 1.13477L13.0245 2.72602H3.1619ZM13.0889 2.72602H13.0877C13.0881 2.72602 13.0885 2.72602 13.0889 2.72602ZM4.35015 1.13477H4.35143C4.351 1.13477 4.35058 1.13477 4.35015 1.13477V1.13477Z" stroke="#C32148"/>
                                                            <path d="M19.8684 20.2558L16.9469 18.6828V7.22099L19.8684 20.2558Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M17.9346 20.9647H14.2568L16.1637 20.0112L17.9346 20.9647Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M15.4066 18.6668L12.1317 20.304L15.4066 6.73687V18.6668Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M5.05764 4.46732H13.0866C13.5812 4.46856 14.0485 4.24143 14.3532 3.8521C14.3534 3.85189 14.3535 3.85167 14.3537 3.85146L14.4435 3.73735L10.3093 20.8657H0.65206L5.05764 4.46732Z" stroke="#C32148"/>
                                                            <path d="M6.36134 6.47914C6.4248 6.26769 6.61944 6.12288 6.84021 6.12287L11.4484 6.12268C11.7764 6.12267 12.0156 6.43316 11.9319 6.75029L10.9972 10.2915C10.9392 10.5109 10.7407 10.6639 10.5137 10.6639L5.77748 10.6639C5.44253 10.6639 5.2023 10.3409 5.29859 10.0201L6.36134 6.47914Z" stroke="#C32148"/>
                                                        </svg>
                                                        Pickup
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col as="div" className="fs20 medium pe-5 mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam 
                                                    ultrices bibendum. Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus 
                                                    amet consectetur ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                                </Col>
                                                <ListGroup horizontal className="justify-content-between align-items-center">
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <FiEdit role="button" size="22" className="me-4" color="var(--theme-color)" />
                                                        <HiOutlineTrash role="button" size="24" color="var(--theme-color)" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                {/* review container */}
                                                <Collapse className="mt-4 shadowXS mx-1 tree-active treeReview position-relative" in={openReview02}>
                                                    <Col as="div" id="Review-collapse">
                                                        <ListGroup horizontal className="align-items-center">
                                                            <ListGroup.Item className="border-0 p-0 me-4">
                                                                <span className="shadowXS rounded-pill p-0 d-inline-block">
                                                                    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.0214 38.2135H40.4479C41.9032 38.2135 43.0676 36.9037 43.0676 35.2301V20.2396C43.0676 18.6386 41.9032 17.2562 40.4479 17.2562H18.6177C17.1624 17.2562 15.998 18.566 15.998 20.2396V35.3025C15.998 36.9035 17.1624 38.2859 18.6177 38.2859H19.5637V44.3983L25.0214 38.2135ZM37.683 25.3337C38.9928 25.3337 40.0843 26.4252 40.0843 27.735C40.0843 29.0448 38.9928 30.1363 37.683 30.1363C36.3732 30.1363 35.2816 29.0448 35.2816 27.735C35.2816 26.4252 36.3732 25.3337 37.683 25.3337ZM29.5328 25.3337C30.8426 25.3337 31.9341 26.4252 31.9341 27.735C31.9341 29.0448 30.8426 30.1363 29.5328 30.1363C28.223 30.1363 27.1315 29.0448 27.1315 27.735C27.1315 26.4252 28.223 25.3337 29.5328 25.3337ZM18.9813 27.735C18.9813 26.4252 20.0728 25.3337 21.3826 25.3337C22.6925 25.3337 23.784 26.4252 23.784 27.735C23.784 29.0448 22.6925 30.1363 21.3826 30.1363C20.0002 30.1363 18.9813 29.0448 18.9813 27.735Z" fill="#C32148"/>
                                                                        <path d="M48.3067 25.1144H44.5227V33.4099C44.8139 33.2645 45.1776 33.1916 45.5414 33.1916C46.8512 33.1916 47.9427 34.2831 47.9427 35.593C47.9427 36.9028 46.8512 37.9943 45.5414 37.9943C44.9593 37.9943 44.377 37.776 43.9404 37.4122C43.2127 38.722 41.9028 39.6681 40.3747 39.6681L25.6761 39.6679L23.9297 41.7054V43.1607C23.9297 44.7617 25.0941 46.1441 26.5493 46.1441H41.9758L47.4334 52.2566V46.1441H48.3794C49.8347 46.1441 50.9991 44.8343 50.9991 43.1607V28.0978C50.9262 26.4243 49.7619 25.1144 48.3066 25.1144L48.3067 25.1144Z" fill="#C32148"/>
                                                                    </svg>
                                                                </span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 fs20 semibold GrayComn">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore.
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row className={isActive ? 'mb-5 treemenu treemenureview position-relative rowactive' : 'mb-5 treemenu treemenureview position-relative'} onClick={handleClick}>
                                            <Col role="button" 
                                                onClick={() => setOpenReview03(!openReview03)} 
                                                aria-controls="Review-collapse" 
                                                aria-expanded={openReview03} 
                                                md="auto"
                                                className="bg-white">
                                                <span className="rounded-pill border border-danger p-2 d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/coffee03.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                </span>
                                            </Col>
                                            <Col className="contentpart">
                                                <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                    <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                    <Badge bg="light" className="bgLightGray" text="dark">
                                                        <span className="rating d-block fs18">
                                                            <BsStarFill size="16" className="align-text-top me-1" color="var(--rating)" /> 
                                                            <strong className="position-relative GrayDark">4.5</strong>
                                                        </span>
                                                    </Badge>
                                                    <span className="float-end fs16 graya1a">Today</span>
                                                </h6>
                                                <Col as="div" className="order-des px-2 shadowMD py-2 grayf7f border04 d-inline-block mb-3">
                                                        <ListGroup className='flex-row'>
                                                            <ListGroup.Item className='border-0 px-2 py-0 colrtranparent'>
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-start border-end py-0 px-2 border-0 semibold fs20 GrayComn colrtranparent'>
                                                            <Col as="span" className='d-inline-blcok themeColor'>Order ID :</Col> A17725GN6CX
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-0 py-0 semibold px-2 fs20 GrayComn colrtranparent'>
                                                        <svg className='align-text-top me-2' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.89436 2.72603C2.89451 2.72603 2.89465 2.72603 2.8948 2.72603L2.89438 2.74329L2.89436 2.72603ZM3.1619 2.72602L4.41464 1.13477L13.0245 2.72602H3.1619ZM13.0889 2.72602H13.0877C13.0881 2.72602 13.0885 2.72602 13.0889 2.72602ZM4.35015 1.13477H4.35143C4.351 1.13477 4.35058 1.13477 4.35015 1.13477V1.13477Z" stroke="#C32148"/>
                                                            <path d="M19.8684 20.2558L16.9469 18.6828V7.22099L19.8684 20.2558Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M17.9346 20.9647H14.2568L16.1637 20.0112L17.9346 20.9647Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M15.4066 18.6668L12.1317 20.304L15.4066 6.73687V18.6668Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M5.05764 4.46732H13.0866C13.5812 4.46856 14.0485 4.24143 14.3532 3.8521C14.3534 3.85189 14.3535 3.85167 14.3537 3.85146L14.4435 3.73735L10.3093 20.8657H0.65206L5.05764 4.46732Z" stroke="#C32148"/>
                                                            <path d="M6.36134 6.47914C6.4248 6.26769 6.61944 6.12288 6.84021 6.12287L11.4484 6.12268C11.7764 6.12267 12.0156 6.43316 11.9319 6.75029L10.9972 10.2915C10.9392 10.5109 10.7407 10.6639 10.5137 10.6639L5.77748 10.6639C5.44253 10.6639 5.2023 10.3409 5.29859 10.0201L6.36134 6.47914Z" stroke="#C32148"/>
                                                        </svg>
                                                        Pickup
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col as="div" className="fs20 medium pe-5 mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam 
                                                    ultrices bibendum. Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus 
                                                    amet consectetur ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                                </Col>
                                                <ListGroup horizontal className="justify-content-between align-items-center">
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <FiEdit role="button" size="22" className="me-4" color="var(--theme-color)" />
                                                        <HiOutlineTrash role="button" size="24" color="var(--theme-color)" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                {/* review container */}
                                                <Collapse className="mt-4 shadowXS mx-1 tree-active treeReview position-relative" in={openReview03}>
                                                    <Col as="div" id="Review-collapse">
                                                        <ListGroup horizontal className="align-items-center">
                                                            <ListGroup.Item className="border-0 p-0 me-4">
                                                                <span className="shadowXS rounded-pill p-0 d-inline-block">
                                                                    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.0214 38.2135H40.4479C41.9032 38.2135 43.0676 36.9037 43.0676 35.2301V20.2396C43.0676 18.6386 41.9032 17.2562 40.4479 17.2562H18.6177C17.1624 17.2562 15.998 18.566 15.998 20.2396V35.3025C15.998 36.9035 17.1624 38.2859 18.6177 38.2859H19.5637V44.3983L25.0214 38.2135ZM37.683 25.3337C38.9928 25.3337 40.0843 26.4252 40.0843 27.735C40.0843 29.0448 38.9928 30.1363 37.683 30.1363C36.3732 30.1363 35.2816 29.0448 35.2816 27.735C35.2816 26.4252 36.3732 25.3337 37.683 25.3337ZM29.5328 25.3337C30.8426 25.3337 31.9341 26.4252 31.9341 27.735C31.9341 29.0448 30.8426 30.1363 29.5328 30.1363C28.223 30.1363 27.1315 29.0448 27.1315 27.735C27.1315 26.4252 28.223 25.3337 29.5328 25.3337ZM18.9813 27.735C18.9813 26.4252 20.0728 25.3337 21.3826 25.3337C22.6925 25.3337 23.784 26.4252 23.784 27.735C23.784 29.0448 22.6925 30.1363 21.3826 30.1363C20.0002 30.1363 18.9813 29.0448 18.9813 27.735Z" fill="#C32148"/>
                                                                        <path d="M48.3067 25.1144H44.5227V33.4099C44.8139 33.2645 45.1776 33.1916 45.5414 33.1916C46.8512 33.1916 47.9427 34.2831 47.9427 35.593C47.9427 36.9028 46.8512 37.9943 45.5414 37.9943C44.9593 37.9943 44.377 37.776 43.9404 37.4122C43.2127 38.722 41.9028 39.6681 40.3747 39.6681L25.6761 39.6679L23.9297 41.7054V43.1607C23.9297 44.7617 25.0941 46.1441 26.5493 46.1441H41.9758L47.4334 52.2566V46.1441H48.3794C49.8347 46.1441 50.9991 44.8343 50.9991 43.1607V28.0978C50.9262 26.4243 49.7619 25.1144 48.3066 25.1144L48.3067 25.1144Z" fill="#C32148"/>
                                                                    </svg>
                                                                </span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 fs20 semibold GrayComn">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore.
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row className={isActive ? 'mb-5 treemenu treemenureview position-relative rowactive' : 'mb-5 treemenu treemenureview position-relative'} onClick={handleClick}>
                                            <Col role="button" 
                                                onClick={() => setOpenReview04(!openReview04)} 
                                                aria-controls="Review-collapse" 
                                                aria-expanded={openReview04} 
                                                md="auto"
                                                className="bg-white">
                                                <span className="rounded-pill border border-danger p-2 d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/coffee04.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                </span>
                                            </Col>
                                            <Col className="contentpart">
                                                <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                    <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                    <Badge bg="light" className="bgLightGray" text="dark">
                                                        <span className="rating d-block fs18">
                                                            <BsStarFill size="16" className="align-text-top me-1" color="var(--rating)" /> 
                                                            <strong className="position-relative GrayDark">4.5</strong>
                                                        </span>
                                                    </Badge>
                                                    <span className="float-end fs16 graya1a">Today</span>
                                                </h6>
                                                <Col as="div" className="order-des px-2 shadowMD py-2 grayf7f border04 d-inline-block mb-3">
                                                        <ListGroup className='flex-row'>
                                                            <ListGroup.Item className='border-0 px-2 py-0 colrtranparent'>
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-start border-end py-0 px-2 border-0 semibold fs20 GrayComn colrtranparent'>
                                                            <Col as="span" className='d-inline-blcok themeColor'>Order ID :</Col> A17725GN6CX
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-0 py-0 semibold px-2 fs20 GrayComn colrtranparent'>
                                                        <svg className='align-text-top me-2' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.89436 2.72603C2.89451 2.72603 2.89465 2.72603 2.8948 2.72603L2.89438 2.74329L2.89436 2.72603ZM3.1619 2.72602L4.41464 1.13477L13.0245 2.72602H3.1619ZM13.0889 2.72602H13.0877C13.0881 2.72602 13.0885 2.72602 13.0889 2.72602ZM4.35015 1.13477H4.35143C4.351 1.13477 4.35058 1.13477 4.35015 1.13477V1.13477Z" stroke="#C32148"/>
                                                            <path d="M19.8684 20.2558L16.9469 18.6828V7.22099L19.8684 20.2558Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M17.9346 20.9647H14.2568L16.1637 20.0112L17.9346 20.9647Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M15.4066 18.6668L12.1317 20.304L15.4066 6.73687V18.6668Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M5.05764 4.46732H13.0866C13.5812 4.46856 14.0485 4.24143 14.3532 3.8521C14.3534 3.85189 14.3535 3.85167 14.3537 3.85146L14.4435 3.73735L10.3093 20.8657H0.65206L5.05764 4.46732Z" stroke="#C32148"/>
                                                            <path d="M6.36134 6.47914C6.4248 6.26769 6.61944 6.12288 6.84021 6.12287L11.4484 6.12268C11.7764 6.12267 12.0156 6.43316 11.9319 6.75029L10.9972 10.2915C10.9392 10.5109 10.7407 10.6639 10.5137 10.6639L5.77748 10.6639C5.44253 10.6639 5.2023 10.3409 5.29859 10.0201L6.36134 6.47914Z" stroke="#C32148"/>
                                                        </svg>
                                                        Pickup
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col as="div" className="fs20 medium pe-5 mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam 
                                                    ultrices bibendum. Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus 
                                                    amet consectetur ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                                </Col>
                                                <ListGroup horizontal className="justify-content-between align-items-center">
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <FiEdit role="button" size="22" className="me-4" color="var(--theme-color)" />
                                                        <HiOutlineTrash role="button" size="24" color="var(--theme-color)" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                {/* review container */}
                                                <Collapse className="mt-4 shadowXS mx-1 tree-active treeReview position-relative" in={openReview04}>
                                                    <Col as="div" id="Review-collapse">
                                                        <ListGroup horizontal className="align-items-center">
                                                            <ListGroup.Item className="border-0 p-0 me-4">
                                                                <span className="shadowXS rounded-pill p-0 d-inline-block">
                                                                    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.0214 38.2135H40.4479C41.9032 38.2135 43.0676 36.9037 43.0676 35.2301V20.2396C43.0676 18.6386 41.9032 17.2562 40.4479 17.2562H18.6177C17.1624 17.2562 15.998 18.566 15.998 20.2396V35.3025C15.998 36.9035 17.1624 38.2859 18.6177 38.2859H19.5637V44.3983L25.0214 38.2135ZM37.683 25.3337C38.9928 25.3337 40.0843 26.4252 40.0843 27.735C40.0843 29.0448 38.9928 30.1363 37.683 30.1363C36.3732 30.1363 35.2816 29.0448 35.2816 27.735C35.2816 26.4252 36.3732 25.3337 37.683 25.3337ZM29.5328 25.3337C30.8426 25.3337 31.9341 26.4252 31.9341 27.735C31.9341 29.0448 30.8426 30.1363 29.5328 30.1363C28.223 30.1363 27.1315 29.0448 27.1315 27.735C27.1315 26.4252 28.223 25.3337 29.5328 25.3337ZM18.9813 27.735C18.9813 26.4252 20.0728 25.3337 21.3826 25.3337C22.6925 25.3337 23.784 26.4252 23.784 27.735C23.784 29.0448 22.6925 30.1363 21.3826 30.1363C20.0002 30.1363 18.9813 29.0448 18.9813 27.735Z" fill="#C32148"/>
                                                                        <path d="M48.3067 25.1144H44.5227V33.4099C44.8139 33.2645 45.1776 33.1916 45.5414 33.1916C46.8512 33.1916 47.9427 34.2831 47.9427 35.593C47.9427 36.9028 46.8512 37.9943 45.5414 37.9943C44.9593 37.9943 44.377 37.776 43.9404 37.4122C43.2127 38.722 41.9028 39.6681 40.3747 39.6681L25.6761 39.6679L23.9297 41.7054V43.1607C23.9297 44.7617 25.0941 46.1441 26.5493 46.1441H41.9758L47.4334 52.2566V46.1441H48.3794C49.8347 46.1441 50.9991 44.8343 50.9991 43.1607V28.0978C50.9262 26.4243 49.7619 25.1144 48.3066 25.1144L48.3067 25.1144Z" fill="#C32148"/>
                                                                    </svg>
                                                                </span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 fs20 semibold GrayComn">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore.
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row className={isActive ? 'mb-5 treemenu treemenureview position-relative rowactive' : 'mb-5 treemenu treemenureview position-relative'} onClick={handleClick}>
                                            <Col role="button" 
                                                onClick={() => setOpenReview05(!openReview05)} 
                                                aria-controls="Review-collapse" 
                                                aria-expanded={openReview05} 
                                                md="auto"
                                                className="bg-white">
                                                <span className="rounded-pill border border-danger p-2 d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/coffee05.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                </span>
                                            </Col>
                                            <Col className="contentpart">
                                                <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                    <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                    <Badge bg="light" className="bgLightGray" text="dark">
                                                        <span className="rating d-block fs18">
                                                            <BsStarFill size="16" className="align-text-top me-1" color="var(--rating)" /> 
                                                            <strong className="position-relative GrayDark">4.5</strong>
                                                        </span>
                                                    </Badge>
                                                    <span className="float-end fs16 graya1a">Today</span>
                                                </h6>
                                                <Col as="div" className="order-des px-2 shadowMD py-2 grayf7f border04 d-inline-block mb-3">
                                                        <ListGroup className='flex-row'>
                                                            <ListGroup.Item className='border-0 px-2 py-0 colrtranparent'>
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-start border-end py-0 px-2 border-0 semibold fs20 GrayComn colrtranparent'>
                                                            <Col as="span" className='d-inline-blcok themeColor'>Order ID :</Col> A17725GN6CX
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-0 py-0 semibold px-2 fs20 GrayComn colrtranparent'>
                                                        <svg className='align-text-top me-2' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.89436 2.72603C2.89451 2.72603 2.89465 2.72603 2.8948 2.72603L2.89438 2.74329L2.89436 2.72603ZM3.1619 2.72602L4.41464 1.13477L13.0245 2.72602H3.1619ZM13.0889 2.72602H13.0877C13.0881 2.72602 13.0885 2.72602 13.0889 2.72602ZM4.35015 1.13477H4.35143C4.351 1.13477 4.35058 1.13477 4.35015 1.13477V1.13477Z" stroke="#C32148"/>
                                                            <path d="M19.8684 20.2558L16.9469 18.6828V7.22099L19.8684 20.2558Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M17.9346 20.9647H14.2568L16.1637 20.0112L17.9346 20.9647Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M15.4066 18.6668L12.1317 20.304L15.4066 6.73687V18.6668Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M5.05764 4.46732H13.0866C13.5812 4.46856 14.0485 4.24143 14.3532 3.8521C14.3534 3.85189 14.3535 3.85167 14.3537 3.85146L14.4435 3.73735L10.3093 20.8657H0.65206L5.05764 4.46732Z" stroke="#C32148"/>
                                                            <path d="M6.36134 6.47914C6.4248 6.26769 6.61944 6.12288 6.84021 6.12287L11.4484 6.12268C11.7764 6.12267 12.0156 6.43316 11.9319 6.75029L10.9972 10.2915C10.9392 10.5109 10.7407 10.6639 10.5137 10.6639L5.77748 10.6639C5.44253 10.6639 5.2023 10.3409 5.29859 10.0201L6.36134 6.47914Z" stroke="#C32148"/>
                                                        </svg>
                                                        Pickup
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col as="div" className="fs20 medium pe-5 mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam 
                                                    ultrices bibendum. Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus 
                                                    amet consectetur ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                                </Col>
                                                <ListGroup horizontal className="justify-content-between align-items-center">
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <FiEdit role="button" size="22" className="me-4" color="var(--theme-color)" />
                                                        <HiOutlineTrash role="button" size="24" color="var(--theme-color)" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                {/* review container */}
                                                <Collapse className="mt-4 shadowXS mx-1 tree-active treeReview position-relative" in={openReview05}>
                                                    <Col as="div" id="Review-collapse">
                                                        <ListGroup horizontal className="align-items-center">
                                                            <ListGroup.Item className="border-0 p-0 me-4">
                                                                <span className="shadowXS rounded-pill p-0 d-inline-block">
                                                                    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.0214 38.2135H40.4479C41.9032 38.2135 43.0676 36.9037 43.0676 35.2301V20.2396C43.0676 18.6386 41.9032 17.2562 40.4479 17.2562H18.6177C17.1624 17.2562 15.998 18.566 15.998 20.2396V35.3025C15.998 36.9035 17.1624 38.2859 18.6177 38.2859H19.5637V44.3983L25.0214 38.2135ZM37.683 25.3337C38.9928 25.3337 40.0843 26.4252 40.0843 27.735C40.0843 29.0448 38.9928 30.1363 37.683 30.1363C36.3732 30.1363 35.2816 29.0448 35.2816 27.735C35.2816 26.4252 36.3732 25.3337 37.683 25.3337ZM29.5328 25.3337C30.8426 25.3337 31.9341 26.4252 31.9341 27.735C31.9341 29.0448 30.8426 30.1363 29.5328 30.1363C28.223 30.1363 27.1315 29.0448 27.1315 27.735C27.1315 26.4252 28.223 25.3337 29.5328 25.3337ZM18.9813 27.735C18.9813 26.4252 20.0728 25.3337 21.3826 25.3337C22.6925 25.3337 23.784 26.4252 23.784 27.735C23.784 29.0448 22.6925 30.1363 21.3826 30.1363C20.0002 30.1363 18.9813 29.0448 18.9813 27.735Z" fill="#C32148"/>
                                                                        <path d="M48.3067 25.1144H44.5227V33.4099C44.8139 33.2645 45.1776 33.1916 45.5414 33.1916C46.8512 33.1916 47.9427 34.2831 47.9427 35.593C47.9427 36.9028 46.8512 37.9943 45.5414 37.9943C44.9593 37.9943 44.377 37.776 43.9404 37.4122C43.2127 38.722 41.9028 39.6681 40.3747 39.6681L25.6761 39.6679L23.9297 41.7054V43.1607C23.9297 44.7617 25.0941 46.1441 26.5493 46.1441H41.9758L47.4334 52.2566V46.1441H48.3794C49.8347 46.1441 50.9991 44.8343 50.9991 43.1607V28.0978C50.9262 26.4243 49.7619 25.1144 48.3066 25.1144L48.3067 25.1144Z" fill="#C32148"/>
                                                                    </svg>
                                                                </span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 fs20 semibold GrayComn">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore.
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row className={isActive ? 'mb-5 treemenu treemenureview position-relative rowactive' : 'mb-5 treemenu treemenureview position-relative'} onClick={handleClick}>
                                            <Col role="button" 
                                                onClick={() => setOpenReview06(!openReview06)} 
                                                aria-controls="Review-collapse" 
                                                aria-expanded={openReview06} 
                                                md="auto"
                                                className="bg-white">
                                                <span className="rounded-pill border border-danger p-2 d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/coffee06.png"} width={90} height={90} className="rounded-pill border border-danger" alt="pic" />
                                                </span>
                                            </Col>
                                            <Col className="contentpart">
                                                <h6><span className="fs22 semibold">Arlene McCoy</span>
                                                    <BsPatchCheckFill className="ms-2 me-2" size="16" color="var(--bs-greenDark)" /> 
                                                    <Badge bg="light" className="bgLightGray" text="dark">
                                                        <span className="rating d-block fs18">
                                                            <BsStarFill size="16" className="align-text-top me-1" color="var(--rating)" /> 
                                                            <strong className="position-relative GrayDark">4.5</strong>
                                                        </span>
                                                    </Badge>
                                                    <span className="float-end fs16 graya1a">Today</span>
                                                </h6>
                                                <Col as="div" className="order-des px-2 shadowMD py-2 grayf7f border04 d-inline-block mb-3">
                                                        <ListGroup className='flex-row'>
                                                            <ListGroup.Item className='border-0 px-2 py-0 colrtranparent'>
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                            <AiFillStar size="22" color="var(--rating" />
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-start border-end py-0 px-2 border-0 semibold fs20 GrayComn colrtranparent'>
                                                            <Col as="span" className='d-inline-blcok themeColor'>Order ID :</Col> A17725GN6CX
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='border-0 py-0 semibold px-2 fs20 GrayComn colrtranparent'>
                                                        <svg className='align-text-top me-2' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.89436 2.72603C2.89451 2.72603 2.89465 2.72603 2.8948 2.72603L2.89438 2.74329L2.89436 2.72603ZM3.1619 2.72602L4.41464 1.13477L13.0245 2.72602H3.1619ZM13.0889 2.72602H13.0877C13.0881 2.72602 13.0885 2.72602 13.0889 2.72602ZM4.35015 1.13477H4.35143C4.351 1.13477 4.35058 1.13477 4.35015 1.13477V1.13477Z" stroke="#C32148"/>
                                                            <path d="M19.8684 20.2558L16.9469 18.6828V7.22099L19.8684 20.2558Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M17.9346 20.9647H14.2568L16.1637 20.0112L17.9346 20.9647Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M15.4066 18.6668L12.1317 20.304L15.4066 6.73687V18.6668Z" stroke="#C32148" stroke-width="0.8"/>
                                                            <path d="M5.05764 4.46732H13.0866C13.5812 4.46856 14.0485 4.24143 14.3532 3.8521C14.3534 3.85189 14.3535 3.85167 14.3537 3.85146L14.4435 3.73735L10.3093 20.8657H0.65206L5.05764 4.46732Z" stroke="#C32148"/>
                                                            <path d="M6.36134 6.47914C6.4248 6.26769 6.61944 6.12288 6.84021 6.12287L11.4484 6.12268C11.7764 6.12267 12.0156 6.43316 11.9319 6.75029L10.9972 10.2915C10.9392 10.5109 10.7407 10.6639 10.5137 10.6639L5.77748 10.6639C5.44253 10.6639 5.2023 10.3409 5.29859 10.0201L6.36134 6.47914Z" stroke="#C32148"/>
                                                        </svg>
                                                        Pickup
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col as="div" className="fs20 medium pe-5 mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum consectetur risus vitae etiam id mi quam 
                                                    ultrices bibendum. Iaculis sit ac donec velit leo. Nunc eget in tincidunt at mi consectetur adipiscing amet. Massa urna cursus 
                                                    amet consectetur ut aliquet.Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                                </Col>
                                                <ListGroup horizontal className="justify-content-between align-items-center">
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img01.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img02.png"} className="rounded-4 me-2" alt=""/>
                                                        <img src={process.env.PUBLIC_URL+"assets/rv-img03.png"} className="rounded-4"      alt=""/>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <FiEdit role="button" size="22" className="me-4" color="var(--theme-color)" />
                                                        <HiOutlineTrash role="button" size="24" color="var(--theme-color)" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                {/* review container */}
                                                <Collapse className="mt-4 shadowXS mx-1 tree-active treeReview position-relative" in={openReview06}>
                                                    <Col as="div" id="Review-collapse">
                                                        <ListGroup horizontal className="align-items-center">
                                                            <ListGroup.Item className="border-0 p-0 me-4">
                                                                <span className="shadowXS rounded-pill p-0 d-inline-block">
                                                                    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M25.0214 38.2135H40.4479C41.9032 38.2135 43.0676 36.9037 43.0676 35.2301V20.2396C43.0676 18.6386 41.9032 17.2562 40.4479 17.2562H18.6177C17.1624 17.2562 15.998 18.566 15.998 20.2396V35.3025C15.998 36.9035 17.1624 38.2859 18.6177 38.2859H19.5637V44.3983L25.0214 38.2135ZM37.683 25.3337C38.9928 25.3337 40.0843 26.4252 40.0843 27.735C40.0843 29.0448 38.9928 30.1363 37.683 30.1363C36.3732 30.1363 35.2816 29.0448 35.2816 27.735C35.2816 26.4252 36.3732 25.3337 37.683 25.3337ZM29.5328 25.3337C30.8426 25.3337 31.9341 26.4252 31.9341 27.735C31.9341 29.0448 30.8426 30.1363 29.5328 30.1363C28.223 30.1363 27.1315 29.0448 27.1315 27.735C27.1315 26.4252 28.223 25.3337 29.5328 25.3337ZM18.9813 27.735C18.9813 26.4252 20.0728 25.3337 21.3826 25.3337C22.6925 25.3337 23.784 26.4252 23.784 27.735C23.784 29.0448 22.6925 30.1363 21.3826 30.1363C20.0002 30.1363 18.9813 29.0448 18.9813 27.735Z" fill="#C32148"/>
                                                                        <path d="M48.3067 25.1144H44.5227V33.4099C44.8139 33.2645 45.1776 33.1916 45.5414 33.1916C46.8512 33.1916 47.9427 34.2831 47.9427 35.593C47.9427 36.9028 46.8512 37.9943 45.5414 37.9943C44.9593 37.9943 44.377 37.776 43.9404 37.4122C43.2127 38.722 41.9028 39.6681 40.3747 39.6681L25.6761 39.6679L23.9297 41.7054V43.1607C23.9297 44.7617 25.0941 46.1441 26.5493 46.1441H41.9758L47.4334 52.2566V46.1441H48.3794C49.8347 46.1441 50.9991 44.8343 50.9991 43.1607V28.0978C50.9262 26.4243 49.7619 25.1144 48.3066 25.1144L48.3067 25.1144Z" fill="#C32148"/>
                                                                    </svg>
                                                                </span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="border-0 p-0 fs20 semibold GrayComn">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun sed do eiu ut labore.
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    </ScrollArea>
                                </Col>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )    
}
export default AllReviews