import React, {useState} from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "./identity.css"
import Header from "../header";
import Footer from "../footer";
import ProfileMenu from "../profilemenu";

const IdentityStep01 = () => {    

  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
  };  

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0"><Header /></Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label"><ProfileMenu /></Col>
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4 mb-0 GrayComn">My Profile</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Verify My Identity</Breadcrumb.Item>                            
                        </Breadcrumb>
                        <Col as="section" className="stepWrap ms-md-4 pt-md-5">
                            <Row className="text-center justify-content-center pb-4">
                                <Col lg="7" className="text-start">
                                    <h2 className="fs22 GrayComn semibold">Please choose your method of registration</h2>
                                    <Col lg="12" as="section" className="stepsBar2 pt-4">
                                        <Col as="ul" className="list-unstyled">
                                            <Col as="li" className="p2 active first"><span className="fs14" style={{marginLeft: "calc(100%/6)"}}>1</span></Col>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="text-center justify-content-center">
                                <Col xs={12} lg="10" xl="7">
                                    <Row className={isActive ? "bg-theme" : ""} onClick={handleClick}>
                                        <Col xs={12} md="6">
                                            <div role="button" className="porleft bgtheme border08 pb-3">
                                                <svg className="cls01" width={172} height={100} fill="#fff" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172.52 99.94">
                                                    <path d="M161.02,99.94H11.5c-6.34,0-11.5-5.17-11.5-11.53V11.53C0,5.17,5.16,0,11.5,0H161.02c6.34,0,11.5,5.17,11.5,11.53V88.41c0,6.36-5.16,11.53-11.5,11.53ZM11.5,3C6.81,3,3,6.83,3,11.53V88.41c0,4.71,3.81,8.53,8.5,8.53H161.02c4.69,0,8.5-3.83,8.5-8.53V11.53c0-4.7-3.81-8.53-8.5-8.53H11.5ZM77.76,77.95H21.92v-6.18c0-5.19,3.15-10.22,8.65-13.79,5.21-3.38,12.06-5.25,19.27-5.25s14.06,1.86,19.27,5.25c5.5,3.57,8.65,8.59,8.65,13.79v6.18Zm-52.84-3h49.84v-3.18c0-4.22-2.59-8.22-7.28-11.27-4.73-3.07-10.99-4.76-17.64-4.76s-12.9,1.69-17.64,4.76c-4.7,3.05-7.28,7.05-7.28,11.27v3.18Zm128.43,3h-59.42c-3.77,0-6.83-3.07-6.83-6.84s3.07-6.84,6.83-6.84h59.42c3.77,0,6.83,3.07,6.83,6.84s-3.07,6.84-6.83,6.84Zm-59.42-10.69c-2.11,0-3.83,1.72-3.83,3.84s1.72,3.84,3.83,3.84h59.42c2.11,0,3.83-1.72,3.83-3.84s-1.72-3.84-3.83-3.84h-59.42Zm59.42-10.45h-59.42c-3.77,0-6.83-3.07-6.83-6.84s3.07-6.84,6.83-6.84h59.42c3.77,0,6.83,3.07,6.83,6.84s-3.07,6.84-6.83,6.84Zm-59.42-10.69c-2.11,0-3.83,1.72-3.83,3.84s1.72,3.84,3.83,3.84h59.42c2.11,0,3.83-1.72,3.83-3.84s-1.72-3.84-3.83-3.84h-59.42Zm-44.09,4.92c-8,0-14.5-6.52-14.5-14.53s6.51-14.53,14.5-14.53,14.5,6.52,14.5,14.53-6.51,14.53-14.5,14.53Zm0-26.06c-6.34,0-11.5,5.17-11.5,11.53s5.16,11.53,11.5,11.53,11.5-5.17,11.5-11.53-5.16-11.53-11.5-11.53Zm103.51,10.69h-59.42c-3.77,0-6.83-3.07-6.83-6.84s3.07-6.84,6.83-6.84h59.42c3.77,0,6.83,3.07,6.83,6.84s-3.07,6.84-6.83,6.84Zm-59.42-10.69c-2.11,0-3.83,1.72-3.83,3.84s1.72,3.84,3.83,3.84h59.42c2.11,0,3.83-1.72,3.83-3.84s-1.72-3.84-3.83-3.84h-59.42Z"/>
                                                </svg>
                                                <h2 className="fs20 text-white semibold pb-5">NRIC / Passport</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} md="6">
                                            <div role="button" className="porright iconright border08 pb-3">
                                                <img src={process.env.PUBLIC_URL+"assets/Minfo100.png"} className="img-fluid cls01 img01" alt="myinfo" />
                                                <img src={process.env.PUBLIC_URL+"assets/Minfo100a.png"} className="img-fluid cls01 img02" alt="myinfo" />
                                                <h2 className="fs20 GrayComn semibold pb-5">Singpass MyInfo*</h2>
                                            </div>
                                        </Col>                                
                                    </Row>
                                    <Row className="text-start pt-4">
                                        <Col>
                                            <h2 className="fs16 GrayComn semibold">*For Singapore Citizens and Permanent Residents only.</h2>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>        
            </Container>
            <Footer />
        </div>
    )    
}
export default IdentityStep01