import React, { useState } from 'react'
import { Container, Row, Col, Button, Badge, Form, Modal } from 'react-bootstrap';
import { FiEye, FiChevronDown, FiChevronRight } from "react-icons/fi";
import { HiCheck, HiOutlineCamera } from "react-icons/hi";
import { BsPatchCheckFill } from 'react-icons/bs';
import { BiUpArrow } from "react-icons/bi";
import { RiCloseCircleFill, RiAddCircleFill } from "react-icons/ri";
import { TbCirclePlus } from "react-icons/tb";
import { AiFillCloseCircle } from "react-icons/ai";
import "react-pro-sidebar/dist/css/styles.css";

import "./account.css"
import Header from "../header";
import Footer from "../footer";
import ProfileMenu from "../profilemenu";

import { Link } from 'react-router-dom';
import SwipeButton from "react-swipezor";
import OTPInput, { ResendOTP } from "otp-input-react";


const MyProfile = () => {    
    //switch icons
    const handleClick = event => {
        event.currentTarget.classList.toggle('switchUser');   
    };

    //for mobile view
    const [formValues, setFormValues] = useState([{ name: "", email : ""}])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { name: "", email: "" }])        
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);        
    }
    
    let handleSubmit = (event) => {
        event.preventDefault();
    }

    const [show, setShow] = useState(false);
    const [show01, setShow01] = useState(false);
    const handleClose01 = () => {
        setShow(false)
        setShow01(false); 
        setNewClass(false);
    }
    const [newClass, setNewClass] = useState(false);

    //opt inputs 
    const [OTP, setOTP] = useState("");

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        
                            <ProfileMenu />
                    </Col>                    
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4">My Profile</h2>
                        <Col as="section" className="shadowSM mt-5 ms-md-4 me-xl-5 pdAround">
                            <Badge bg="white" className="d-block p-0">
                                <span className="rounded-pill border border-3 border-danger p-1 d-inline-block position-relative">
                                    <img src="/assets/frame02_old.png" width={150} height={150} className="rounded-pill border border-2 border-danger" alt="navimg" />
                                    <span className="position-absolute cameraIcon bgLight d-inline-block rounded-pill d-flex align-items-center justify-content-center">
                                        <HiOutlineCamera size="28" color="var(--theme-color)" />
                                    </span>
                                    <Form.Group controlId="formFile" className="cameraChooseFile">
                                        <Form.Control type="file" />
                                    </Form.Group>
                                </span>
                                <h5 className="semibold fs26 GrayComn mt-3 mb-1">John Doe <BsPatchCheckFill className="checkfill" size="22" color="var(--bs-greenDark)" /> </h5>
                                <p className="semibold fs14 gray82"><FiEye size="19" color="var(--theme-color)" /> Total Reviews 2048</p>
                            </Badge>
                            <Form className="mt-4 pt-5 accForm">
                                <Row className="pb-4">
                                    <Col className="fs20 semibold GrayComn">Personal Details</Col>
                                    <Col className="fs16 semibold themeColor text-end">Verify my Identity</Col>
                                </Row>
                                <Row className="g_flutter gx-4">
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="John" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Doe" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Control type="text" placeholder="Male" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Date Of Birth</Form.Label>
                                            <Form.Control className="bgLight" type="text" placeholder="25 November 1995" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control type="number" placeholder="+65 9215 2547" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control className="bgLight" type="email" placeholder="johndoe123@hotmail.com" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="12" xl="6">
                                        <h5 className="fs20 semibold GrayComn mb-4 mt-3">
                                            Account Security 
                                            <span className="fs12 semibold d-block">Change Password</span>
                                        </h5>
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Current Password</Form.Label>
                                            <Form.Control type="password" placeholder="**********" />
                                        </Form.Group>
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control type="password" placeholder="**********" />
                                        </Form.Group>
                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Re-enter Password</Form.Label>
                                            <Form.Control type="password" placeholder="**********" />
                                        </Form.Group>
                                        <Button className="w-100 border-0 mt-2" type="submit"><span className="regular">Update Password</span></Button>
                                    </Col>
                                    <Col xs="12" md="12" xl="6">
                                        <h5 className="fs20 semibold GrayComn mb-4 mt-3">
                                            &nbsp; 
                                            <span className="fs12 semibold d-block">Set Account 2FA Method</span>
                                        </h5>
                                        <Col as="section" className="signpUser row text-center" onClick={handleClick}>
                                            <Col sm="12" md="6" className="dineUser">
                                                <div className="iconleft">
                                                <svg width="82" height="64" viewBox="0 0 82 64" fill="none">
                                                    <path d="M2.83205 13.4189C2.14276 12.9594 1.21145 13.1457 0.751925 13.8349C0.292396 14.5242 0.478657 15.4555 1.16795 15.9151L2.83205 13.4189ZM36.1926 37.4621L37.0247 36.214L37.0247 36.214L36.1926 37.4621ZM45.8074 37.4621L44.9753 36.214L44.9753 36.214L45.8074 37.4621ZM80.8321 15.9151C81.5213 15.4555 81.7076 14.5242 81.2481 13.8349C80.7886 13.1457 79.8572 12.9594 79.168 13.4189L80.8321 15.9151ZM1.16795 15.9151L35.3606 38.7101L37.0247 36.214L2.83205 13.4189L1.16795 15.9151ZM35.3605 38.7101C38.7755 40.9869 43.2245 40.9869 46.6395 38.7101L44.9753 36.214C42.568 37.819 39.432 37.819 37.0247 36.214L35.3605 38.7101ZM46.6395 38.7101L80.8321 15.9151L79.168 13.4189L44.9753 36.214L46.6395 38.7101ZM10.6667 63.8337H71.3333V60.8337H10.6667V63.8337ZM71.3333 63.8337C76.9484 63.8337 81.5 59.282 81.5 53.667H78.5C78.5 57.6252 75.2915 60.8337 71.3333 60.8337V63.8337ZM81.5 53.667V10.3337H78.5V53.667H81.5ZM81.5 10.3337C81.5 4.71878 76.9484 0.166992 71.3333 0.166992V3.16699C75.2915 3.16699 78.5 6.3756 78.5 10.3337H81.5ZM71.3333 0.166992H10.6667V3.16699H71.3333V0.166992ZM10.6667 0.166992C5.05177 0.166992 0.5 4.71876 0.5 10.3337H3.5C3.5 6.37562 6.70862 3.16699 10.6667 3.16699V0.166992ZM0.5 10.3337V53.667H3.5V10.3337H0.5ZM0.5 53.667C0.5 59.282 5.05178 63.8337 10.6667 63.8337V60.8337C6.70861 60.8337 3.5 57.6252 3.5 53.667H0.5Z" fill="white"/>
                                                </svg>
                                                <h4 className="text-white mt-4 pb-5 lh-base">Email</h4>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6" className="hostUser">
                                                <div className="iconright mobIcn">
                                                <svg width="52" height="89" viewBox="0 0 52 89" fill="none">
                                                    <path d="M44.3965 0H7.6049C3.66131 0 0.451172 3.21014 0.451172 7.15373V81.2478C0.451172 85.1914 3.66131 88.4016 7.6049 88.4016H44.3965C48.3401 88.4016 51.5502 85.1914 51.5502 81.2478V7.15373C51.5502 3.21014 48.3401 0 44.3965 0ZM7.6049 4.08759H44.3965C46.0867 4.08759 47.4626 5.46346 47.4626 7.15373V12.1525H4.53912V7.15373C4.53912 5.46349 5.91499 4.08759 7.60527 4.08759H7.6049ZM47.4624 16.2401V65.9179H4.53894V16.2401H47.4624ZM44.3963 84.3136H7.60472C5.91448 84.3136 4.53857 82.9378 4.53857 81.2475V70.006H47.4621V81.2482C47.4628 82.9377 46.0869 84.3136 44.3966 84.3136H44.3963Z" fill="#404040"/>
                                                </svg>
                                                <h4 className="mt-4 pb-5 lh-base">Mobile</h4>
                                                </div>
                                            </Col>
                                        </Col>                                            
                                        {/* checkbox */}
                                        <Col as="section" className="row profilecheckbox mt-2">
                                            <Col className="pt-1">
                                                <div className="form-check position-relative mb-2">
                                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="mypro01" />
                                                    <label className="form-check-label text-truncate overflow-hidden w-100" for="mypro01"><span>Authenticate when logging in.</span></label>
                                                </div>
                                                <div className="form-check position-relative mb-2">
                                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="mypro02" />
                                                    <label className="form-check-label text-truncate overflow-hidden w-100" for="mypro02"><span>Authenticate when placing an order.</span></label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-check position-relative">
                                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="mypro03" />
                                                    <label className="form-check-label" for="mypro03"><span className="lh-sm d-inline-block">Authenticate when making any account related changes</span></label>
                                                </div>
                                            </Col>
                                        </Col>
                                    </Col>
                                    {/* popupover tooltip */}
                                    <Col as="section" md="12" className="mt-5">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            Dietary Preference
                                            <span className="fs12 semibold d-block pt-1">Please set your default dietary preference filter. Leave blank if there are none.</span>
                                        </h5>
                                        <Row className="justify-content-between">
                                            <Col md="auto">
                                                <Badge pill className="menuTag badgeColor fs16 pe-1 me-3 mb-1">Vegan <RiCloseCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                <Badge pill className="menuTag badgeColor fs16 pe-1 me-3 mb-1">Vegetarian <RiCloseCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                <Badge pill className="menuTag badgeColor fs16 pe-1 me-3 mb-1">Pescatarian <RiCloseCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                            </Col>
                                            <Col md="auto" className="fs22 themeColor bold">
                                                <Col as="div" className="text-end pluMinus" role="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><TbCirclePlus size="24" color="var(--theme-color" className="align-text-top" /> Add</Col>
                                            </Col>
                                            <Col md="12" as="div" className="collapse" id="collapseExample">
                                                <Col as="div" className="badgeOpen">
                                                    <BiUpArrow size="20" color="var(--bs-white)" className="uparrow" />                                                    
                                                    <Badge pill bg="light" className="menuTag themeColor fs16 pe-1 me-3">Halal <RiAddCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                    <Badge pill bg="light" className="menuTag themeColor fs16 pe-1 me-3">Kosher <RiAddCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                    <Badge pill bg="light" className="menuTag themeColor fs16 pe-1 me-3">Gluten-Free <RiAddCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col as="section" md="12" className="mt-5">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            Food Allergen
                                            <span className="fs12 semibold d-block pt-1">Please set your default major food allergen filters. Leave blank if there are none.</span>
                                        </h5>
                                        <Row className="justify-content-between">
                                            <Col md="auto">
                                                <Badge pill className="menuTag badgeColor fs16 pe-1 me-3 mb-1">Seafood (Shellfish) <RiCloseCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                <Badge pill className="menuTag badgeColor fs16 pe-1 me-3 mb-1">Seafood (Fish) <RiCloseCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                <Badge pill className="menuTag badgeColor fs16 pe-1 me-3 mb-1">Pescatarian <RiCloseCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                            </Col>
                                            <Col md="auto" className="fs22 themeColor bold">
                                                <Col as="div" className="text-end pluMinus" role="button" data-bs-toggle="collapse" data-bs-target="#collapseExample02" aria-expanded="false" aria-controls="collapseExample02"><TbCirclePlus size="24" color="var(--theme-color" className="align-text-top" /> Add</Col>
                                            </Col>
                                            <Col md="12" as="div" className="collapse" id="collapseExample02">
                                                <Col as="div" className="badgeOpen">
                                                    <BiUpArrow size="20" color="var(--bs-white)" className="uparrow" />                                                    
                                                    <Badge pill bg="light" className="menuTag themeColor fs16 pe-1 me-3">Dairy <RiAddCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                    <Badge pill bg="light" className="menuTag themeColor fs16 pe-1 me-3">Nuts (Ground Nuts) <RiAddCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                    <Badge pill bg="light" className="menuTag themeColor fs16 pe-1 me-3">Soy Bean <RiAddCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                    <Badge pill bg="light" className="menuTag themeColor fs16 pe-1 me-3">Wheat <RiAddCircleFill role="button" size="20" color="var(--theme-color)" /></Badge>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col as="section" md="12" className="mt-2">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            &nbsp;
                                            <span className="fs14 semibold d-block pt-1">
                                                *Always check on the contents / ingredients with the Host(s), prior to purchase. 
                                                While we endeavour to facilitate a safe environment, <br />we shall not be held liable for any damages or injury suffered as a result of an allergic reaction.</span>
                                        </h5>                                    
                                    </Col>
                                    <Col as="section" md="12" className="mt-4">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            Emergency Contact 
                                            <span className="fs12 semibold d-block pt-1">Please provide your emergency contact details.</span>
                                        </h5>                                            
                                        <Form className="mt-2 pt-2 accForm" onSubmit={handleSubmit}>
                                            <span className="fs12 semibold d-block mb-4">Contact 1 :</span>
                                                <Row className="g_flutter gx-4">
                                                    <Col xs="12" md="6">
                                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Michael" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" md="6">
                                                        <Form.Group className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Doe" />
                                                        </Form.Group>
                                                    </Col>                                                
                                                </Row>
                                                {formValues.map((element, index) => (
                                                    <div className="form-inline position-relative" key={index}>
                                                        <Row className="g_flutter gx-4">
                                                            <Col xs="12" md="6">
                                                                <Form.Group value={element.name || ""} onChange={e => handleChange(index, e)} className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>First Name</Form.Label>
                                                                    <Form.Control type="text" placeholder="Michael" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs="12" md="6">
                                                                <Form.Group value={element.email || ""} onChange={e => handleChange(index, e)} className="mb-3 pb-1 position-relative" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Last Name</Form.Label>
                                                                    <Form.Control type="text" placeholder="Doe" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        {
                                                        index ?
                                                            <AiFillCloseCircle size="35" color="var(--theme-color)" className="removeField position-absolute" onClick={() => removeFormFields(index)} />                                                
                                                        : null
                                                        }
                                                    </div>
                                                ))}
                                            <Col as="section" md="12" className="fs22 themeColor bold">
                                                <Col as="div" role="button" onClick={() => addFormFields()}>
                                                    <TbCirclePlus size="24" color="var(--theme-color)" className="align-text-top" /> Add Emergency Contact</Col>
                                            </Col>
                                            <br />
                                            <Button className="button submit col-md-5 m-auto d-block mt-5 mb-3" type="submit">Save</Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col as="section" className="shadowSM shadowSMacc mt-5 ms-md-4 me-md-5 text-center py-5">
                            <h5 className="fs20 semibold GrayComn mb-5">
                                Account Management 
                                <span className="fs12 semibold d-block pt-2">Decide what you would like to do with your Cribbly Account.</span>
                            </h5>
                            <Button onClick={setShow01} className="button rounded-pill fs20 susAcc bgLight GrayComn border-0 px-4 me-2" variant="danger" type="submit">Suspend Account</Button>
                            <Button className="button rounded-pill fs20 cloAcc border-0 px-4" variant="danger" type="submit">Close Account</Button>
                        </Col>
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
            <Modal 
            className="planModal" 
            backdropClassName="order1 smsFill"
            dialogClassName="order2 sucaccountPop modal-lg"
            contentClassName="order3 border-0 border12"
            show={show01} 
            centered
            onHide={handleClose01}>
            <Modal.Header closeButton className="border-0 closeD flex-row-reverse position-relative p-0 pt-3 pe-3">
            <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="var(--theme-color)"/>
            </svg>
            </Modal.Header>
            <Modal.Body className="modal-input m-0 pt-0">
                <Col className="container">
                    <Row className="justifycontentcenter">
                        <Col sm="12" className="modal-content-inner sminner">
                            <Col as="p" className="semibol fs28 lh-sm mb-0">
                                <h1 class="p-0 fs52 GrayComn semibold col">Suspend Account</h1>
                                <p className="dnone fs20 semibold pt-2">Please key in the OTP sent to your mobile +65 .... 4567.</p>
                            </Col>
                            <Col as="div" className="otpInput">
                                <OTPInput 
                                    className="justify-content-center otpfields"
                                    value={OTP} 
                                    onChange={setOTP} 
                                    OTPLength={6} 
                                    otpType="number" 
                                    disabled={false} 
                                    autoFocus 
                                    secure 
                                />
                                <Row className="justify-content-between mt-3">
                                    <Col className="fs24 black semibold">
                                        <Link to="/" className="fs24 themeColor semibold text-decoration-none">Resend</Link> or <Link to="/" className="fs24 themeColor semibold text-decoration-none">Change Number</Link>
                                    </Col>
                                    <Col className="endOTP col-auto">
                                        <ResendOTP 
                                            onResendClick={() => console.log("Resend clicked")} 
                                            className="ROTP justify-content-end"
                                        />                                    
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>
            <Modal.Footer className="border-0 my-5 p-0 d-block">
                <Col sm="7" className={` ${newClass ? "doneClass m-auto mt-sm-4 px-3" : "m-auto mt-sm-4 px-3"}`}>
                    <SwipeButton
                        mainText="Slide to Confirm"
                        overlayText="Confirmed!"
                        classList="myClass1 cncorder1 border-0 rounded-pill fs26 semibold gray82"
                        caretClassList="my-caret-class myClass2 p-0"
                        overlayClassList="my-overlay-class myClass3"
                        caret={newClass ? <HiCheck size="30" className='GrayDark' /> :<FiChevronRight size="30" className='themeColor' />}
                        onSwipeDone={function () {
                            setNewClass(true)
                            setShow01(true)
                            
                            setTimeout(()=>{
                                setShow(false)
                                setShow01(false)
                                setNewClass(false)
                            },8000)
                        }}
                    />
                </Col>
            </Modal.Footer>
        </Modal>
        </div>
    )    
}
export default MyProfile