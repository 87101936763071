import React from 'react'
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./dinerstyle.css"
import Header from "../header";
import Footer from '../footer';

import { Link } from 'react-router-dom';

const Faq = () => {
    
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col xl="11" xxl="9" as="div" className="userAcc membership pt-4 pe-3 m-auto">
                        <h2 className="fs52 semibold mb-0">Frequently Asked Questions</h2>
                        <span className="faq01"><br /><br /><br /></span>
                        <Col as="section" className="px3">
                            <Col className="outerspacep-0">
                                <Col as="section" className="innerspace">
                                    <Row className="ms4 me5 faqBox m-0">
                                        <Col className="faq_wrapper p-0">
                                            <Form className="faqForm position-relative">
                                                <Form.Control placeholder="Find a Topic" className="border-0 gray999" />
                                                <Button variant="light" className="bg-transparent position-absolute top-0 end-0 border-0">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L18.2929 19.7071ZM13.7071 12.2929C13.3166 11.9024 12.6834 11.9024 12.2929 12.2929C11.9024 12.6834 11.9024 13.3166 12.2929 13.7071L13.7071 12.2929ZM19.7071 18.2929L13.7071 12.2929L12.2929 13.7071L18.2929 19.7071L19.7071 18.2929ZM14 8C14 11.3137 11.3137 14 8 14V16C12.4183 16 16 12.4183 16 8H14ZM8 14C4.68629 14 2 11.3137 2 8H0C0 12.4183 3.58173 16 8 16V14ZM2 8C2 4.68629 4.68629 2 8 2V0C3.58173 0 0 3.58173 0 8H2ZM8 2C11.3137 2 14 4.68629 14 8H16C16 3.58173 12.4183 0 8 0V2Z" fill="#404040"/>
                                                </svg>
                                                </Button>
                                            </Form>
                                            <span className="faq02"><br /><br /></span>
                                            <Row>
                                                <Col md="6" lg="4" className="mb-4 pb-1">
                                                    <Card className="faqQtypes border-0 shadowMD border08">
                                                        <Card.Body>
                                                            <Card.Title className="fs32 semibold themeColor mb-3 pb-1">
                                                                <svg className="position-relative me-1" width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.7998 25.8008H0.549805V27.0508H1.7998V25.8008ZM24.1998 25.8008V27.0508H25.4498V25.8008H24.1998ZM12.9998 12.6508C16.3412 12.6508 19.0498 9.94208 19.0498 6.60078H16.5498C16.5498 8.5614 14.9605 10.1508 12.9998 10.1508V12.6508ZM19.0498 6.60078C19.0498 3.25948 16.3412 0.550781 12.9998 0.550781V3.05078C14.9605 3.05078 16.5498 4.64016 16.5498 6.60078H19.0498ZM12.9998 0.550781C9.65849 0.550781 6.9498 3.25947 6.9498 6.60078H9.4498C9.4498 4.64018 11.0392 3.05078 12.9998 3.05078V0.550781ZM6.9498 6.60078C6.9498 9.9421 9.65849 12.6508 12.9998 12.6508V10.1508C11.0392 10.1508 9.4498 8.56138 9.4498 6.60078H6.9498ZM3.0498 25.8008C3.0498 20.3055 7.50458 15.8508 12.9998 15.8508V13.3508C6.12387 13.3508 0.549805 18.9248 0.549805 25.8008H3.0498ZM12.9998 15.8508C18.4951 15.8508 22.9498 20.3055 22.9498 25.8008H25.4498C25.4498 18.9248 19.8758 13.3508 12.9998 13.3508V15.8508ZM24.1998 24.5508H1.7998V27.0508H24.1998V24.5508Z" fill="#C32148"/>
                                                                </svg> My Profile</Card.Title>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Deserunt amet minim mollit ullamco est.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Mollit non deserunt ullamco est sit.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Aliqua dolor do amet sintduis enim.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Exercitation veniam consequat.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Ullamco est sit dolor sintduis exercitation.
                                                            </Card.Text>
                                                            <Card.Link href="/" className="fs20 semibold themeColor text-decoration-none mt-2 d-inline-block">View All</Card.Link>                                                            
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" lg="4" className="mb-4 pb-1">
                                                    <Card className="faqQtypes border-0 shadowMD border08">
                                                        <Card.Body>
                                                            <Card.Title className="fs32 semibold themeColor mb-3 pb-1">
                                                                <svg className="position-relative me-1" width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.7998 25.8008H0.549805V27.0508H1.7998V25.8008ZM24.1998 25.8008V27.0508H25.4498V25.8008H24.1998ZM12.9998 12.6508C16.3412 12.6508 19.0498 9.94208 19.0498 6.60078H16.5498C16.5498 8.5614 14.9605 10.1508 12.9998 10.1508V12.6508ZM19.0498 6.60078C19.0498 3.25948 16.3412 0.550781 12.9998 0.550781V3.05078C14.9605 3.05078 16.5498 4.64016 16.5498 6.60078H19.0498ZM12.9998 0.550781C9.65849 0.550781 6.9498 3.25947 6.9498 6.60078H9.4498C9.4498 4.64018 11.0392 3.05078 12.9998 3.05078V0.550781ZM6.9498 6.60078C6.9498 9.9421 9.65849 12.6508 12.9998 12.6508V10.1508C11.0392 10.1508 9.4498 8.56138 9.4498 6.60078H6.9498ZM3.0498 25.8008C3.0498 20.3055 7.50458 15.8508 12.9998 15.8508V13.3508C6.12387 13.3508 0.549805 18.9248 0.549805 25.8008H3.0498ZM12.9998 15.8508C18.4951 15.8508 22.9498 20.3055 22.9498 25.8008H25.4498C25.4498 18.9248 19.8758 13.3508 12.9998 13.3508V15.8508ZM24.1998 24.5508H1.7998V27.0508H24.1998V24.5508Z" fill="#C32148"/>
                                                                </svg> Order & Deliveries</Card.Title>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Deserunt amet minim mollit ullamco est.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Mollit non deserunt ullamco est sit.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Aliqua dolor do amet sintduis enim.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Exercitation veniam consequat.
                                                            </Card.Text>
                                                            <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>Ullamco est sit dolor sintduis exercitation.
                                                            </Card.Text>
                                                            <Card.Link href="/" className="fs20 semibold themeColor text-decoration-none mt-2 d-inline-block">View All</Card.Link>                                                            
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" lg="4" className="mb-4 pb-1">
                                                    <Card className="faqQtypes border-0 shadowMD border08">
                                                            <Card.Body>
                                                                <Card.Title className="fs32 semibold themeColor mb-3 pb-1">
                                                                    <svg className="position-relative me-1" width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.7998 25.8008H0.549805V27.0508H1.7998V25.8008ZM24.1998 25.8008V27.0508H25.4498V25.8008H24.1998ZM12.9998 12.6508C16.3412 12.6508 19.0498 9.94208 19.0498 6.60078H16.5498C16.5498 8.5614 14.9605 10.1508 12.9998 10.1508V12.6508ZM19.0498 6.60078C19.0498 3.25948 16.3412 0.550781 12.9998 0.550781V3.05078C14.9605 3.05078 16.5498 4.64016 16.5498 6.60078H19.0498ZM12.9998 0.550781C9.65849 0.550781 6.9498 3.25947 6.9498 6.60078H9.4498C9.4498 4.64018 11.0392 3.05078 12.9998 3.05078V0.550781ZM6.9498 6.60078C6.9498 9.9421 9.65849 12.6508 12.9998 12.6508V10.1508C11.0392 10.1508 9.4498 8.56138 9.4498 6.60078H6.9498ZM3.0498 25.8008C3.0498 20.3055 7.50458 15.8508 12.9998 15.8508V13.3508C6.12387 13.3508 0.549805 18.9248 0.549805 25.8008H3.0498ZM12.9998 15.8508C18.4951 15.8508 22.9498 20.3055 22.9498 25.8008H25.4498C25.4498 18.9248 19.8758 13.3508 12.9998 13.3508V15.8508ZM24.1998 24.5508H1.7998V27.0508H24.1998V24.5508Z" fill="#C32148"/>
                                                                    </svg> Booking Orders</Card.Title>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Deserunt amet minim mollit ullamco est.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Mollit non deserunt ullamco est sit.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Aliqua dolor do amet sintduis enim.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Exercitation veniam consequat.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Ullamco est sit dolor sintduis exercitation.
                                                                </Card.Text>
                                                                <Card.Link href="/" className="fs20 semibold themeColor text-decoration-none mt-2 d-inline-block">View All</Card.Link>                                                            
                                                            </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" lg="4" className="mb-4 pb-1">
                                                    <Card className="faqQtypes border-0 shadowMD border08">
                                                            <Card.Body>
                                                                <Card.Title className="fs32 semibold themeColor mb-3 pb-1">
                                                                    <svg className="position-relative me-1" width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.7998 25.8008H0.549805V27.0508H1.7998V25.8008ZM24.1998 25.8008V27.0508H25.4498V25.8008H24.1998ZM12.9998 12.6508C16.3412 12.6508 19.0498 9.94208 19.0498 6.60078H16.5498C16.5498 8.5614 14.9605 10.1508 12.9998 10.1508V12.6508ZM19.0498 6.60078C19.0498 3.25948 16.3412 0.550781 12.9998 0.550781V3.05078C14.9605 3.05078 16.5498 4.64016 16.5498 6.60078H19.0498ZM12.9998 0.550781C9.65849 0.550781 6.9498 3.25947 6.9498 6.60078H9.4498C9.4498 4.64018 11.0392 3.05078 12.9998 3.05078V0.550781ZM6.9498 6.60078C6.9498 9.9421 9.65849 12.6508 12.9998 12.6508V10.1508C11.0392 10.1508 9.4498 8.56138 9.4498 6.60078H6.9498ZM3.0498 25.8008C3.0498 20.3055 7.50458 15.8508 12.9998 15.8508V13.3508C6.12387 13.3508 0.549805 18.9248 0.549805 25.8008H3.0498ZM12.9998 15.8508C18.4951 15.8508 22.9498 20.3055 22.9498 25.8008H25.4498C25.4498 18.9248 19.8758 13.3508 12.9998 13.3508V15.8508ZM24.1998 24.5508H1.7998V27.0508H24.1998V24.5508Z" fill="#C32148"/>
                                                                    </svg> Reviews & Ratings</Card.Title>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Deserunt amet minim mollit ullamco est.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Mollit non deserunt ullamco est sit.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Aliqua dolor do amet sintduis enim.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Exercitation veniam consequat.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Ullamco est sit dolor sintduis exercitation.
                                                                </Card.Text>
                                                                <Card.Link href="/" className="fs20 semibold themeColor text-decoration-none mt-2 d-inline-block">View All</Card.Link>                                                            
                                                            </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" lg="4" className="mb-4 pb-1">
                                                    <Card className="faqQtypes border-0 shadowMD border08">
                                                            <Card.Body>
                                                                <Card.Title className="fs32 semibold themeColor mb-3 pb-1">
                                                                    <svg className="position-relative me-1" width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.7998 25.8008H0.549805V27.0508H1.7998V25.8008ZM24.1998 25.8008V27.0508H25.4498V25.8008H24.1998ZM12.9998 12.6508C16.3412 12.6508 19.0498 9.94208 19.0498 6.60078H16.5498C16.5498 8.5614 14.9605 10.1508 12.9998 10.1508V12.6508ZM19.0498 6.60078C19.0498 3.25948 16.3412 0.550781 12.9998 0.550781V3.05078C14.9605 3.05078 16.5498 4.64016 16.5498 6.60078H19.0498ZM12.9998 0.550781C9.65849 0.550781 6.9498 3.25947 6.9498 6.60078H9.4498C9.4498 4.64018 11.0392 3.05078 12.9998 3.05078V0.550781ZM6.9498 6.60078C6.9498 9.9421 9.65849 12.6508 12.9998 12.6508V10.1508C11.0392 10.1508 9.4498 8.56138 9.4498 6.60078H6.9498ZM3.0498 25.8008C3.0498 20.3055 7.50458 15.8508 12.9998 15.8508V13.3508C6.12387 13.3508 0.549805 18.9248 0.549805 25.8008H3.0498ZM12.9998 15.8508C18.4951 15.8508 22.9498 20.3055 22.9498 25.8008H25.4498C25.4498 18.9248 19.8758 13.3508 12.9998 13.3508V15.8508ZM24.1998 24.5508H1.7998V27.0508H24.1998V24.5508Z" fill="#C32148"/>
                                                                    </svg> Payment Method</Card.Title>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Deserunt amet minim mollit ullamco est.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Mollit non deserunt ullamco est sit.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Aliqua dolor do amet sintduis enim.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Exercitation veniam consequat.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Ullamco est sit dolor sintduis exercitation.
                                                                </Card.Text>
                                                                <Card.Link href="/" className="fs20 semibold themeColor text-decoration-none mt-2 d-inline-block">View All</Card.Link>                                                            
                                                            </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" lg="4" className="mb-4 pb-1">
                                                    <Card className="faqQtypes border-0 shadowMD border08">
                                                            <Card.Body>
                                                                <Card.Title className="fs32 semibold themeColor mb-3 pb-1">
                                                                    <svg className="position-relative me-1" width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.7998 25.8008H0.549805V27.0508H1.7998V25.8008ZM24.1998 25.8008V27.0508H25.4498V25.8008H24.1998ZM12.9998 12.6508C16.3412 12.6508 19.0498 9.94208 19.0498 6.60078H16.5498C16.5498 8.5614 14.9605 10.1508 12.9998 10.1508V12.6508ZM19.0498 6.60078C19.0498 3.25948 16.3412 0.550781 12.9998 0.550781V3.05078C14.9605 3.05078 16.5498 4.64016 16.5498 6.60078H19.0498ZM12.9998 0.550781C9.65849 0.550781 6.9498 3.25947 6.9498 6.60078H9.4498C9.4498 4.64018 11.0392 3.05078 12.9998 3.05078V0.550781ZM6.9498 6.60078C6.9498 9.9421 9.65849 12.6508 12.9998 12.6508V10.1508C11.0392 10.1508 9.4498 8.56138 9.4498 6.60078H6.9498ZM3.0498 25.8008C3.0498 20.3055 7.50458 15.8508 12.9998 15.8508V13.3508C6.12387 13.3508 0.549805 18.9248 0.549805 25.8008H3.0498ZM12.9998 15.8508C18.4951 15.8508 22.9498 20.3055 22.9498 25.8008H25.4498C25.4498 18.9248 19.8758 13.3508 12.9998 13.3508V15.8508ZM24.1998 24.5508H1.7998V27.0508H24.1998V24.5508Z" fill="#C32148"/>
                                                                    </svg> Offers</Card.Title>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Deserunt amet minim mollit ullamco est.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Mollit non deserunt ullamco est sit.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Aliqua dolor do amet sintduis enim.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Exercitation veniam consequat.
                                                                </Card.Text>
                                                                <Card.Text className="semibold GrayComn lh-sm fs20">
                                                                    <svg className="me-3 align-middle" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.75 0.75L6 6L0.75 11.25" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>Ullamco est sit dolor sintduis exercitation.
                                                                </Card.Text>
                                                                <Card.Link href="/" className="fs20 semibold themeColor text-decoration-none mt-2 d-inline-block">View All</Card.Link>                                                            
                                                            </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row className="mt-5">
                                                <Col className="text-center">
                                                    <Card className="border-0">
                                                        <Card.Body className="p-0">
                                                            <blockquote className="blockqoute mb-1">
                                                                <svg width="106" height="105" viewBox="0 0 106 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M96 59.809C96 53.222 91.8355 47.7177 86.0419 45.552V42.9355C86.0419 24.7984 71.1956 10 53 10C34.8043 10 19.9581 24.7984 19.9581 42.9355V45.552C14.1645 47.7177 10 53.3121 10 59.809C10 68.2004 16.8798 75.0587 25.299 75.0587H30.8211C32.2696 75.0587 33.446 73.8855 33.446 72.4422V47.1767C33.446 45.7328 32.2689 44.5602 30.8211 44.5602H28.2863V42.9363C28.2863 29.3111 39.4208 18.3024 52.9998 18.3024C66.5788 18.3024 77.7133 29.401 77.7133 42.9363V44.5602H75.1786C73.7301 44.5602 72.5537 45.7334 72.5537 47.1767V72.4422C72.5537 73.8861 73.7307 75.0587 75.1786 75.0587H76.3556C73.7307 81.1044 67.9371 85.3454 61.0566 85.8867C60.6948 84.1721 59.1553 82.8189 57.2546 82.8189H48.6548C46.482 82.8189 44.7625 84.5335 44.7625 86.6986V91.1203C44.7625 93.286 46.4827 95 48.6548 95H57.2546C59.1559 95 60.7851 93.6468 61.0566 91.7516C71.2862 91.2103 79.7954 84.2621 82.6923 74.8778C90.2059 73.8858 95.9995 67.4795 95.9995 59.8093L96 59.809Z" fill="#C32148"/>
                                                                </svg>
                                                            </blockquote>
                                                            <Card.Text className="fs22 semibold lh-sm GrayDark">
                                                                Cannot find what you are looking for? 
                                                                <br className="d-none d-lg-inline" /><Link to="/contactus" className="themeColor text-decoration-none">Contact</Link> our support team.
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>

                                        </Col>                                        
                                    </Row>
                                </Col>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default Faq