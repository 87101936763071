import React, { useState } from 'react'
import { Col } from 'react-bootstrap';
import { AiFillPlayCircle, AiFillPauseCircle } from 'react-icons/ai'

const PauseOrder = () => {
    const [clicked, setClicked] = useState('');
    const handleClick = () => {
        clicked ? setClicked('') : setClicked('play-state pause-state d-inline-block'); 
    };
    return (
        <Col className="position-absolute bottom-0 mb-5 pb-3 text-center w-100" >
            <Col className={clicked || 'play-state d-inline-block'} onClick={handleClick}>
                <Col as="span" className="cursor-pointer d-inline-block p-0 fs20 semibold fs20 black">
                    <AiFillPauseCircle className="order-pause themeColor me-3" size="50" />
                    <AiFillPlayCircle className="order-play themeColor me-3" size="50" />
                    Pause New Order
                </Col>
            </Col>
        </Col>
        
    )
}

export default PauseOrder;