import React from 'react';
import { Col } from 'react-bootstrap';
import "./hoststep.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const TooltipRange = () => {
  const [ value, setValue ] = React.useState(0);

  return (
        <Col md="12">
          <RangeSlider className="position-relative"
            value={value}
            variant="danger"
            onChange={e => setValue(e.target.value)}
            tooltip="on"
            min="0"
            max="5000"
          />
        </Col>
  );
};
export default TooltipRange;