import React, { useState } from 'react'
import { Container, Row, Col, Image, Card, Form, Button } from 'react-bootstrap';
import { FiEye, FiEyeOff } from "react-icons/fi";
import "./account.css"
import Header from "../header";
const MyAccount02 = () => {
    
    const[inputtext,setinputtext]=useState({
        email:"",
        password:""
    });
    
    const[warnpassword]=useState(false);    
    const[seteye]=useState(true);
    const[password,setpassword]=useState("password");
    const[type,settype]=useState(false);
    
    const inputEvent=(event)=>{
        const name=event.target.name;
        const value=event.target.value;
        setinputtext((lastValue)=>{
            return{
                ...lastValue,
                [name]:value
            }
        });
    }
    const Eye=()=>{
        if(password==="password"){
            setpassword("text");
            seteye(false);
            settype(true);
        }
        else{
            setpassword("password");
            seteye(true);
            settype(false);
        }
    }
    return (
        <div id="bodyWrap" className="">
        <Container className="mealOne" fluid>
            <Row>
                <Col className="px-0">
                    <Header />
                </Col>
            </Row>
            <Row className="ps-0 pinkgradiant pb-3">
                <Col lg="12" className="text-center mb-5"><Image src={process.env.PUBLIC_URL+"assets/sadIcon.png"} alt="mainbanner" className="img-fluid" /></Col>
                <Col lg="12" className="text-center">
                <h2 className="fs60 text-white semibold mb-5">We’re Sad To See You Go!</h2>
                    <Card style={{maxWidth: '670px', paddingLeft: '5rem', paddingRight: '5rem'}} className="d-inline-block px-md-5 py-3 border08 w-100" body>
                    <Card.Text className="fs30 GrayComn semibold">Please enter your Credentials</Card.Text>                             
                        <Form className="mt-5">
                            <Form.Group className="mb-3" controlId="ControlInput1">
                                <Form.Control className="border-0" type="text" placeholder="Email or Contact Number" />
                            </Form.Group>
                            <Form.Group controlId="ControlPassword" className="position-relative">
                                <Form.Control type={password} className={` ${warnpassword ? "warning" : "" } ${type ? "type_password" : "" } border-0`} placeholder="Password" value={inputtext.password} onChange={inputEvent} name="password" />
                                <FiEye role="button" size="20" className="position-absolute end-0 top-0 mt-4 me-4 d-none openEye" onClick={Eye} />
                                <FiEyeOff role="button" size="20" className="position-absolute end-0 top-0 mt-4 me-4 closeEye" onClick={Eye} />
                            </Form.Group> 
                            <Form.Group className="mt-4 mb-5"> 
                                <Button className="w-100 py-2 lh-lg border-0 rounded-pill" variant="primary" type="submit">Confirm</Button>
                            </Form.Group>
                        </Form>
                    </Card>
                </Col>
                <Col lg="8" className="text-center py-5 mt-3 text-center m-auto">
                    <Col as="div" className="icons pb-2">
                        <svg width="106" height="105" viewBox="0 0 106 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M96 59.809C96 53.222 91.8355 47.7177 86.0419 45.552V42.9355C86.0419 24.7984 71.1956 10 53 10C34.8043 10 19.9581 24.7984 19.9581 42.9355V45.552C14.1645 47.7177 10 53.3121 10 59.809C10 68.2004 16.8798 75.0587 25.299 75.0587H30.8211C32.2696 75.0587 33.446 73.8855 33.446 72.4422V47.1767C33.446 45.7328 32.2689 44.5602 30.8211 44.5602H28.2863V42.9363C28.2863 29.3111 39.4208 18.3024 52.9998 18.3024C66.5788 18.3024 77.7133 29.401 77.7133 42.9363V44.5602H75.1786C73.7301 44.5602 72.5537 45.7334 72.5537 47.1767V72.4422C72.5537 73.8861 73.7307 75.0587 75.1786 75.0587H76.3556C73.7307 81.1044 67.9371 85.3454 61.0566 85.8867C60.6948 84.1721 59.1553 82.8189 57.2546 82.8189H48.6548C46.482 82.8189 44.7625 84.5335 44.7625 86.6986V91.1203C44.7625 93.286 46.4827 95 48.6548 95H57.2546C59.1559 95 60.7851 93.6468 61.0566 91.7516C71.2862 91.2103 79.7954 84.2621 82.6923 74.8778C90.2059 73.8858 95.9995 67.4795 95.9995 59.8093L96 59.809Z" fill="#C32148"/>
                        </svg>
                    </Col>
                    <Col as="p" className="parraText fs30 semibold text-white lh-sm pb-3">
                        We are here for you. <span className="themeColor">Contact</span> our Support Team and<br className="d-none d-lg-inline" /> let us know how we can help you.
                    </Col>
                </Col>
            </Row>
        </Container>
        </div>
        )    
    }
    export default MyAccount02