import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import "react-pro-sidebar/dist/css/styles.css";

import "./cartsummery.css"
import Header from "../header";
import Footer from "../footer";

const Coupon = () => {    
    const [isLeft, setRight] = useState("false");    
    const handleToggle = () => {
        setRight(!isLeft);
    };
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Row>
                    <Col as="div" className="BGRound position-absolute p-0">
                        <img src={process.env.PUBLIC_URL+"assets/couponBG.png"} className="img-fluid w-100" alt="couponimage" />
                    </Col>
                    <br /><br />
                    <Col md={7} className="m-auto lrgeCoupon">
                        <Row className="cpn">
                            <Col className="text-center py-5">
                                <section className="toggleCPN tobbleButton d-inline-block m-0 w-75">
                                    <div className={`app ${isLeft ? "left" : "right"}`}>
                                        <span className="leftButton semibold fs20 w-50" onClick={handleToggle}>Collected Coupon</span>
                                        <span className="rightButton semibold fs20 w-50" onClick={handleToggle}>Available Coupon</span>
                                    </div>
                                </section>
                            </Col>
                        </Row>                        
                        <Row className="pb-3">
                            <Col className="text-center border08 shadow bgLight">
                                <Scrollbars 
                                className="cpnlist"
                                autoHideTimeout={1000}
                                autoHideDuration={1000}
                                autoHeight
                                autoHeightMax={600}
                                thumbMinSize={300}
                                universal={true}>
                                        <Col as="div" className="large_coupon">
                                            <img src={process.env.PUBLIC_URL+"assets/coupon_large.png"} className="img-fluid my-4" alt="couponlarge" />
                                        </Col>
                                        <Row className="justify-content-between text-start cpnCode px-2 border-bottom mx-2 pb-4 mb-3">
                                            <Col md="auto"><h2 className="fs40 bold GrayDark">S$10</h2><p className="fs20 m-0 semibold">Cashback</p></Col>
                                            <Col md="auto"><h2 className="fs40 bold GrayDark">DEC20OFF</h2><p className="fs20 m-0 semibold">Promocode</p></Col>
                                            <Col md="auto"><h2 className="fs40 bold GrayDark">19.12.2022</h2><p className="fs20 m-0 semibold">Expiry Date</p></Col>
                                        </Row>
                                        <Col as="div" className="trmCondition text-start mx-4 pt-3">
                                            <h3 className="fs30 semibold GrayComn">Terms &#38; Conditions</h3>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting has been</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and </p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has been</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text dummy.</p>
                                            <p className="fs18 semibold GrayComn"><FaCircle size="6" color="--bs-grayDark" className="ms-3 me-1" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
                                        </Col>
                                </Scrollbars>
                            </Col>
                        </Row>                        
                        <Scrollbars 
                            className="cpnlist"
                            autoHideTimeout={1000}
                            autoHideDuration={1000}
                            autoHeight
                            autoHeightMax={600}
                            thumbMinSize={300}
                            universal={true}>
                            <Row className="mt-3 mb-5 listCPN">
                                <Col md="auto">                                    
                                    <img src={process.env.PUBLIC_URL+"assets/coupon04.png"} className="img-fluid" alt="couponlarge" width="150" />
                                </Col>
                                <Col>
                                    <h4>S$20 Cashback</h4>
                                    <p>
                                        <span>Promo Code : DEC20OFF</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Expiry Date : 19.12.2022</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Terms and Conditions apply.</span>
                                    </p>
                                </Col>
                                <Col md="auto" className="me-4">
                                    <Link to="/" className="btn btn-danger rounded-pill px-4">1 Day Left</Link>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-5 listCPN">
                                <Col md="auto">
                                    <img src={process.env.PUBLIC_URL+"assets/coupon02.png"} className="img-fluid" alt="couponlarge" width="150" />
                                </Col>
                                <Col>
                                    <h4>S$20 Cashback</h4>
                                    <p>
                                        <span>Promo Code : DEC20OFF</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Expiry Date : 19.12.2022</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Terms and Conditions apply.</span>
                                    </p>
                                </Col>
                                <Col md="auto" className="me-4">
                                    <Link to="/" className="btn btn-danger rounded-pill px-4">2 Day Left</Link>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-5 listCPN">
                                <Col md="auto">
                                    <img src={process.env.PUBLIC_URL+"assets/coupon03.png"} className="img-fluid" alt="couponlarge" width="150" />
                                </Col>
                                <Col>
                                    <h4>S$20 Cashback</h4>
                                    <p>
                                        <span>Promo Code : DEC20OFF</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Expiry Date : 19.12.2022</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Terms and Conditions apply.</span>
                                    </p>
                                </Col>
                                <Col md="auto" className="me-4">
                                    <Link to="/" className="btn btn-danger rounded-pill px-4">3 Day Left</Link>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-5 listCPN">
                                <Col md="auto">
                                    <img src={process.env.PUBLIC_URL+"assets/coupon01.png"} className="img-fluid" alt="couponlarge" width="150" />
                                </Col>
                                <Col>
                                    <h4>S$20 Cashback</h4>
                                    <p>
                                        <span>Promo Code : DEC20OFF</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Expiry Date : 19.12.2022</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Terms and Conditions apply.</span>
                                    </p>
                                </Col>
                                <Col md="auto" className="me-4">
                                    <Link to="/" className="btn bgLight rounded-pill px-4">7 Day Left</Link>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-5 listCPN">
                                <Col md="auto">
                                    <img src={process.env.PUBLIC_URL+"assets/coupon02.png"} className="img-fluid" alt="couponlarge" width="150" />
                                </Col>
                                <Col>
                                    <h4>S$20 Cashback</h4>
                                    <p>
                                        <span>Promo Code : DEC20OFF</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Expiry Date : 19.12.2022</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Terms and Conditions apply.</span>
                                    </p>
                                </Col>
                                <Col md="auto" className="me-4">
                                    <Link to="/" className="btn bgLight rounded-pill px-4">7 Day Left</Link>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-5 listCPN">
                                <Col md="auto">
                                    <img src={process.env.PUBLIC_URL+"assets/coupon01.png"} className="img-fluid" alt="couponlarge" width="150" />
                                </Col>
                                <Col>
                                    <h4>S$20 Cashback</h4>
                                    <p>
                                        <span>Promo Code : DEC20OFF</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Expiry Date : 19.12.2022</span>
                                        <FaCircle size="8" color="--bs-grayDark" className="mx-2" />
                                        <span>Terms and Conditions apply.</span>
                                    </p>
                                </Col>
                                <Col md="auto" className="me-4">
                                    <Link to="/" className="btn bgLight rounded-pill px-4">30 Day Left</Link>
                                </Col>
                            </Row>
                        </Scrollbars>                        
                    </Col>
                </Row>        
            </Container>
            <Footer />
        </div>
    )    
}
export default Coupon