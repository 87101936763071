import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { HiHome } from "react-icons/hi";
import { Link } from 'react-router-dom';
//import './loginpage.css';

const DinerSignupNine = () => {
    
    return (
        <div>
            <Container className="userLogin" fluid>
                <Row className="dFlex justify-content-center">
                    <Col md={11} className="row justify-content-center midContainer">
                        <Col md={5}>
                            <Col as="div" className="logo_login my-5 py-md-5">
                                <Link to="/" className="loginLogo mb-5 d-block"><img src="./assets/loginLogo.png" alt='logo' /></Link>                            
                                <Col className="headingLogin">
                                    <Col as="div" className="heading mb-5">
                                        <h2 className="m-0 small text-black">
                                            Enjoy a cozy hot meal at your neighbour's crib.
                                        </h2>
                                        <p className="parra mt-4">
                                            Join us and be rewarded whenever you eat with us. 
                                            A good taste deserves more treats.
                                        </p>
                                    </Col>
                                    <Col as="div" className="appStoreIcons pt-1">
                                        <h2>Download App</h2>
                                        <div className="d-inline-block aapblock">
                                            <img src="./assets/googlePlay.png" className="img-fluid" alt='googlePay' />
                                            <img src="./assets/appStore.png" className="img-fluid" alt='appStore' />
                                        </div>
                                    </Col>                                    
                                </Col>
                                <br /><br />
                                <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-5">
                                    <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                                </Link>                                
                            </Col>
                        </Col>
                        <Col md={6} className="pe-0">
                            <Row className="d-md-flex h-100">
                                <Col className="rightLog position-relative mt-5">
                                <br /><br />
                                    <section id="scalOut" className="backImage position-absolute scalImg ">
                                        <img src="./assets/imageBunch.png" className="img-fluid" alt='bg' />
                                    </section>
                                    <section id="signUpFlip" className="mobilePur m-auto cardBox">
                                        <div className="front">
                                            <div className="heading">
                                                <h2 className='small lh-1 fs-normal mb-2'>Signup</h2>
                                                <p className="semibold m-0 lh-1">Please read, confirm and accept the following terms to proceed.</p>
                                            </div>
                                            <Col as="section" className="stepsBar pt-3">
                                                <Col as="ul" className="d-flex justify-content-between list-unstyled">
                                                    <li className="p2 active first"><span>1</span></li>
                                                    <li className="p2 active"><span>2</span></li>
                                                    <li className="p2 active"><span>3</span></li>
                                                    <li className="p2 active"><span>4</span></li>
                                                    <li className="p2 active last"><span>5</span></li>
                                                </Col>
                                            </Col>
                                            <p className="semibold mb-3 lh-1">For Diner</p>
                                            <Form className="pt3">
                                                <Form.Group className="pb-3" controlId="exampleForm.ControlTextarea1">
                                                    <div className="form-check d-inline-block">
                                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="back01" />
                                                        <label className="form-check-label semibold ps-3" for="back01">
                                                            <b className="text-danger">*</b>I confirm that I have read, understood and accepted 
                                                            Cribbly User <Link to="/" className="navlink text-danger text-decoration-none">Terms &#38; Conditions</Link> and <Link to="/" className="navlink text-danger text-decoration-none">Privacy Policy</Link>.
                                                        </label>
                                                    </div>                                                    
                                                </Form.Group>
                                                <Form.Group className="mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                                                    <div className="form-check d-inline-block">
                                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="back02" />
                                                        <label className="form-check-label semibold ps-3" for="back02">
                                                        I would like to receive updates, special offers and marketing promotions from Cribbly Pte Ltd. This option may be changed in your account later.
                                                        </label>
                                                    </div>                                                    
                                                </Form.Group>
                                                <Form.Group className="mb-4 pb-5 text-center" controlId="exampleForm.ControlTextarea1">
                                                    <img src="./assets/captcha.png" alt="captcha" />
                                                </Form.Group>
                                                <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary">Create Account</Button>
                                            </Form>                                            
                                        </div>                                        
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>                
            </Container> 

        </div>        
    )
}
export default DinerSignupNine