import { useState } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Slider from "react-slick";
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./hoststep.css";
import Header from "../header";
import ProfileMenu from "../profilemenu";
import Footer from '../footer';
import { Link } from 'react-router-dom';

const HostCribTwo = () => {
    const settings = {
        dots: true,
        infinite: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        easing: 'cubic',
        
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }            
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    const [alpha, setAlpha] = useState();
    const [alpha01, setAlpha01] = useState();    

    const getCheckedValue = (e) => {
        switch (e.target.name) {
            case "case01":
                e.target.checked ? setAlpha("On") : setAlpha("Off")
                break;
            case "case02":
                e.target.checked ? setAlpha01("On") : setAlpha01("Off")
                break;
            default:
        }
    }
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9">
                        <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-5 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <Col md="auto" className='d-flex text-center flex-column flex-md-row text-md-start col-md-auto'>
                                        <span className="user-profile-circle me-md-3 mx-auto rounded-pill border border-2 border-danger p-1 d-inline-block"><img src="./assets/black_scoop.png" className='rounded-pill border-2' alt="food" /></span>
                                        <Col className="itemDetails">
                                            <Col as="div" className="itemHead mb-2">
                                                <h4 className="d-inline-block align-middle GrayDark fs46 semibold">City Home Cafe</h4>
                                            </Col>
                                            <Col as="p" className="d-inline-flex border04 grayf7f py-2 px-3 shadowMD align-items-center semibold align-middle">
                                                <Col className='border-end pe-3 me-3'>
                                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.0013 26.0947L15.8379 14.4258H24.1647L20.0013 26.0947Z" fill="#6992F2"/>
                                                    <path d="M0 14.3711L19.3602 31.4961L13.5297 14.4017L0 14.3711Z" fill="#3169ED"/>
                                                    <path d="M12.8381 12.0727L0.0273438 12.0743L6.42691 0.878906L12.8381 12.0727Z" fill="#4377EF"/>
                                                    <path d="M39.9998 14.3867L20.6396 31.5117L26.4701 14.4173L39.9998 14.3867Z" fill="#7CA0F4"/>
                                                    <path d="M27.1631 12.0845L39.9738 12.086L33.5859 0.890625L27.1631 12.0845Z" fill="#A1BBF7"/>
                                                    <path d="M24.2077 11.9639L15.8809 11.9649L20.0559 4.72656L24.2077 11.9639Z" fill="#8EADF6"/>
                                                    <path d="M19.5222 0.00155315L8.61328 0L14.0628 9.53337L19.5222 0.00155315Z" fill="#5684F0"/>
                                                    <path d="M20.5225 0.00155315L31.4314 0L25.9918 9.53337L20.5225 0.00155315Z" fill="#7CA0F4"/>
                                                    </svg>
                                                </Col>    
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6758 0.928603C12.0926 -0.309534 13.9074 -0.309534 14.3242 0.928603L16.4386 7.20961C16.6249 7.76332 17.1596 8.13821 17.7627 8.13821H24.605C25.9538 8.13821 26.5146 9.80412 25.4234 10.5693L19.8878 14.4512C19.3998 14.7934 19.1957 15.4 19.3821 15.9537L21.4965 22.2348C21.9132 23.4729 20.445 24.5025 19.3539 23.7372L13.8184 19.8554C13.3304 19.5131 12.6696 19.5131 12.1816 19.8554L6.64609 23.7372C5.55492 24.5025 4.08674 23.4729 4.50353 22.2348L6.61791 15.9537C6.8043 15.4 6.6001 14.7934 6.11212 14.4512L0.57661 10.5693C-0.514582 9.80412 0.0462183 8.13821 1.39499 8.13821H8.23727C8.84045 8.13821 9.37505 7.76332 9.56144 7.20961L11.6758 0.928603Z" fill="#EF9309"/>
                                                    </svg>
                                                <Col as="span" className='col-auto px-2 lh-1 align-middle fs26 GrayComn semibold'>
                                                    4.5
                                                </Col>
                                                <Link to="/" className='d-inline-block themeColor ps-3 semibold fs20 text-decoration-none'>See Reviews (55)</Link>
                                            </Col>
                                        </Col>
                                    </Col>
                                    <Col md="auto" className='text-md-end py-md-4 mt-2 text-center'>
                                        <Button variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 ">
                                        <svg className='align-text-bottom me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.4142 1.58577L22.4142 1.58582C23.1953 2.36684 23.1953 3.63313 22.4142 4.41418L22.4142 4.41421L11.3284 15.5H8.5V12.6716L19.5858 1.58579L19.5858 1.58577C20.3668 0.804745 21.6332 0.804745 22.4142 1.58577Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3 5C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18C18.5523 22 19 21.5523 19 21V15C19 14.7238 19.2238 14.5 19.5 14.5C19.7762 14.5 20 14.7238 20 15V21C20 22.1046 19.1046 23 18 23H3C1.89542 23 1 22.1046 1 21V6C1 4.89543 1.89543 4 3 4H9C9.27614 4 9.5 4.22386 9.5 4.5C9.5 4.77614 9.27614 5 9 5H3Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                            Edit Details
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col as="section" className='ms-0 ms-xl-5 ps-xl-5 mb-5 ps-0 '>
                            <Col className='container px-xl-5 px-lg-4 px-0'>
                                <Form className="accForm px-lg-2 px-xl-0">
                                    <Row>
                                        <Col md="6" xl="5" xxl="6">
                                            <Row className="align-items-end">
                                                <Col>
                                                    <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Name</Col>
                                                    <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Name of Outlet</Form.Label>
                                                        <Form.Control className='bgLightGray' type="email" placeholder="Bayfront" />
                                                        <Form.Label className='char-limit'>8 / 20 Characters</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="auto" className='fs16 semibold GrayComn pb-4 px-2 mx-md-0'>
                                                    Crib
                                                </Col>
                                            </Row>                                            
                                        </Col>
                                        
                                        <Col md="6" lg="6" xl="4" xxl="4">
                                            <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Crib Status</Col>
                                            <Form.Group className="position-relative approve-field" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Current Status</Form.Label>
                                                <Form.Control  className='bgLightGray' type="email" placeholder="Approved and Published" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="12" lg="12" xl="3" xxl="2" className='pt-4 pt-xl-0 pb-0'>
                                            <Row className='flex-xl-column row-cols-auto text-end'>
                                                <Col md="auto">
                                                    <Button variant="light" className="w-100 text-nowrap rounded-pill bgLightGray py-1 px-4 mb-3 GrayComn border-0 fs20">Suspend Crib</Button>
                                                </Col>
                                                <Col md="auto">
                                                    <Button variant="light" className="w-100 text-nowrap rounded-pill bgtheme py-1 px-4 text-white border-0 fs20 ">Delete Crib</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4 graye0e opacity-100"></Col>
                                    <Row>
                                        <Col md="6"  className='mb-4'>
                                            <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Address</Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Street Address</Form.Label>
                                                <Form.Control className='bgLightGray' type="email" placeholder="10 Bayfront Ave" />
                                            </Form.Group>                        
                                        </Col>
                                        
                                        <Col md="6" className='mb-4'>
                                            <Col as="p" className='fs18 semibold textGreen text-end mb-3 lh-sm'>
                                                <svg className='me-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L7.70711 9.29289ZM9 12L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L9 12ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L13.7071 8.70711ZM6.29289 10.7071L8.29289 12.7071L9.70711 11.2929L7.70711 9.29289L6.29289 10.7071ZM9.70711 12.7071L13.7071 8.70711L12.2929 7.29289L8.29289 11.2929L9.70711 12.7071ZM18 10C18 14.4183 14.4183 18 10 18V20C15.5229 20 20 15.5229 20 10H18ZM10 18C5.58172 18 2 14.4183 2 10H0C0 15.5229 4.47716 20 10 20V18ZM2 10C2 5.58172 5.58172 2 10 2V0C4.47716 0 0 4.47716 0 10H2ZM10 2C14.4183 2 18 5.58172 18 10H20C20 4.47716 15.5229 0 10 0V2Z" fill="#00676B"/>
                                                </svg>
                                                Verified
                                                </Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Building Name</Form.Label>
                                                <Form.Control  className='bgLightGray' type="email" placeholder="-" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" md="2" lg="3" xxl="2" className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Floor Number</Form.Label>
                                                <Form.Control className='bgLightGray' type="email" placeholder="01" />
                                            </Form.Group>                        
                                        </Col>
                                        
                                        <Col xs="6" md="2" lg="3" xxl="2" className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Unit Number</Form.Label>
                                                <Form.Control  className='bgLightGray' type="email" placeholder="01" />
                                            </Form.Group>
                                        </Col>
                                        <Col className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control  className='bgLightGray' type="email" placeholder="Singapore" />
                                            </Form.Group>
                                        </Col>
                                        <Col className='mb-4'>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Postal Code</Form.Label>
                                                <Form.Control  className='bgLightGray' type="email" placeholder="018956" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4  mt-2 graye0e opacity-100"></Col>
                                    <Col>
                                        <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Operating Hours</Col>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Sunday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="2:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                        <Col as="span" className='border-end py-3'></Col>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="2:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Monday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="10:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Tuesday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="10:00 PM" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Wednesday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="-:-- AM / PM " />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="-:-- AM / PM " />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="2" className='mb-4 pt-lg-4'>
                                                        <Col as="span" className='fs16 semibold GrayComn'>Not In Operation</Col>                       
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Thursday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="10:00 PM" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Friday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="10:00 PM" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Saturday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 1</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="2:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                        <Col as="span" className='border-end py-3'></Col>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 2</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="4:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Closing Time 2</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="7:30 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                        <Col as="span" className='border-end py-3'></Col>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Row>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Opening Time 2</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="4:00 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} md="auto" className='mb-4'>
                                                                <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Closing Time 2</Form.Label>
                                                                    <Form.Control  className='bgLightGray' type="email" placeholder="7:30 PM" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Eve Of Public Holiday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="Normal Hours" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="Normal Hours" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="2" className='mb-4 pt-4'>
                                                <Col as="span" className='fs16 semibold themeColor'>Public Holiday</Col>                       
                                            </Col>
                                            <Col md="10">
                                                <Row>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="9:00 AM" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} md="auto" className='mb-4'>
                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Opening Time 1</Form.Label>
                                                            <Form.Control  className='bgLightGray' type="email" placeholder="2:00 PM" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col as="hr" className="my-4 mt-2 graye0e opacity-100"></Col>
                                    <Row>
                                        <Col md="12" className='mb-4'>
                                            <Col className='d-flex justify-content-between align-items-center mb-4 collection-switch'>
                                                <Col as="p" className='fs18 semibold GrayDark text-start mb-0 lh-sm'>Pickup Collections</Col>
                                                <Form.Check 
                                                    className="togglerBtn fs18 semibold GrayComn text-uppercase float-end togglerSize"
                                                    type="switch"
                                                    id="custom-switch01"
                                                    label={alpha ? alpha: "Off"}
                                                    name="case01"
                                                    onChange={getCheckedValue}
                                                />
                                            </Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Collection Instructions</Form.Label>
                                                <Form.Control className='bgLightGray' type="email" placeholder="Please collect your order at ground floor lift lobby. Block 10, Lobby A." />
                                                <Form.Label className='char-limit'>72 / 150 Characters</Form.Label>
                                            </Form.Group>                        
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4 mt-2 graye0e opacity-100"></Col>
                                    <Row>
                                        <Col md="12" className='mb-4'>
                                            <Col className='d-flex justify-content-between align-items-center mb-4 collection-switch'>
                                                <Col as="p" className='fs18 semibold GrayDark text-start mb-0 lh-sm'>Pickup Collections</Col>
                                                <Form.Check 
                                                    className="togglerBtn fs18 semibold GrayComn text-uppercase float-end togglerSize"
                                                    type="switch"
                                                    id="custom-switch02"
                                                    label={alpha01 ? alpha01: "Off"}
                                                    name="case02"
                                                    onChange={getCheckedValue}
                                                />
                                            </Col>
                                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Collection Instructions</Form.Label>
                                                <Form.Control className='bgLightGray' type="email" placeholder="Please ring the door bell and provide your Order ID when you arrive at the gate." />
                                                <Form.Label className='char-limit'>80 / 150 Characters</Form.Label>
                                            </Form.Group>                        
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col md="auto">
                                                    <Row>
                                                        <Col md="auto" className='mb-4'>
                                                            <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Table Number</Form.Label>
                                                                <Form.Control  className='bgLightGray' type="email" placeholder="01" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                            <Col as="span" className='border-end py-3'></Col>
                                                        </Col>
                                                        <Col md="auto" className='mb-4'>
                                                            <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Number of Seats</Form.Label>
                                                                <Form.Control  className='bgLightGray' type="email" placeholder="08" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="auto">
                                                    <Row>
                                                        <Col md="auto" className='mb-4'>
                                                            <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Table Number</Form.Label>
                                                                <Form.Control  className='bgLightGray' type="email" placeholder="02" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                            <Col as="span" className='border-end py-3'></Col>
                                                        </Col>
                                                        <Col md="auto" className='mb-4'>
                                                            <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Number of Seats</Form.Label>
                                                                <Form.Control  className='bgLightGray' type="email" placeholder="06" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="my-4 mt-2 graye0e opacity-100"></Col>
                                </Form>
                                <Col>
                                    <Col as="p" className='fs18 semibold GrayDark text-md-start text-center mb-3 mb-md-5 lh-sm'>Delivery Collections</Col>
                                    <Col className='text-center'>
                                        <Col as="h2" className='fs80vw semibold GrayComn'>Coming Soon</Col>
                                        <Col as="p" className='fs18 semibold GrayComn'>Stay tuned for more exciting features! We'd love to hear your <br className='d-none d-md-block '/> feedback. Let us know what you think.</Col>
                                    </Col>
                                </Col>
                                <Col as="hr" className="my-4 mt-2 graye0e opacity-100"></Col>
                                <Col className='gallery-slider'>
                                    <Col className='d-flex justify-contnet-between'>
                                        <Col className='GrayComn fs18 semibold mb-3'>Gallery</Col>
                                        <Col className='text-end mb-3'>
                                        <Link to=" " className='themeColor fs18 semibold text-decoration-none'>View Gallery</Link>
                                        </Col>
                                    </Col>
                                    <Slider className="featureProd galleryProd" {...settings}>
                                        <div className="card border-0">
                                            <div className="promo"> 
                                                <img src={process.env.PUBLIC_URL+"assets/gallery1.png"} className="img-fluid" alt="gallery01" />                                    
                                            </div>
                                        </div>
                                        <div className="card border-0">
                                            <div className="promo">
                                                <img src={process.env.PUBLIC_URL+"assets/gallery3.png"} className="img-fluid" alt="gallery03" />
                                            </div>
                                        </div>
                                        <div className="card border-0">
                                            <div className="promo">
                                                <img src={process.env.PUBLIC_URL+"assets/gallery2.png"} className="img-fluid" alt="gallery02" />
                                            </div>
                                        </div>
                                        <div className="card border-0">
                                            <div className="promo">
                                                <img src={process.env.PUBLIC_URL+"assets/gallery1.png"} className="img-fluid" alt="gallery01" />
                                            </div>
                                        </div>
                                    </Slider>
                                </Col> 
                            </Col>   
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )    
}
export default HostCribTwo;