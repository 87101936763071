import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { HiHome } from "react-icons/hi";
import { Link } from 'react-router-dom';
import "react-pro-sidebar/dist/css/styles.css";
import "./careers.css";
import Header from "../header";
import Footer from "../footer";

const Error = () => {    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>                
                <Row>
                    <Col className="px-0">
                        <Header />
                    </Col>
                </Row>                
            </Container>
            <br /><br />
            <Col as="div">
                <Col md={11} className="m-auto py-md-5 px-md-5">
                    <Row className="mx-md-4 hypart align-items-center">
                        <Col md="12" lg="6" className="text-center"><img src={process.env.PUBLIC_URL+"assets/errorimg.png"} className="figure-img img-fluid" alt="error" /></Col>
                        <Col md="12" lg="6" className="text-center">
                           <Card className="errormessage border-0">
                                <Card.Body className="p-0">
                                    <Card.Title className="semibold GrayDark lh-1 mb-0">404</Card.Title>
                                    <Card.Subtitle className="lh-1 fs62 semibold GrayDark text-uppercase mb-4">Error</Card.Subtitle>
                                    <Card.Text className="fs16 semibold GrayDark">We're sorry! The site you are trying to reach does not exist.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-4">
                                <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                            </Link> 
                        </Col>
                    </Row>
                </Col>
            </Col>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default Error