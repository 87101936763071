import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { HiHome } from "react-icons/hi";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

const DinerSignupTen = () => {
    
    return (
        <div>
            <Container className="userLogin" fluid>
                <Row className="dFlex justify-content-center">
                    <Col md={11} className="row justify-content-center midContainer">
                        <Col md={5}>
                            <Col as="div" className="logo_login my-5 py-md-5">
                                <Link to="/" className="loginLogo mb-5 d-block"><img src="./assets/loginLogo.png" alt='logo' /></Link>                            
                                <Col className="headingLogin">
                                    <Col as="div" className="heading mb-5">
                                        <h2 className="m-0 small text-black">
                                            Enjoy a cozy hot meal at your neighbour's crib.
                                        </h2>
                                        <p className="parra mt-4">
                                            Join us and be rewarded whenever you eat with us. 
                                            A good taste deserves more treats.
                                        </p>
                                    </Col>
                                    <Col as="div" className="appStoreIcons pt-1">
                                        <h2>Download App</h2>
                                        <div className="d-inline-block aapblock">
                                            <img src="./assets/googlePlay.png" className="img-fluid" alt='googlePay' />
                                            <img src="./assets/appStore.png" className="img-fluid" alt='appStore' />
                                        </div>
                                    </Col>                                    
                                </Col>
                                <br /><br />
                                <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-5">
                                    <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                                </Link>                                
                            </Col>
                        </Col>
                        <Col md={6} className="pe-0">
                            <Row className="d-md-flex h-100">
                                <Col className="rightLog position-relative mt-5">
                                <br /><br />
                                    <section id="scalOut" className="backImage position-absolute scalImg ">
                                        <img src="./assets/imageBunch.png" className="img-fluid" alt='bg' />
                                    </section>
                                    <section id="signUpFlip" className="mobilePur m-auto cardBox">
                                        <div className="front">
                                            <div className="heading">
                                                <h2 className='small lh-1 fs-normal mb-2'>Account Setup <span className="skipCont float-end">Skip <RiArrowRightSLine size="30" /></span></h2>
                                                <p className="semibold m-0 lh-1">Welcome aboard! Your account has been successfully created. Just a few more things to help you get going.</p>
                                            </div>
                                            <br /><br />
                                            <p className="semibold mb-2 pb-3 lh-1">Alternative Login method</p>
                                            <Col className="d-flex justify-content-between socialButton">
                                                <FacebookLoginButton className="facebookplus fs-bold ms-0" onClick={() => alert("Hello")}>
                                                    <span>Login with Facebook</span>
                                                </FacebookLoginButton>
                                                <GoogleLoginButton className="googleplus fw-bold me-0" onClick={() => alert("Hello")}>
                                                    <span>Login with Google</span>
                                                </GoogleLoginButton>
                                            </Col>
                                            <p className="semibold text-danger mt-3 lh-sm fs14">
                                                Link your Facebook or Google to your Cribbly account and you can log in using one of your preferred method instead.
                                            </p>
                                        </div>                                        
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>                
            </Container> 

        </div>        
    )
}
export default DinerSignupTen